<template>
  <!-- add new number -->
  <AddNumberSection
    :isLoadingBalance="isLoadingBalance"
    :isLoadingNumbersList="isLoadingNumbersList"
    :isLoadingCountries="isLoadingCountries"
    :showBuyNumberContainerOnMobile="showBuyNumberContainerOnMobile"
    :changeErrorCaptured="changeErrorCaptured"
    :userBalance="Number(userBalance)"
    :errorCaptured="errorCaptured"
    :closeMobileModal="() => changeShowingContainerOnMobileState(false)"
    :changeShowingContainerOnMobileState="changeShowingContainerOnMobileState"
    :onAddingNumberFormSubmission="onAddingNumberFormSubmission"
    :countriesList="countriesList"
    :isAddingNumber="isAddingNumber"
    :activeNumbers="numbersList"
    :isRentNumber="false"
    :isLoading="isLoading"
    :deleteNumberFromList="deleteNumberFromList"
    :isHomepageDesign="isHomepageDesign"
  />
</template>

<script>
import AddNumberSection from "@/components/Main/Dashboard/AddNumberSection";
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isLoading: false,
      errorCaptured: "",
      isAddingNumber: false,
      profitPercentage: 0,
      showBuyNumberContainerOnMobile: false,
      gettingNumberUpdatesInterval: undefined,
      isLoadingNumbersList: false,
      isCheckingForUpdates: false,
      numbersList: [],
      abortController: new AbortController(),
    };
  },
  components: {
    AddNumberSection,
  },
  computed: {
    ...mapGetters("main", ["isUserLoggedIn"]),
  },
  props: {
    isLoadingBalance: {
      type: Boolean,
      default: false,
      required: true,
    },
    isLoadingCountries: {
      type: Boolean,
      default: false,
      required: true,
    },
    countriesList: {
      type: Array,
      default: () => ([]),
      required: true
    },
    userBalance: {
      type: [Number, String],
      default: 0,
      required: true,
    },
    isHomepageDesign: {
      type: Boolean,
      default: false,
      required: false,
    },
    updateUserBalance: {
      type: Function,
      required: true,
    },
  },
  methods: {
    getNumbersList() {
      return new Promise((resolve, reject) => {
        if(!this.isUserLoggedIn){
          resolve();
          return;
        }
        this.isLoadingNumbersList = true;
        this.makeRequest({
          owner: this.requestOwners.user,
          method: this.apiRequests.user.getNumbersList.method,
          authRequired: this.isUserLoggedIn,
          url: `${this.apiRequests.user.getNumbersList.url}?service_type=unitedsms&term=short`,
          formData: {},
          abortControllerSignal: this.abortController.signal,
        })
          .then((res) => {
            resolve();
            this.isLoadingNumbersList = false;
            if (res?.success) {
              if (res?.data?.length > 0) {
                this.numbersList = res.data
                  ?.filter((el) => {
                    return (
                      this.lowerString(el.status)?.trim() === "available" &&
                      el?.created_at?.slice(0, 10) ===
                        el?.expires_at?.slice(0, 10)
                    ); // to check if creation  and expiration in the same day
                  })
                  ?.sort((a, b) => b.id - a.id);
              }
            } else {
              this.notify({
                type: "error",
                msg: res.errors || "Failed to fetch numbers list.",
              });
            }
          })
          .catch((err) => {
            reject();
            this.isLoadingNumbersList = false;
            this.notify({
              type: "error",
              msg: err.errors || "Failed to fetch numbers list.",
            });
          });
      });
    },
    onAddingNumberFormSubmission({ selectedCountry, selectedService }) {
      let currentCountry = selectedCountry?.id;

      if (parseFloat(this.userBalance) <= 0) {
        this.notify({
          type: "error",
          msg: `You don't have enough credit to proceed`,
        });
        return;
      }
      return new Promise((resolve, reject) => {
        if (!selectedCountry?.id) {
          currentCountry = null;
          // console.log('currentCountry' , currentCountry)
          // this.notify({
          //   type: "error",
          //   msg: `You must select a country.`
          // });
          // reject();
          // return;
        }
        if (!selectedService?.id) {
          this.notify({
            type: "error",
            msg: `You must select a service.`,
          });
          reject();
          return;
        }

        this.isAddingNumber = true;
        // on adding new number
        // const codedServiceLabel = window.btoa(unescape(encodeURIComponent(selectedService)));
        const formToSend = new FormData();
        formToSend.append("service", selectedService.id);
        formToSend.append("country", currentCountry);
        this.makeRequest({
          owner: this.requestOwners.user,
          method: this.apiRequests.user.generateNumber.method,
          authRequired: true,
          url: `${this.apiRequests.user.generateNumber.url}?service_type=unitedsms&term=short`,
          formData: formToSend,
          abortControllerSignal: this.abortController.signal,
        })
          .then((res) => {
            this.isAddingNumber = false;
            if (res?.success) {
              resolve();
              this.getNumbersList();
              this.updateUserBalance();
              this.errorCaptured = "";
              this.notify({
                type: "success",
                msg: res?.message || "Done successfully",
              });
            } else {
              reject();
              this.errorCaptured =
                res?.errors && !res?.errors.includes("Timed Out")
                  ? res?.errors
                  : "Failed to generate new number. Please try again later";
            }
          })
          .catch((err) => {
            reject();
            this.isAddingNumber = false;
            this.errorCaptured =
              err?.errors || "Failed to generate new number.";
          });
      });
    },
    deleteNumberFromList(numberId) {
      if (!numberId || this.numbersList?.length <= 0) {
        return;
      }
      this.numbersList = this.numbersList.filter((el) => el.id !== numberId);
    },
    changeShowingContainerOnMobileState(newBool) {
      this.showBuyNumberContainerOnMobile =
        typeof newBool === "boolean"
          ? newBool
          : !this.showBuyNumberContainerOnMobile;
    },
    changeErrorCaptured(newState) {
      this.errorCaptured = newState;
    },
  },
  mounted() {
      if(this.isUserLoggedIn){
        this.getNumbersList();
      }
  },
  beforeDestroy() {
    this.abortController.abort();
  },
};
</script>

<style></style>
