<template>
    <!-- No layouts -->
    <v-main id="publicView">
        <router-view></router-view>
    </v-main>
</template>

<script>
    export default {
        name: "PublicLayout"
    }
</script>

<style lang="scss" scoped>

</style>