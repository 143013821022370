<template>
  <div class="px-5 py-6 bg-gray-50 dark:bg-fox-800 dark:text-gray-100 space-y-8">
    <p class="text-lg font-bold">Are you sure you want to logout ?</p>

    <div class="flex items-center justify-end space-x-5">
      <!-- Close Button -->
      <button @click="() => closeModal()" class="px-5 py-2 inline-flex justify-center items-center space-x-2 text-white font-bold bg-gray-600 border-0 hover:bg-gray-700 rounded shadow-md">
        <span>Cancel</span>
      </button>     
      
      <!-- Logout Button -->
      <button :disabled="isLoggingOut" @click="logout" :class="`${isLoggingOut? 'disabled': ''} px-5 py-2 inline-flex justify-center items-center space-x-2 text-white font-bold bg-orange-700 border-0 hover:bg-orange-800 rounded shadow-md`">
        <span>Logout</span>
      </button>
    </div>
  </div>
</template>

<script>
import { routesNames } from '../../utilities/basicData';

export default {
  data(){
    return {
      isLoggingOut: false,
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    logout() {
      this.isLoggingOut = true;
      this.makeRequest({
        owner: this.requestOwners.user,
        authRequired: true,
        method: this.apiRequests.user.logout.method,
        url: this.apiRequests.user.logout.url,
      })
        .then((res) => {
          this.isLoggingOut = false;
          // failed
          if (!res?.success) {
            this.notify({
              type: 'error',
              msg: res.message,
            });
          } else {
            // succeeded

            this.updateMemory({
              type: 'update',
              newObject: {},
              destination: this.requestOwners.user,
            });

            this.$store.dispatch('website/setIsAuth', false);
            this.closeModal();

            if (this.$route.path != '/') {
              this.$router.push({ path: routesNames.user.homepage });
            }           
            // temporary -- will be removed later
            window.location.reload();
            // xxxx  ------  xxxxx
          }
        })
        .catch((err) => {
          this.isLoggingOut = false;
          this.notify({
              type: 'error',
              msg: err.errors || "An error has occurred. Please try again later."
            });
        });
    },
  },
};
</script>

<style></style>
