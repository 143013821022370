<template>
  <li v-if="item" class="flex-column py-2">
    <!-- renew confirmation dialog -->
    <v-dialog v-model="isRenewModalOpen" width="500">
          <v-card class="mb-0 bg-gray-50 dark:bg-fox-900 border-0">
            <div class="px-5 py-6 bg-gray-50 dark:bg-fox-800 dark:text-gray-100 space-y-8">
              <p class="text-lg font-bold">Are you sure you want to {{ item.is_renewable ? 'cancel the auto-renew action that was previously made for the number' : 'auto-renew the number' }} {{item.number}}?</p>

              <div class="flex items-center justify-end space-x-5">
                <!-- Close Button -->
                <button @click="() => closeRenewModal()" class="px-5 py-2 inline-flex justify-center items-center space-x-2 text-white font-bold bg-gray-600 border-0 hover:bg-gray-700 rounded shadow-md">
                  <span>Cancel</span>
                </button>
                
                <!-- Renew Button -->
                <button :disabled="isAutoRenewingNumber" @click="onRenewingNumber" :class="`${isAutoRenewingNumber? 'disabled': ''} px-5 py-2 inline-flex justify-center items-center space-x-2 text-white font-bold bg-green-700 border-0 hover:bg-green-800 rounded shadow-md`">
                  <span>{{ item.is_renewable ? 'Cancel auto-renew' : 'Auto Renew' }}</span>
                </button>
              </div>
            </div>
          </v-card>
    </v-dialog>
    
    <!-- number info -->
    <div @click="() => updateOpeningMessagesState()" class="flex-row flex-wrap align-center justify-between cursor-pointer">
      <span class="text-lg font-bold flex-row flex-wrap phone--number align-items-center">
        <div class="flex-row phone--number align-items-center">
                                                                <!-- getCountryAbbrFromId(item.country) -->
          <CountryFlagIcon spanClass="mr-3" defaultClass="mr-1" :countryId="getCountryAbbrFromId(1)"/>
          <div class="inline-flex gap-2 items-center">
            <span :title="isNumberActive ? 'online' : 'offline'" :class="`w-[9px] h-[9px] ${isNumberActive ? 'bg-[#1d8f1dcc]' : 'bg-[#e44040cc]'} rounded-full`"></span>
            <span class="phone--number--text">{{ item.number }}</span>
          </div>
        </div>
          
          <span class="expiration__timer ml-2" v-if="availabilityTimerValue !== '00:00'">{{availabilityTimerValue}}</span>
          <v-icon class="ml-3 exension--icon" v-show="hasNewMsg" color="#F97314">mdi-message-badge-outline</v-icon>
      </span>
      <div class="flex-row align-center gap-2">
        <div class="flex-row flex-wrap align-center gap-2">
          <template v-if="isRentNumber">
            <!-- rent number buttons -->
            <!-- v-if="!isNumberActive" -->
            <div>
              <!-- timeLeftUntilOnline && item.till_change_date -->
              <strong v-if="timeLeftUntilOnline && item.till_change_date" title="Activating number..." class="py-1 px-2.5 bg-fox-400 transition-colors duration-200 text-white select-none">
                <span >{{ timeLeftUntilOnline }}</span>
              </strong>
              <button v-else :disabled="isReactivatingNumber" type="button" @click.stop="(e) => reactivateNumber(e)" title="Activate number" :class="`${isReactivatingNumber ? 'disabled' : ''} py-1 px-2.5 bg-fox-400 hover:brightness-95 cursor-pointer transition-colors duration-200 text-white`.trim()">
                  <!-- show only if number is inactive (offline) -->
                  <strong>Activate</strong>
              </button>
            </div>

            <button type="button" @click.stop="() => onNumberRenew(item)" :title="`${item.is_renewable ? 'Cancel auto renewing number' :'Auto renew'}`" class="py-1 px-2">
              <img :src="item.is_renewable ? OnToggleButton : OffToggleButton"
                   :alt="item.is_renewable ? 'Auto-renew on' : 'Auto-renew off'"
                   style="width: 50px; height: 50px;" /> <!-- Adjust width and height as needed -->
            </button>

            <!-- <button type="button">
              <v-icon
                v-if="item.state == 0"
                size="21"
                :class="`copy--number--icon ${isReactivatingNumber ? 'disabled': ''}`"
                title="Re-activate number"
                @click.stop="(e) => reactivateNumber(e)"
              >mdi-phone-refresh-outline</v-icon>
            </button> -->
          </template>

          <button type="button">
            <v-icon
              size="21"
              class="copy--number--icon"
              title="Copy number"
              @click.stop="(e) => copyNumber(e)"
            >mdi-content-copy</v-icon>
          </button>
          <button 
            v-if="isLongServiceType"
            @click.stop="openEmailDialog(item)"
            class="mr-2 px-2 py-1 bg-blue-500 hover:bg-blue-600 text-white rounded shadow-md"
          >
            Email Notifications
          </button>
        </div>
        <span>
          <v-icon
            size="21"
            class="exension--icon"
            title="Go to messages"
            :style="{transform: areMessagesOpen ? 'rotate(180deg)' : 'rotate(0deg)'}"
          >mdi-chevron-down</v-icon>
        </span>
      </div>
    </div>
    <!-- numbers' messages -->
    <div v-show="areMessagesOpen" class="mt-7">
      <MessagesList
        filterNumbersBy="available"
        :service="item.service"
        :oldMsgs="item.messages"
        :tzid="item.tzid"
        :isActive="areMessagesOpen"
        :newMsgs="newMessages"
        :isRentNumber="isRentNumber"
      />
    </div>

    <!-- New Email Popup Dialog -->
    <v-dialog v-model="isEmailDialogOpen" width="500">
      <v-card class="mb-0 bg-gray-50 dark:bg-fox-900 border-0">
        <div class="px-5 py-6 bg-gray-50 dark:bg-fox-800 dark:text-gray-100 space-y-8">
          <h3 class="text-lg font-bold">Enter Email for Notifications</h3>
          <v-text-field
            v-model="emailForNotifications"
            label="Email"
            type="email"
            outlined
            dense
            :rules="emailRules"
            ref="emailField"
          ></v-text-field>
          <div class="flex items-center justify-end space-x-5">
            <button @click="closeEmailDialog" class="px-5 py-2 inline-flex justify-center items-center space-x-2 text-white font-bold bg-gray-600 border-0 hover:bg-gray-700 rounded shadow-md">
              <span>Cancel</span>
            </button>
            <button 
              @click="submitEmail" 
              class="px-5 py-2 inline-flex justify-center items-center space-x-2 text-white font-bold bg-green-700 border-0 hover:bg-green-800 rounded shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
              :disabled="!isEmailValid"
              :loading="isSubmittingEmail"
            >
              <span>Submit</span>
            </button>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </li>
</template>

<script>
import MessagesList from "@/components/Main/Dashboard/MessagesList.vue";
import CountryFlagIcon from "@/components/Generic/CountryFlagIcon.vue";
import { checkServiceType } from "@/utilities/tools";
import OnToggleButton from '@/assets/imgs/On-toggle-button.png';
import OffToggleButton from '@/assets/imgs/Off-toggle-button.png';

export default {
  data() {
    return {
      areMessagesOpen: false,
      timerIntervalForAvailability: undefined,
      timerIntervalUntilBeingOnline: undefined,
      availabilityTimerValue: "00:00",
      timeLeftUntilOnline: "Active",
      isRenewModalOpen: false,
      isEmailDialogOpen: false,
      emailForNotifications: '',

      isSubmittingEmail: false,
      OnToggleButton,
      OffToggleButton,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
    };
  },
  components: {
    MessagesList,
    CountryFlagIcon
  },
  props: {
    isRentNumber: {
      type: Boolean,
      required: false,
      defaut: false
    },
    isReactivatingNumber: {
      type: Boolean,
      required: false,
      defaut: false
    },
    isAutoRenewingNumber: {
      type: Boolean,
      required: false,
      defaut: false
    },
    copyText: {
      type: Function,
      required: true
    },
    item: {
      type: Object,
      required: true,
      default: () => {}
    },
    newMessages: {
      type: Array,
      required: true,
      default: () => []
    },
    deleteNumberFromList: {
      type: Function,
      required: true
    },
    onRenewingNumberConfirmation: {
      type: Function,
      required: false
    }
  },
  methods: {
    closeRenewModal(){
      this.isRenewModalOpen = false;
    },
    onNumberRenew(){
      this.isRenewModalOpen = true;
    },
    reactivateNumber(){
      if(!this.isRentNumber || this.isReactivatingNumber){
        return;
      }
      this.$emit('reactivateNum', this.item?.id);
    },
    onRenewingNumber(){
      this.onRenewingNumberConfirmation(this.item?.id, this.item?.is_renewable).then(() => {
        this.closeRenewModal();
      });
    },
    openEmailDialog(item) {
      this.selectedNumber = item;
      this.isEmailDialogOpen = true;
      this.getCurrentEmail();
    },
    getCurrentEmail() {
      if (this.selectedNumber && this.selectedNumber.number) {
        this.currentEmail = this.selectedNumber.email;
        if(this.currentEmail == null){
          this.currentEmail = '';
        }
        this.emailForNotifications = this.currentEmail;
      } else {
        this.notify({
          type: "error",
          msg: "Failed to get current email. Number information is missing."
        });
      }
    },

    submitEmail() {
      if (!this.isEmailValid || !this.selectedNumber) {
        this.notify({
          type: "error",
          msg: "Please enter a valid email address."
        });
        return;
      }

      this.isSubmittingEmail = true; // Set to true when starting submission

      // Log the number and email to the console
      console.log('Submitting email notification:');
      console.log('Number:', this.selectedNumber.number);
      console.log('Email:', this.emailForNotifications);

      const queryParams = new URLSearchParams({
        email: this.emailForNotifications,
        phone_number: this.selectedNumber.number
      }).toString();

      this.makeRequest({
        owner: this.requestOwners.user,
        method: 'GET',
        authRequired: true,
        url: `${this.apiRequests.user.setEmailNotification.url}?${queryParams}`,
      })
        .then(res => {
          this.isSubmittingEmail = false; // Set to false when request completes
          if (res?.success) {
            this.notify({
              type: "success",
              msg: res.message || "Email notification set successfully."
            });
            this.closeEmailDialog();
          } else {
            this.notify({
              type: "error",
              msg: res.message || "Failed to set email notification."
            });
          }
        })
        .catch(err => {
          this.isSubmittingEmail = false; // Set to false if there's an error
          this.notify({
            type: "error",
            msg: err.message || "An error occurred while setting email notification."
          });
        });
    },
    updateOpeningMessagesState(bool) {
      this.areMessagesOpen =
        typeof bool === "boolean" ? bool : !this.areMessagesOpen;
    },
    copyNumber() {
      this.copyText({ text: this.item.number });
    },   
    stopTimer_sIntervalForNumberAvailability(){
      if(this.timerIntervalForAvailability && this.timerIntervalForAvailability > 0){
          window.clearInterval(this.timerIntervalForAvailability);
          this.timerIntervalForAvailability = undefined;
      }
    },
    stopTimer_sIntervalForBeingOnline(){
      if(this.timerIntervalUntilBeingOnline && this.timerIntervalUntilBeingOnline > 0){
          window.clearInterval(this.timerIntervalUntilBeingOnline);
          this.timerIntervalUntilBeingOnline = undefined;
      }
    },
    getCaledTime(){
        let calcedTimeByMs = 0;
        const expirationDate = this.item?.expires_at;
        const creationDate = this.item?.created_at;
        // if long type, calculate the different between the creation and expiration data. If short, put 15 minutes difference
        if(checkServiceType({expiresAt: expirationDate, createdAt: creationDate}) == 'short'){
            // short period lasts for 15 minutes
            const _15Minutes = 900000;
            const totalMs = new Date(creationDate).getTime() + _15Minutes;
            calcedTimeByMs = totalMs - new Date().getTime();
        }else{
          // long period - lastes more than 15 minutes
          calcedTimeByMs = (new Date(expirationDate).getTime() - new Date());
        }

        let secs = Math.floor(calcedTimeByMs / 1000);
        let mins = Math.floor(secs / 60);
        let hrs = Math.floor(mins / 60);
        let days = Math.floor(hrs/24);
        secs = secs % 60;
        mins = mins % 60;
        hrs = hrs % 24;
        let formattedTime = days > 1 ? `${days} days` :`${hrs > 0 ? `${hrs < 10 ? '0'+ hrs : hrs}:` : ''} ${(mins < 10 ? '0' : '')}${mins} : ${(secs < 10 ? '0' : '')}${ secs}`;
        if(calcedTimeByMs <=0){
          this.stopTimer_sIntervalForNumberAvailability();
          formattedTime = "00:00";
          this.deleteNumberFromList(this.item.id);
        }
        if(days > 1){
          this.stopTimer_sIntervalForNumberAvailability();
        }
        return formattedTime;
    },
    getTimeLeftUntilOnline(onlineDate) {
      if (!onlineDate) {
        return null;
      }
      const now = new Date();
      const expiration = new Date(onlineDate);

      // Calculate the difference in milliseconds
      let timeDiff = expiration - now;

      if (timeDiff <= 0) {
        return null;
      }

      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      timeDiff -= days * (1000 * 60 * 60 * 24);

      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      timeDiff -= hours * (1000 * 60 * 60);

      const minutes = Math.floor(timeDiff / (1000 * 60));
      timeDiff -= minutes * (1000 * 60);

      const seconds = Math.floor(timeDiff / 1000);
      return timeDiff > 0 ? `${days > 1 ? (days + 'd') : ''} ${hours >= 1 ? (hours + 'h') : ''} ${minutes >= 1 ? (minutes + 'm') : ''} ${seconds >= 1 ? (seconds + 's') : ''} ` : null;
    },
    startCounterForAvailability() {
      if(!this.item?.created_at || typeof this.item?.created_at !== 'string'){
        return;
      }
      this.stopTimer_sIntervalForNumberAvailability();
      this.timerIntervalForAvailability = setInterval(() => {
        this.availabilityTimerValue = `${this.getCaledTime()} left`;
      }, 1000);
    },
    startCounterUntilBeingOnline() {
      const canProceedCountingOuter = this.timeLeftUntilOnline && this.item?.till_change_date;
      this.stopTimer_sIntervalForBeingOnline();
      if(canProceedCountingOuter){
        this.timerIntervalUntilBeingOnline = setInterval(() => {
          const timeUntillOnline = this.item?.till_change_date;
          const canProceedCountingInner = this.timeLeftUntilOnline && timeUntillOnline;
          if(canProceedCountingInner){
            this.timeLeftUntilOnline = this.getTimeLeftUntilOnline(timeUntillOnline);
          }else{
            this.stopTimer_sIntervalForBeingOnline();
            this.timeLeftUntilOnline = null;
          }
        }, 1000);
      }
    },
    closeEmailDialog() {
      this.isEmailDialogOpen = false;
      this.emailForNotifications = '';
    },
  },
  computed: {
    hasNewMsg(){
        return this.newMessages.some(el => this.lowerString(el.phoneNumber?.tzid?.toString()) === this.lowerString(this.item?.tzid?.toString()));
    },
    isNumberActive(){
      return this.item?.ltr_status == 'online';
    },
    isEmailValid() {
      return this.emailRules.every(rule => rule(this.emailForNotifications) === true);
    },
    isLongServiceType() {
      return checkServiceType({
        expiresAt: this.item.expires_at,
        createdAt: this.item.created_at
      }) === 'long';
    }
  },
  watch: {
    hasNewMsg: {
        immediate: true,
        handler(val){
            if(typeof val === "boolean" && val){
                this.areMessagesOpen = true;
            }
        },
    },
    "item.till_change_date"(){
      this.startCounterUntilBeingOnline();
    },
    isNumberActive(){
      this.startCounterUntilBeingOnline();
    },
  },
  mounted(){
    this.startCounterForAvailability();
    this.startCounterUntilBeingOnline();
    
  },
  beforeDestroy() {
     this.stopTimer_sIntervalForNumberAvailability(); 
     this.stopTimer_sIntervalForBeingOnline();
  }
};
</script>

<style lang="scss" scoped>
li {
  border-bottom: 1px dotted var(--borders-gray);
  width: 99%;
  transition: color 0.2s linear;
  .phone--number{
    transition: color 0.2s linear;
    font-size: 1rem;
  }
  .expiration__timer{
    display: inline-block;
    background: rgba(252, 146, 50, 0.1);
    color: var(--ultra-black);
    border-radius: 5px;
    padding: 0px 8px;
    font-size: 0.8rem;
  }
  &:hover {
    .phone--number--text {
     
      color: var(--primary-clr);
    }
  }
  &:last-of-type {
    border-bottom: none;
  }
}
.copy--number--icon,
.exension--icon {
  padding: 0.2rem;
  transition: transform 0.2s linear;
  border-radius: 50%;
}
.copy--number--icon{
  &:hover {
    background: rgba(0,0, 0, 0.1);
  }
}
</style>

