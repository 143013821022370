<template>
    <li v-on:dblclick="counter += 1, onDoubleClicking({item})" @click="() => onServiceClick({item})" :id="item.id" ref="serviceItem" :class="`service--item flex-row ${isLoading ? 'disabled' : ''} ${(isSelected && !isMobile) ? 'active': ''}`.trim()">
        <div class="service--item--inner flex-row align-center justify-between w-100">
            <div class="flex-row align-center">
                <img @error="(e) => onImgFailing(e)" v-if="isRentNumber && item.image" class="service--img" :src="item.image" :alt="item.name"/>
                <v-avatar v-else-if="hasImageFailedLoading" class="message--user--img" color="#F97314" :size="28">
                        <strong :style="{color: '#fff'}">{{typeof item.name === "string" ? item.name.charAt(0).toUpperCase() : ''}}</strong>
                </v-avatar>
                <ServiceIcon v-else :service="item.name" :size="28"/>
                <span class="ml-3">{{item.name}}</span>
            </div>
            <!-- <strong v-if="isRentNumber">${{ Math.round(calcedRentPrice * 100) / 100 }}/month</strong> -->
            <strong v-if="typeof item.price === 'number' || typeof item.price === 'string' ">${{+item.price}}</strong>
        </div>
    </li>
</template>

<script>
import ServiceIcon from '@/components/Generic/ServiceIcon.vue';
export default {
    data() {
        return{
            hasImageFailedLoading: false,
            counter: 0
        }
    },
    props: {
        profitPercentage: {
            required: true
        },
        item:{
            type: Object,
            required: true    
        },
        isRentNumber: {
            type: Boolean,
            required: false,
            default: false
        },
        isSelected: {
            type: Boolean,
            required: true
        },
        changeSelectedService: {
            type: Function,
            required: true
        },
        isMobile: {
            type: Boolean,
            required:false,
            default: false
        },
        onGenerateNumberClick: {
            type: Function,
            required: true
        },
        selectedCountry: {
            type: Object,
            required: true,
            default: () => {}
        },
        isLoading: {
            type: Boolean,
            required: true,
            default: false
        },
        hasRecentlyGeneratedNumber:{
            type: Boolean,
            required: true,
        },
        updateRecentlyGeneratedNumber: {
            type: Function,
            required: true
        }
    },
    methods: {
        onServiceClick({item}){
            this.changeSelectedService({serviceItem: item, isMobile: this.isMobile});
        },
        onDoubleClicking({item}){
            if(this.isLoading || this.isMobile){
                return;
            }
            this.onGenerateNumberClick({selectedCountry: this.selectedCountry, selectedService: item});
        },
        scrollToView(){
            if(this.$refs.serviceItem) {
                this.$refs.serviceItem?.scrollIntoView({
                        top: 0,
                        behavior: 'smooth',
                        block: "center"
                });
            }    
        },
        onImgFailing(e){
            this.hasImageFailedLoading = true;
            e.onerror = null;
            return true;
        }
    },
    watch: {
        hasRecentlyGeneratedNumber: {
            immediate: true,
            handler(val){
                if(this.isSelected && val){
                    this.scrollToView();
                    this.updateRecentlyGeneratedNumber(false);
                }
            }

        }
    },
    computed: {
        calcedRentPrice(){
            const {item, isRentNumber, profitPercentage} = this;
            const priceByDay = item.price;
            if(isRentNumber && (typeof priceByDay !== 'string' && typeof priceByDay !== 'number') && profitPercentage){
                return "";
            }
            const partialPrice = (+priceByDay * 7);
            
            return typeof partialPrice === 'number' ? (partialPrice * (+profitPercentage + 100) / 100) : "";
        }
    },
    components: {
        ServiceIcon
    },
}
</script>

<style lang="scss" scoped>
    .service--item{
        .service--img{
            width: 28px;
            height: 28px;
            object-fit: contain;
            border-radius: 50%;
            border: 1px solid var(--light);
        }
        border-bottom: 1px dashed var(--borders-gray);
        cursor: pointer;
        padding: 8px 0.95rem;
        transition: 0.2s linear;
        transition-property: background, font-weight, color;
        &:hover{
            color: var(--primary-clr);
        }
        &, .service--item--inner{
            align-items: center;
        }
        &:last-of-type {
            border-bottom: none;
        }
    }
    .service--item.active{
        background: rgba(249, 115, 20, 0.5);
        color: #fff;
        font-weight: bold;
    }
    #homeDesign{
        .service--item{
            border-bottom: none;
            padding: 6.5px 0.95rem;
            border-radius: 7px;
        }
        .service--item.active{
            background: #fa812254;
            color: #1E282D;
        }
    }
    body.dark{
        #homeDesign{
            .service--item.active{
                background: #934316;
                color: white;
            }
        }
    }
</style>