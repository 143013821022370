<template>
  <v-app id="app">
    <!-- Modals & Global Loading Screen -->
    <vue-confirm-dialog></vue-confirm-dialog>
    <!-- Different Layouts -->
    <router-view />
  </v-app>
</template>
<script>
import { localStorageBasicData } from '@/utilities/basicData';
import { mapActions, mapGetters } from 'vuex';
export default {
  methods: {
    ...mapActions('main', ['updateMemory']),
  },
  created() {
    const newLocStorage = this.locStorage({ type: 'get' });
    if (newLocStorage && Object.keys(newLocStorage || {}).length > 0) {
      this.updateMemory({ type: 'set', storage: newLocStorage });
    } else {
      this.updateMemory({ type: 'set', storage: localStorageBasicData, createEmptyObject: true });
    }
  },
  computed: {
    ...mapGetters('main', ['getLocalStorage']),
  },
  watch: {
    // localstorage listener
    getLocalStorage: {
      deep: true,
      immediate: true,
      handler: function (ls) {
        if (typeof ls.isDarkmode === 'boolean') {
          this.$vuetify.theme.dark = ls.isDarkmode;
          if (ls.isDarkmode) {
            document.body.classList.add('dark');
            document.documentElement.classList.add('dark');
          } else {
            document.body.classList.remove('dark');
            document.documentElement.classList.remove('dark');
          }
        }
      },
    },
  },
};
</script>
