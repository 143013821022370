<template>
  <div class="loading">
    <span
      class="
        animate-spin
        h-12
        w-12
        border-4 border-orange-400 border-t-white
        rounded-full
      "
    ></span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.65);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>