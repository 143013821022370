import { localStorageBasicData } from "../utilities/basicData";
export const isString = (x) => Object.prototype.toString.call(x) === "[object String]";

export const checkServiceType = ({createdAt, expiresAt}) => {
  const creationDate = new Date(createdAt);
  const expDate = new Date(expiresAt);

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = expDate - creationDate;
  
  const _15Minutes = 900000;
  // Return the service type 
  return differenceInMilliseconds > _15Minutes ? 'long' : 'short';
}

export const locStorage = ({type, newData, destination}) =>{
    const parsedBasicData = JSON.parse(JSON.stringify(localStorageBasicData));
    const appNameOnLocalstorage = "fox_sims";
    if(type === "set" && (Array.isArray(newData) || typeof newData === "object" || isString(newData) ) || typeof newData === "boolean"){
      const storageCopy = locStorage({type: "get"});
      const newLocStorageArr = {...storageCopy, [destination] : newData};
      Object.keys(parsedBasicData || {}).forEach(key => {
          if(!newLocStorageArr.hasOwnProperty(key)){
              newLocStorageArr[key] = parsedBasicData[key];
          }
      });
      localStorage.setItem(appNameOnLocalstorage, JSON.stringify(newLocStorageArr));
      
    } else if(type === "get"){
      return JSON.parse(localStorage.getItem(appNameOnLocalstorage));
    } else if(type === "reset" && typeof newData === "object" && newData !== null){
      localStorage.setItem(appNameOnLocalstorage, JSON.stringify(newData));
    } else if(type === "clear"){
      localStorage.clear();
    }
    return {};
}