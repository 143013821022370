<template>
  <Page title="" description="" class="relative">
      <Banner />

      <div class>
        <section id="landing" class="app-container pt-[23px] md:pt-[50px]">
          <!-- <div
            class="font-bold text-fontColor-1 dark:text-white text-center pb-[30px] text-[20px] xs:text-[28px] md:text-[36px] lg:text-[45px]"
          >
            <h1>Generate your virtual</h1>
            <div class="leading-9">
              phone number
              <span class="text-first">now.</span>
            </div>
          </div> -->

          <!-- <div class="md:pt-[50px] px-0 sm:px-2 md:px-[20px]">
            <div class="grid justify-center items-center">
              <img src="../../../assets/imgs/home/map.svg" :draggable="false" class="max-w-[1100px] user-select-none w-full object-contain" alt="" />
            </div>
            <div class="flex justify-center relative h-[60px]">
              <button
                @click="onOrderClick"
                class="rounded-[28px] order-button font-bold sm:py-[0.225rem] sm:px-5 lg:px-6 xl:px-8 h-fit hover:bg-[#111] transition-colors duration-150 bg-fontColor-1 text-[14px] text-light-1 xs:absolute xs:top-[-20px] sm:top-[-50px] sm:text-[22px] md:text-[25px] lg:text-[27px] hover:dark:bg-white dark:bg-light-1 dark:text-fontColor-1"
              >ORDER NOW</button>
            </div>
          </div> -->

          <div v-if="websiteData && websiteData.siteData && websiteData.siteData.about_us" class="flex items-center flex-col">
            <h3
              class="text-center font-bold text-fontColor-1 gap-y-1 dark:text-light-1 pb-4 text-[18px] xs:text-[26px] md:text-[25px] xl:text-[27px]"
            >
              What does the service do
              <span
                class="bg-fontColor-1 text-light-1 dark:bg-light-1 dark:text-fontColor-1"
              >FoxSims.com?</span>
            </h3>
            <p
              class="text-[#575757] text-[14px] mt-.5 leading-6 dark:text-fontColor-6 max-w-[640px] text-center xs:text-[16px] md:text-[17px] lg:text-[18.5px] font-normal"
            >
             {{websiteData.siteData.about_us}}
            </p>
          </div>
        </section>
        <!-- Buy number section -->
        <BuyNumberSection class="pb-[25px]"/>

        <section v-if="websiteData && websiteData.siteData" id="feature" class="app-container pt-[25px] md:pt-[100px] pb-5">
          <h3
            class="text-center font-[700] text-fontColor-1 uppercase dark:text-light-1 pb-4 mb-4 text-[18.5px] xs:text-[22px] md:text-[26px] lg:text-[28px]"
          >
            OUR
            <span class="text-first">FEATURES</span>
          </h3>
          <div class="grid grid-cols-1 gap-[15px] md:grid-cols-2 xl:grid-cols-3">
            <div class="bg-first p-1 rounded-[10px] md:rounded-[40px] xl:rounded-[42px] h-fit">
              <div
                class="pt-[8px] flex flex-col items-center gap-0 relative"
              >
                <img
                v-if="websiteData?.siteData?.full_feature_first_icon"
                  class="h-[71px] w-[65%] md:w-[80%] xl:w-[50%] md:h-[80px] xl:h-[88px] object-contain"
                  @error="onImageError"
                  :src="websiteData?.siteData?.full_feature_first_icon.includes('https://') || websiteData?.siteData?.full_feature_first_icon.includes('http://') ? websiteData?.siteData?.full_feature_first_icon : `https://${websiteData?.siteData?.full_feature_first_icon}` "
                  alt=""
                />
                <h5
                  :class="` feature-title font-bold text-white text-[18px] pt-0.5 ellipsis-x1 leading-8 md:text-[19px] lg:text-[20.5px] p-2 text-center ${
                    websiteData?.siteData?.full_feature_first_title?.length >= 20 &&
                    ''
                  }`"
                >
                  {{websiteData?.siteData?.full_feature_first_title
                  }}
                  <!-- <p
                    v-if="
                      websiteData?.siteData?.full_feature_first_title?.length >= 20
                    "
                    class="hidden w-full text-[12px] bg-fontColor-3 text-white p-2 rounded-lg absolute top-0 left-0 dark:text-fontColor-1"
                  >
                    {{ websiteData?.siteData?.full_feature_first_title }}
                  </p> -->
                </h5>
              </div>
              <div
                :class="`bg-[#ffffff] h-full min-h-[130px] md:min-h-[120px] feature-title relative rounded-[10px] md:rounded-ee-[40px] md:rounded-es-[40px] xl:rounded-ee-[42px] xl:rounded-es-[42px] pt-2 px-4 pb-0 text-[14px] xs:text-[15px] text-fontColor-1 dark:text-fontColor-6 dark:bg-[#1E282D] ${
                  websiteData?.siteData?.full_feature_first_text?.length >= 20 &&
                  ''
                }`"
              >
              <p class="ellipsis-x5 leading-5.5">
                {{ websiteData?.siteData?.full_feature_first_text }}
              </p>
              
                <!-- <p
                  v-if="
                    websiteData?.siteData?.full_feature_first_text?.length >= 100
                  "
                  class="hidden w-full text-[12px] bg-fontColor-3 text-white p-2 rounded-lg absolute top-0 left-0 dark:text-fontColor-1"
                >
                  {{ websiteData?.siteData?.full_feature_first_text }}
                </p> -->
              </div>
            </div>


            <div class="bg-first p-1 rounded-[10px] md:rounded-[40px] xl:rounded-[42px] h-fit">
              <div
                class="pt-[8px] flex flex-col items-center gap-0 relative"
              >
              <img
              v-if="websiteData?.siteData?.full_feature_second_icon"
                  class="h-[71px] w-[65%] md:w-[80%] xl:w-[50%] md:h-[80px] xl:h-[88px] object-contain"
                  @error="onImageError"
                  :src="websiteData?.siteData?.full_feature_second_icon.includes('https://') || websiteData?.siteData?.full_feature_second_icon.includes('http://') ? websiteData?.siteData?.full_feature_second_icon : `https://${websiteData?.siteData?.full_feature_second_icon}` "
                  alt=""
                />
                <h5
                  :class="` feature-title font-bold text-white text-[18px] pt-0.5 ellipsis-x1 leading-8 md:text-[19px] lg:text-[20.5px] p-2 text-center ${
                    websiteData?.siteData?.full_feature_second_title?.length >= 20 &&
                    ''
                  }`"
                >
                  {{websiteData?.siteData?.full_feature_second_title
                  }}
                  <!-- <p
                    v-if="
                      websiteData?.siteData?.full_feature_second_title?.length >= 20
                    "
                    class="hidden w-full text-[12px] bg-fontColor-3 text-white p-2 rounded-lg absolute top-0 left-0 dark:text-fontColor-1"
                  >
                    {{ websiteData?.siteData?.full_feature_second_title }}
                  </p> -->
                </h5>
              </div>
              <div
                :class="`bg-[#ffffff] h-full min-h-[130px] md:min-h-[120px] feature-title relative rounded-[10px] md:rounded-ee-[40px] md:rounded-es-[40px] xl:rounded-ee-[42px] xl:rounded-es-[42px] pt-2 px-4 pb-0 text-[14px] xs:text-[15px] text-fontColor-1 dark:text-fontColor-6 dark:bg-[#1E282D] ${
                  websiteData?.siteData?.full_feature_second_text?.length >= 20 &&
                  ''
                }`"
              >
                <p class="ellipsis-x5 leading-5.5">
                  {{ websiteData?.siteData?.full_feature_second_text }}
                </p>
                <!-- <p
                  v-if="
                    websiteData?.siteData?.full_feature_second_text?.length >= 100
                  "
                  class="hidden w-full text-[12px] bg-fontColor-3 text-white p-2 rounded-lg absolute top-0 left-0 dark:text-fontColor-1"
                >
                  {{ websiteData?.siteData?.full_feature_second_text }}
                </p> -->
              </div>
            </div>

            <div class="bg-first p-1 rounded-[10px] md:rounded-[40px] xl:rounded-[42px] h-fit">
              <div
                class="pt-[8px] flex flex-col items-center gap-0 relative"
              >
              <img
              v-if="websiteData?.siteData?.full_feature_third_icon"
                  class="h-[71px] w-[65%] md:w-[80%] xl:w-[50%] md:h-[80px] xl:h-[88px] object-contain"
                  @error="onImageError"
                  :src="websiteData?.siteData?.full_feature_third_icon.includes('https://') || websiteData?.siteData?.full_feature_third_icon.includes('http://') ? websiteData?.siteData?.full_feature_third_icon : `https://${websiteData?.siteData?.full_feature_third_icon}` "
                  alt=""
                />
                <h5
                  :class="` feature-title font-bold text-white text-[18px] pt-0.5 ellipsis-x1 leading-8 md:text-[19px] lg:text-[20.5px] p-2 text-center ${
                    websiteData?.siteData?.full_feature_third_title?.length >= 20 &&
                    ''
                  }`"
                >
                  {{ websiteData?.siteData?.full_feature_third_title }}
                  <!-- <p
                    v-if="
                      websiteData?.siteData?.full_feature_third_title?.length >= 20
                    "
                    class="hidden w-full text-[12px] bg-fontColor-3 text-white p-2 rounded-lg absolute top-0 left-0 dark:text-fontColor-1"
                  >
                    {{ websiteData?.siteData?.full_feature_third_title }}
                  </p> -->
                </h5>
              </div>
              <div
                :class="`bg-[#ffffff] h-full min-h-[130px] md:min-h-[120px] feature-title relative rounded-[10px] md:rounded-ee-[40px] md:rounded-es-[40px] xl:rounded-ee-[42px] xl:rounded-es-[42px] pt-2 px-4 pb-0 text-[14px] xs:text-[15px] text-fontColor-1 dark:text-fontColor-6 dark:bg-[#1E282D] ${
                  websiteData?.siteData?.full_feature_third_text?.length >= 20 &&
                  ''
                }`"
              >
                <p class="ellipsis-x5 leading-5.5">
                  {{ websiteData?.siteData?.full_feature_third_text }}
                </p>
                <!-- <p
                  v-if="
                    websiteData?.siteData?.full_feature_third_text?.length >= 100
                  "
                  class="hidden w-full text-[12px] bg-fontColor-3 text-white p-2 rounded-lg absolute top-0 left-0 dark:text-fontColor-1"
                >
                  {{ websiteData?.siteData?.full_feature_third_text }}
                </p> -->
              </div>
            </div>

            <div class="bg-first p-1 rounded-[10px] md:rounded-[40px] xl:rounded-[42px] h-fit">
              <div
                class="pt-[8px] flex flex-col items-center gap-0 relative"
              >
              <img
              v-if="websiteData?.siteData?.full_feature_forth_icon"
                  class="h-[71px] w-[65%] md:w-[80%] xl:w-[50%] md:h-[80px] xl:h-[88px] object-contain"
                  @error="onImageError"
                  :src="websiteData?.siteData?.full_feature_forth_icon.includes('https://') || websiteData?.siteData?.full_feature_forth_icon.includes('http://') ? websiteData?.siteData?.full_feature_forth_icon : `https://${websiteData?.siteData?.full_feature_forth_icon}` "             
                  alt=""
                />
                <h5
                  :class="` feature-title font-bold text-white text-[18px] pt-0.5 ellipsis-x1 leading-8 md:text-[19px] lg:text-[20.5px] p-2 text-center ${
                    websiteData?.siteData?.full_feature_forth_title?.length >= 20 &&
                    ''
                  }`"
                >
                  {{ websiteData?.siteData?.full_feature_forth_title }}
                  <!-- <p
                    v-if="
                      websiteData?.siteData?.full_feature_forth_title?.length >= 20
                    "
                    class="hidden w-full text-[12px] bg-fontColor-3 text-white p-2 rounded-lg absolute top-0 left-0 dark:text-fontColor-1"
                  >
                    {{ websiteData?.siteData?.full_feature_forth_title }}
                  </p> -->
                </h5>
              </div>
              <div
                :class="`bg-[#ffffff] h-full min-h-[130px] md:min-h-[120px] feature-title relative rounded-[10px] md:rounded-ee-[40px] md:rounded-es-[40px] xl:rounded-ee-[42px] xl:rounded-es-[42px] pt-2 px-4 pb-0 text-[14px] xs:text-[15px] text-fontColor-1 dark:text-fontColor-6 dark:bg-[#1E282D] ${
                  websiteData?.siteData?.full_feature_forth_text?.length >= 20 &&
                  ''
                }`"
              >
                <p class="ellipsis-x5 leading-5.5">
                  {{ websiteData?.siteData?.full_feature_forth_text }}
                </p>
                
                <!-- <p
                  v-if="
                    websiteData?.siteData?.full_feature_forth_text?.length >= 100
                  "
                  class="hidden w-full text-[12px] bg-fontColor-3 text-white p-2 rounded-lg absolute top-0 left-0 dark:text-fontColor-1"
                >
                  {{ websiteData?.siteData?.full_feature_forth_text }}
                </p> -->
              </div>
            </div>


            <div class="bg-first p-1 rounded-[10px] md:rounded-[40px] xl:rounded-[42px] h-fit">
              <div
                class="pt-[8px] flex flex-col items-center gap-0 relative"
              >
              <img
              v-if="websiteData?.siteData?.full_feature_fifth_icon"
                  class="h-[71px] w-[65%] md:w-[80%] xl:w-[50%] md:h-[80px] xl:h-[88px] object-contain"
                  @error="onImageError"
                  :src="websiteData?.siteData?.full_feature_fifth_icon.includes('https://') || websiteData?.siteData?.full_feature_fifth_icon.includes('http://') ? websiteData?.siteData?.full_feature_fifth_icon : `https://${websiteData?.siteData?.full_feature_fifth_icon}` "              
                  alt=""
                />
                <h5
                  :class="` feature-title font-bold text-white text-[18px] pt-0.5 ellipsis-x1 leading-8 md:text-[19px] lg:text-[20.5px] p-2 text-center ${
                    websiteData?.siteData?.full_feature_fifth_title?.length >= 20 &&
                    ''
                  }`"
                >
                  {{ websiteData?.siteData?.full_feature_fifth_title }}
                  <!-- <p
                    v-if="
                      websiteData?.siteData?.full_feature_fifth_title?.length >= 20
                    "
                    class="hidden w-full text-[12px] bg-fontColor-3 text-white p-2 rounded-lg absolute top-0 left-0 dark:text-fontColor-1"
                  >
                    {{ websiteData?.siteData?.full_feature_fifth_title }}
                  </p> -->
                </h5>
              </div>
              <div
                :class="`bg-[#ffffff] h-full min-h-[130px] md:min-h-[120px] feature-title relative rounded-[10px] md:rounded-ee-[40px] md:rounded-es-[40px] xl:rounded-ee-[42px] xl:rounded-es-[42px] pt-2 px-4 pb-0 text-[14px] xs:text-[15px] text-fontColor-1 dark:text-fontColor-6 dark:bg-[#1E282D] ${
                  websiteData?.siteData?.full_feature_fifth_text?.length >= 20 &&
                  ''
                }`"
              >
                <p class="ellipsis-x5 leading-5.5">
                  {{ websiteData?.siteData?.full_feature_fifth_text }}
                </p>
                <!-- <p
                  v-if="
                    websiteData?.siteData?.full_feature_fifth_text?.length >= 100
                  "
                  class="hidden w-full text-[12px] bg-fontColor-3 text-white p-2 rounded-lg absolute top-0 left-0 dark:text-fontColor-1"
                >
                  {{ websiteData?.siteData?.full_feature_fifth_text }}
                </p> -->
              </div>
            </div>


            <div class="bg-first p-1 rounded-[10px] md:rounded-[40px] xl:rounded-[42px] h-fit">
              <div
                class="pt-[8px] flex flex-col items-center gap-0 relative"
              >
              <img
              v-if="websiteData?.siteData?.full_feature_sixth_icon"
                  class="h-[71px] w-[65%] md:w-[80%] xl:w-[50%] md:h-[80px] xl:h-[88px] object-contain"
                  @error="onImageError"
                  :src="websiteData?.siteData?.full_feature_sixth_icon.includes('https://') || websiteData?.siteData?.full_feature_sixth_icon.includes('http://') ? websiteData?.siteData?.full_feature_sixth_icon : `https://${websiteData?.siteData?.full_feature_sixth_icon}` "                alt=""
                />
                <h5
                  :class="` feature-title font-bold text-white text-[18px] pt-0.5 ellipsis-x1 leading-8 md:text-[19px] lg:text-[20.5px] p-2 text-center ${
                    websiteData?.siteData?.full_feature_sixth_title?.length >= 20 &&
                    ''
                  }`"
                >
                  {{ websiteData?.siteData?.full_feature_sixth_title }}
                  <!-- <p
                    v-if="
                      websiteData?.siteData?.full_feature_sixth_title?.length >= 20
                    "
                    class="hidden w-full text-[12px] bg-fontColor-3 text-white p-2 rounded-lg absolute top-0 left-0 dark:text-fontColor-1"
                  >
                    {{ websiteData?.siteData?.full_feature_sixth_title }}
                  </p> -->
                </h5>
              </div>
              <div
                :class="`bg-[#ffffff] h-full min-h-[130px] md:min-h-[120px] feature-title relative rounded-[10px] md:rounded-ee-[40px] md:rounded-es-[40px] xl:rounded-ee-[42px] xl:rounded-es-[42px] pt-2 px-4 pb-0 text-[14px] xs:text-[15px] text-fontColor-1 dark:text-fontColor-6 dark:bg-[#1E282D] ${
                  websiteData?.siteData?.full_feature_sixth_text?.length >= 20 &&
                  ''
                }`"
              >
                <p class="ellipsis-x5 leading-5.5">
                  {{ websiteData?.siteData?.full_feature_sixth_text }}
                </p>
                <!-- <p
                  v-if="
                    websiteData?.siteData?.full_feature_sixth_text?.length >= 100
                  "
                  class="hidden w-full text-[12px] bg-fontColor-3 text-white p-2 rounded-lg absolute top-0 left-0 dark:text-fontColor-1"
                >
                  {{ websiteData?.siteData?.full_feature_sixth_text }}
                </p> -->
              </div>
            </div>


          </div>
        </section>

        <section v-if="onboarding && onboarding.length >0" id="how-use" class="app-container pt-[40px] md:pt-[100px]">
          <div>
            <h3
              class="text-center font-[700] text-fontColor-1 dark:text-light-1 pb-5 mb-0.5 text-[18.5px] xs:text-[22px] md:text-[26px] lg:text-[28px]"
            >
              HOW IT
              <span class="text-first">WORKS?</span>
            </h3>
          </div>
          <nav class="how-it-work">
            <ul
              id="how-it-work"
              class="flex flex-wrap gap-2 p-0 justify-center text-[#1E282D] dark:text-fontColor-6 uppercase text-[12px] xs:text-[14px] xs:gap-[10px] md:text-[18px]"
            >
              <li
                v-for="(video , i) in onboarding"
                :key="i"
                @click="(e) => {toggleHowItWork(e); changeVideo(video?.video_path)}"
                :class="`bg-[#D8D8D8] text-[#949494] transition-colors duration-150 hover:brightness-95 font-medium dark:bg-fontColor-1 dark:text-fontColor-6 text-[16px] capitalize md:text-[18px] lg:text-[21px] xl:text-[22px] rounded-[4px] py-1.5 md:py-1 px-3.5 cursor-pointer w-full md:w-auto  ${i == 0 ? 'active': ''}`"
              >{{ `${i +1}. ${video?.title}` }}</li>
            </ul>
          </nav>
          <div class="mt-4 md:pt-3.5 mb-[50px] md:mb-[100px]">
            <div @click="toggleVideoState" @mouseenter="timeoutVideoControls" class="w-full cursor-pointer flex relative h-[300px] md:h-[500px] rounded-[10px] md:rounded-[24px] lg:rounded-[26px] xl:rounded-[29px] overflow-hidden md:max-w-[90%] lg:max-w-[80%] xl:max-w-[70%] mx-auto">
              <transition name="videoControlsTransition">
                <div v-if="showVideoControls" class="absolute z-[3] bg-[#000]/30 top-0 left-0 w-full h-full flex justify-center items-center">
                  <div >
                    <button class="bg-[#FA8122] grid place-items-center hover:brightness-110 transition-all duration-150 rounded-full w-[60px] h-[60px] sm:w-[90px] sm:h-[90px] md:w-[100px] md:h-[100px]  xl:w-[110px] xl:h-[110px]" aria-label="play/pause" aria-labelledby="play/pause">
                      <span v-if="!isVideoPlaying">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-[30px] h-[30px] sm:w-[35px] sm:h-[35px]  md:w-[50px] md:h-[50px] xl:w-[62px] xl:h-[62px]" width="60" height="60" viewBox="0 0 256 256"><path fill="white" d="M240 128a15.74 15.74 0 0 1-7.6 13.51L88.32 229.65a16 16 0 0 1-16.2.3A15.86 15.86 0 0 1 64 216.13V39.87a15.86 15.86 0 0 1 8.12-13.82a16 16 0 0 1 16.2.3l144.08 88.14A15.74 15.74 0 0 1 240 128Z"/></svg>
                      </span>
                      <span v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-[30px] h-[30px] sm:w-[35px] sm:h-[35px] md:w-[50px] md:h-[50px] xl:w-[62px] xl:h-[62px]" width="60" height="60" viewBox="0 0 256 256"><path fill="white" d="M216 48v160a16 16 0 0 1-16 16h-40a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h40a16 16 0 0 1 16 16ZM96 32H56a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h40a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Z"/></svg>
                      </span>
                    </button>
                  </div>
                </div>
              </transition>

              <video
                :src="currentVideoLink ? currentVideoLink : onboarding && onboarding.length && onboarding[0].video_path"
                width="100%"
                style="border: none"
                @loadeddata="onVideoLoad"
                @pause="()=> changePlayingState(false)"
                @play="()=> changePlayingState(true)"
                :muted="false"
                controlsList="nodownload"
                class="w-full flex-1 h-full object-cover"
                :playsinline="true"
                :draggable="false"
                ref="videoRef"
                loading="lazy"
                :controls="false"
              />
            </div>
          </div>
        </section>

        <section v-if="faqsSections && faqsSections.length >0" class="bg-[#F8F8F8] dark:bg-fontColor-1">
          <div class="app-container pt-[30px] pb-[35px] md:pt-[35px] md:pb-[60px]">
            <h3
              class="text-center font-[700] text-fontColor-1 uppercase dark:text-light-1 pb-4 mb-3 text-[18.5px] xs:text-[22px] md:text-[26px] lg:text-[28px]"
            >
              HAVE ANY
              <span class="text-first">QUESTIONS?</span>
            </h3>

            <div>
              <nav class="any-questions">
                <ul
                  id="any-questions"
                  class="flex flex-wrap gap-2 justify-center text-[#1E282D] dark:text-fontColor-6 uppercase text-[12px] xs:text-[14px] xs:gap-[20px] md:text-[18px] md:gap-[40px]"
                >
                  <li
                    v-for="(category , idx) in faqsSections"
                    :key="idx"
                    @click="(e) => {toggleQuestion(e); changeCategory(category?.id)}"
                    :class="`cursor-pointer text-[15px] sm:text-[16px] md:text-[16.6px] font-medium uppercase hover-underline-animation  ${idx == 0 && 'active'}`"
                  >{{ category?.name }}</li>
                </ul>
              </nav>
              <div class="pt-[30px] md:pt-[50px] flex flex-wrap gap-x-[4%] gap-y-6">
                <div v-for="(question , index) in currentQustions" :key="index" class="lg:w-[48%]">
                  <h3
                    class="font-bold text-fontColor-1 dark:text-light-1 text-[19px] md:text-[26px] lg:text-[28px]"
                  >{{ capFirstLetter(question?.question) }}</h3>
                  <p
                    class="pt-[8.5px] text-[15px] xs:text-[16px] xl:text-[17px] font-medium multi-lines-text text-[#7D7D7D] dark:text-[#B3BDC2] dark:bg-fontColor-1"
                  >{{ capFirstLetter(question?.answer) }}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section v-if="testimonials.length > 0">
          <Testimonials :testimonials="testimonials"/>
        </section>
        <section class="bg-[#192124] py-[40px]">
          <ul class="flex justify-center items-center gap-[10px] md:gap-[30px]">
            <li>
              <img class="h-[25px] md:h-[40px] lg:h-[49px] object-contain" src="../../../assets/imgs/home/master-card.svg" alt />
            </li>
            <li>
              <img class="h-[20px] md:h-[29px] lg:h-[35px] object-contain" src="../../../assets/imgs/home/paypal.svg" alt />
            </li>
            <li>
              <img class="h-[25px] md:h-[40px] lg:h-[49px] object-contain" src="../../../assets/imgs/home/Bitcoin.svg" alt />
            </li>
            <li>
              <img class="h-[25px] md:h-[40px] lg:h-[49px] object-contain" src="../../../assets/imgs/home/visa.svg" alt />
            </li>
          </ul>
        </section>
      </div>
  </Page>
</template>

<script>
import Testimonials from "../../../components/Main/testimonials.vue";
import Banner from "../../../components/Generic/Banner.vue";
import { mapState, mapGetters, mapActions } from 'vuex';
import Page from "@/components/Generic/Page.vue";
import BuyNumberSection from "../../../components/Main/Homepage/BuyNumberSection.vue";

export default {
  data(){
    return {
      currentVideoLink:  "",
      hasUserInteractedWithTheDOM: false,
      isVideoPlaying: false,
      showVideoControls: false,
      controlsInverval: undefined,
    }
  },
  components: {
    Testimonials,
    Page,
    Banner,
    BuyNumberSection
  },
  computed:{
    ...mapState("main", ['faqs', "sellectedCategoryId", "onboarding", "faqsSections", "testimonials"]),
    ...mapState("website", ["websiteData"]),
    ...mapGetters("website", { isLoggedIn: "getIsAuth" }),
    currentQustions(){
      const faqs = this.faqs.filter(item => {
        return item?.category?.id == this.sellectedCategoryId;
      });
      return faqs;
    },
    bannerHomepageData(){
      const siteSettingsObj = this.getLocalStorage?.siteSettings;
      return {
        url: siteSettingsObj?.banner_first_url,
        image: siteSettingsObj?.banner_first_icon,
      }
    }
  },
  methods: {
    ...mapActions("main", ["updateSelectedCategoryId"]),   
    timeoutVideoControls(){
      const disappearanceTimeoutPerSeconds = 2;
      this.showVideoControls = true;
      window.clearTimeout(this.controlsInverval);
      this.controlsInverval = setTimeout(() => {
        this.showVideoControls = false;
      }, disappearanceTimeoutPerSeconds * 1000);
    },
    playVideo(){
      this.$refs?.videoRef?.play?.();
      this.timeoutVideoControls();
    },
    pauseVideo(){
      window.clearTimeout(this.controlsInverval);
      this.showVideoControls = true;
      this.$refs?.videoRef?.pause?.();
    },
    onVideoLoad(){
      if(!this.hasUserInteractedWithTheDOM){
        return;
      }
      this.playVideo();
    },

    toggleVideoState(){
      if(this.isVideoPlaying){
        this.pauseVideo();
      }else{
        this.playVideo();
      }
    },
    changePlayingState(newState){
      if(typeof newState !== 'boolean'){
        return;
      }
      this.isVideoPlaying = newState;
    },
    onOrderClick(){
      this.$router.push(this.isLoggedIn ? this.routesNames.user.dashboard : this.routesNames.user.login);
    },
    changeVideo(src) {
      this.currentVideoLink = src;
    },
    changeCategory(id) {
      this.updateSelectedCategoryId(id);
    },
    toggleHowItWork (e) {
      this.hasUserInteractedWithTheDOM = true;
      const lis = document.getElementById("how-it-work").getElementsByTagName("li");
      const currentElement = e.target;

      for (let li of lis) {
        li.classList.remove("active");
      }
      currentElement?.closest("li")?.classList?.add("active");
    },
    toggleQuestion (e) {
      const lis = document
        .getElementById("any-questions")
        .getElementsByTagName("li");
      const currentElement = e.target;

      for (let li of lis) {
        li.classList.remove("active");
      }
      currentElement?.closest("li")?.classList?.add("active");
    }
  },
  mounted(){
    this.showVideoControls = true;
  }
}
</script>


<style lang="scss" scoped>
.any-questions .active {
  color: #FA8122;
  &::after{
    transform: scaleX(1);
    transform-origin: bottom left;
    opacity: 1;
  }
}

.how-it-work .active {
  color: #fff;
  background-color: #FA8122;
}
.order-button {
  display: inline-block;
  color: rgb(255, 255, 255);
  outline: none;
  border: none;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;



  &::after{
    content: " ";
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid #1E282D;
    border-radius: 50px;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease;
    box-sizing: content-box;
  }
  @media only screen and (min-width: 468px) {
    &:hover{
      transform: scale(0.9);
    }
    &:hover::after{
      transform: translate(-50%, -50%) scale(1);
      padding: 0.3rem;
    }
  }

  a{
    white-space: nowrap;
  }
  @media only screen and (max-width: 768px) {
    padding: 9px 20px 9px 20px;
  }
}
body.dark{
  .order-button {
    &::after{
      border: 2px solid #FFFFFF;
    }
  }
}

.how-it-work .active {
  color: #fff;
  background-color: #fa8122;
}
// .feature-title:hover > p {
//   display: block;
// }
</style>