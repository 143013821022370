<template>
  <div id="search--input" :class="`${isFocused ? 'focused' : ''} ${isDisabled ? 'disabled': ''}`">
    <input type="text" :disabled="isDisabled" :autofocus="autoFocus" @input="$emit('input', $event.target.value)" :value="value" :placeholder="placeholder" :autocomplete="true" @focus="() => isFocused = true"  @blur="() => isFocused = false" />
    <div class="search--suffex flex-row">
       <v-icon class="clear--icon" @click="() => clearSearchInput()" v-show="value">mdi-close-circle</v-icon>

       <v-icon class="search--icon--1">mdi-magnify</v-icon>
       <img src="../../../assets/imgs/home/search.svg" alt="" loading="lazy" class="search--icon--2 w-[20px] h-[20px] md:w-[22px] md:h-[22px]" width="28" height="28" />
       <img src="../../../assets/imgs/home/search_dark.svg" alt="" loading="lazy" class="search--icon--2--dark w-[20px] h-[20px] md:w-[22px] md:h-[22px]" width="28" height="28" />
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            isFocused: false
        }
    },
    props: {
        value: {
            type: [Number, String, Boolean],
            required: false,
            default: ""
        },
        placeholder: {
            type: String,
            required: true
        },
        clearSearchInput: {
            type: Function,
            required: true
        },
        autoFocus: {
            type: Boolean,
            required: false,
            default: false
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>

    #search--input{
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        border: 1px solid var(--borders-gray);
        border-radius: 4px;
        overflow: hidden;
        transition: all 0.3s;
        width: 100%;
        margin-bottom: 10px;
        .search--icon--2{
            display: none;
        }
        .search--icon--2--dark{
            display: none;
        }
        input{
            border: none;
            box-shadow: none;
            &:focus{
                outline: none;
            }
            touch-action: manipulation;
            box-sizing: border-box;
            margin: 0;
            font-variant: tabular-nums;
            list-style: none;
            position: relative;
            display: inline-block;
            width: 100%;
            height: 35px;
            padding: 4px 30px 4px 11px;
            color: var(--ultra-black);
            font-size: 14px;
            line-height: 1.5;
            background-image: none;
            -webkit-appearance: none;
        }
        .search--suffex{     
            flex-wrap: nowrap;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: max-content;
            right: 0;
            align-items:center;
            right: 12px;
            z-index: 2;
            line-height: 0;
            .clear--icon{
                cursor: pointer;
               padding: 0.3rem;
            }
            .clear--icon, .search--icon--1{
                font-size: 0.95rem;
            }
            .search--icon--1{
                margin: 0.3rem 0 0.3rem 0;
            }
        }
    }   
    #homeDesign{
        #search--input{
            background: white;
            border: 1px solid #DDE1E4;
            .search--icon--2--dark{
                display: none;
            }
            .search--icon--1{
                display: none;
            }           
            .search--icon--2{
                display: block;
            }

            input{
                height: 47px;   
                font-size: 17px;     
                &::placeholder{
                    color: #949494;
                    font-size: 17px;
                }
            }
            @media screen and (max-width: 670px) {
                input{
                    height: 40px;
                }
            }
        }

    }
    body.dark{
        #homeDesign{
            #search--input{
                background: #1C282D;
                border:1.2px solid #373636;
                .search--icon--2{
                    display: none;
                }       
                .search--icon--1{
                    display: none;
                }
                .search--icon--2--dark{
                    display: block;
                }
                input{
                    &::placeholder{
                        color: #6A7080;
                    }
                }
            }
        }
        
    }
    #search--input.focused, #homeDesign #search--input.focused{
        border-color: var(--primary-clr);
        box-shadow: 0px 0px 4px var(--primary-clr);
    }


</style>