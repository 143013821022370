<template>
  <div class="w-full h-full relative flex flex-col items-center overflow-hidden text-gray-400 bg-fox-800">
    <div class="w-full sidebar-inner">
      <div class="w-full">
        <router-link to="/dashboard">
          <div class="h-[175px] block">
            <router-link to="/" title="Fox Sims" class="hide-if-close h-full flex items-center justify-center overflow-hidden w-full">
              <img class="w-50 max-w-full mx-auto" src="../../../assets/images/main-imgs/footer-logo.svg" alt="logo" />
            </router-link>
          </div>
        </router-link>
      </div>
      <ul class="dashboard-list w-full m-0 p-0 space-y-3.5">
        <li class="w-full" @click="onLinkClick">
          <router-link :to="{ name: 'userDashboardHome' }" title="Dashboard" class="sidebar-list-item w-full flex space-x-4 py-3 px-3 font-bold rounded">
            <span class="">
              <svg class="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path
                  d="M4 13h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1zm-1 7a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v4zm10 0a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v7zm1-10h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1z"
                ></path>
              </svg>
            </span>
            <span class="hide-if-close">Dashboard</span>
          </router-link>
        </li>
        <li class="w-full" @click="onLinkClick">
          <router-link :to="{ name: 'userDashboardMessages' }" title="Messages" class="sidebar-list-item w-full m-0 flex space-x-4 py-3 px-3 font-bold rounded">
            <span class="">
              <svg class="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M4 18h2v4.081L11.101 18H16c1.103 0 2-.897 2-2V8c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2z"></path>
                <path d="M20 2H8c-1.103 0-2 .897-2 2h12c1.103 0 2 .897 2 2v8c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2z"></path>
              </svg>
            </span>
            <span class="hide-if-close">Messages</span>
          </router-link>
        </li>
        <li class="w-full" @click="onLinkClick">
          <router-link :to="{ name: 'userDashboardBilling' }" title="Billing" class="sidebar-list-item w-full flex space-x-4 py-3 px-3 font-bold rounded">
            <span class="">
              <svg class="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M20 4H4c-1.103 0-2 .897-2 2v2h20V6c0-1.103-.897-2-2-2zM2 18c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-6H2v6zm3-3h6v2H5v-2z"></path>
              </svg>
            </span>
            <span class="hide-if-close">Billing</span>
          </router-link>
        </li>
        <li class="w-full" @click="onLinkClick">
          <router-link :to="{ name: 'userDashboardRentNumber' }" class="sidebar-list-item w-full flex space-x-4 py-3 px-3 font-bold rounded">
            <span class="">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                <path fill-rule="evenodd" d="M7.5 5.25a3 3 0 013-3h3a3 3 0 013 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0112 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 017.5 5.455V5.25zm7.5 0v.09a49.488 49.488 0 00-6 0v-.09a1.5 1.5 0 011.5-1.5h3a1.5 1.5 0 011.5 1.5zm-3 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clip-rule="evenodd" />
                <path d="M3 18.4v-2.796a4.3 4.3 0 00.713.31A26.226 26.226 0 0012 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 01-6.477-.427C4.047 21.128 3 19.852 3 18.4z" />
              </svg>
            </span>
            <span class="hide-if-close">The Rented Numbers</span>
          </router-link>
        </li>

        <v-dialog v-model="logoutModal" width="500">
          <template v-slot:activator="{ on }">
            <button v-on="on" to="" class="sidebar-list-item w-full flex space-x-4 py-3 px-3 font-bold rounded">
              <span class="">
                <svg class="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M16 13v-2H7V8l-5 4 5 4v-3z"></path>
                  <path d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"></path>
                </svg>
              </span>
              <span class="hide-if-close">Logout</span>
            </button>
          </template>
          <v-card class="mb-0 bg-gray-50 dark:bg-fox-900 border-0">
            <LogoutModal @close="closeModal" />
          </v-card>
        </v-dialog>
      </ul>
    </div>
  </div>
</template>

<script>
import LogoutModal from '../../../components/Main/LogoutModal.vue';

export default {
  components: { LogoutModal },
  data() {
    return {
      logoutModal: false,
    };
  },
  methods: {
    onLinkClick() {
      this.$emit('closeSidebar');
    },
    closeModal() {
      this.logoutModal = false;
    },
  },
};
</script>

<style scoped>
.dashboard-list .sidebar-list-item {
  color: white;
  background-color: #00000040;
  transition: background-color 0.25s ease-out;
}
.dashboard-list .sidebar-list-item span {
  transition: all 0.25s ease-out;
}
.dashboard-list .sidebar-list-item:hover span {
  margin-right: 0.5rem;
}
.dashboard-list a.router-link-exact-active {
  background-color: #f97316;
}
</style>
