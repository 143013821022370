<template>
        <span v-if="countryId" :class="`country--icon--inner ${spanClass ? spanClass : ''}`">
            <country-flag :country="countryId" size='normal'/>
        </span>
        <v-icon v-else :class="defaultClass ? defaultClass : ''" :style="{color: isSelected ? '#fff':''}" >mdi-web</v-icon>
</template>

<script>
import CountryFlag from 'vue-country-flag';
export default {
  props: {
    countryId: {
        type: [String, Number],
        required: true,
        default: ''
    },
    isSelected: {
        type: Boolean,
        required: false,
        default: false
    },
    spanClass: {
        type: String,
        required: false,
        default: ""
    },
    defaultClass: {
        type: String,
        required: false,
        default: ""
    }
  },
  components: {
    CountryFlag
  },
}
</script>

<style lang="scss" scoped>
    .normal-flag.normal-flag{
        margin-top: 0;
        margin-bottom: 0;
    }
    .country--icon--inner{
      span{
        border-radius: 4px;
      }
    }
</style>