<template>
  <div class="w-full">
    <v-dialog v-model="textCopiedConfirmation" width="500">
      <v-card class="mb-0">
        <div
          class="px-5 py-6 bg-gray-50 dark:bg-fox-800 dark:text-gray-100 space-y-8"
        >
          <p class="text-lg font-bold">
            This number has been copied to clipboard
          </p>

          <div class="flex items-center justify-center space-x-5">
            <!-- Close Button -->
            <button
              @click="() => (textCopiedConfirmation = false)"
              class="px-5 text-center py-2 inline-flex justify-center items-center space-x-2 text-white font-bold bg-gray-600 border-0 hover:bg-gray-700 rounded shadow-md"
            >
              <span>OK</span>
            </button>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- Rent number modal -->
    <!-- <v-dialog
      v-model="isSpecifyingDateModalOpen"
      :persistent="isAddingNumber"
      class="add--number--modal"
      max-width="600px"
    >
      <RentPeriodSelectionCard
        v-if="isSpecifyingDateModalOpen"
        :isAddingNumber="isAddingNumber"
        @closeModal="
          () => !isAddingNumber && (isSpecifyingDateModalOpen = false)
        "
        @submitRequest="(e) => onGenerateNumberClick(e)"
        :selectedService="selectedService"
        :errorCaptured="errorCaptured"
        :selectedPeriodTime.sync="selectedPeriodTime"
        :selectedPeriodCount.sync="selectedPeriodCount"
        :totalAmount="totalRentAmount"
        :daysCount="daysCount"
      />
    </v-dialog> -->
    <div
      v-if="isLoadingBalance || isLoading || isLoadingCountries"
      class="loading--div mt-5 bg-gray-50 dark:bg-fox-800"
    >
      <span class="buffering--loader"></span>
    </div>
    <div v-else :id="isHomepageDesign ? 'homeDesign': ''">
      <div v-if="!isHomepageDesign">
        <!-- show on mobile -->
        <button
          @click="() => changeShowingContainerOnMobileState()"
          :disabled="isLoading || isLoadingBalance"
          :class="
            `flex-row align-center flex-nowrap submit--btn mt-11 mb-5 mobile-only ${
              isLoading || isLoadingBalance ? 'disabled' : ''
            }`.trim()
          "
        >
          <v-icon size="20" class="mr-1" :style="{ color: '#fff' }"
            >mdi-cart</v-icon
          >
          <span v-if="isRentNumber">Rent a number</span>
          <span v-else>Get a number</span>
        </button>
      </div>

      <!-- @submit.prevent="()=> onGenerateNumberClick({ confirmed: isRentNumber })" -->
      <div
        :style="{ display: isLoadingBalance ? 'none' : 'flex' }"
        class="add--number--form row m-0 w-full"
      >
        <div
          :class="`${(isHomepageDesign && (activeNumbers && activeNumbers.length <=0))? 'col-xl-10 col-lg-10' : 'col-xl-6 col-lg-7'} col-md-12 col-sm-12 max-w-[1290px] mx-auto pt-5 px-0 flex-column`"
        >
          <div class="w-full">
            <!-- desktop -->
            <!-- select a country -->
            <div class="desktop-only w-full">
              <div
                :class="`add--number--container desktop--version flex-row ${isHomepageDesign ? 'bg-[#7388921A] dark:bg-[#1C282D]' : 'bg-gray-50 dark:bg-fox-800'}  `"
              >
                <div class="row w-full m-0">
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 px-2 pt-1">
                    <div v-if="isLoading" class="mt-6 grid place-items-center">
                      Loading...
                    </div>
                    <div v-if="countriesList && countriesList.length > 0">
                      <SearchInput
                        v-model="countrySearch"
                        :isDisabled="
                          areServicesLoading ||
                          (countriesList && countriesList.length <= 0)
                        "
                        :clearSearchInput="() => clearSearchInput('country')"
                        :placeholder="'Find state'"
                      />
                      <div :class="`items--list--container`">
                        <ul
                          @scroll="
                            (e) =>
                              updateListScrollingReachedBottom({
                                e,
                                type: 'countries',
                              })
                          "
                          class="countries--list list--container"
                        >
                          <CountryItem
                            v-for="item in filteredCountries"
                            :changeSelectedCountry="changeSelectedCountry"
                            :isSelected="
                              selectedCountry && selectedCountry.id === item.id
                            "
                            :key="item.id"
                            :item="item"
                            :isRentNumber="isRentNumber"
                          />
                        </ul>
                        <div v-show="!haveCountriesReachedBottom">
                          <div class="elevated__bottom" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- select a service -->
                  <div ref="servicesBoxRef" class="col-xl-8 col-lg-8 col-md-12 col-sm-12 pt-1">
                    <div>
                      <SearchInput
                        v-model="serviceSearch"
                        :isDisabled="
                          areServicesLoading ||
                          (allServices && allServices.length <= 0)
                        "
                        :clearSearchInput="() => clearSearchInput('service')"
                        placeholder="Find a service"
                      />
                      <div class="services--inner">
                        <div
                          v-show="areServicesLoading"
                          class="services--loader--container bg-gray-50 dark:bg-fox-800"
                        >
                          <span class="buffering--loader"></span>
                        </div>
                        <div
                          v-if="filteredServices && filteredServices.length > 0"
                          class="items--list--container"
                        >
                          <ul
                            @scroll="
                              (e) =>
                                updateListScrollingReachedBottom({
                                  e,
                                  type: 'services',
                                })
                            "
                            id="servicesList"
                            :style="{
                              overflowY: areServicesLoading ? 'hidden' : 'auto',
                            }"
                            class="services--list list--container"
                          >
                            <ServiceItem
                              v-for="item in filteredServices"
                              :changeSelectedService="changeSelectedService"
                              :isSelected="
                                selectedService &&
                                selectedService.id === item.id
                              "
                              :key="item.id"
                              :item="item"
                              :isRentNumber="isRentNumber"
                              :onGenerateNumberClick="onGenerateNumberClick"
                              :selectedCountry="selectedCountry"
                              :isLoading="isAddingNumber || isLoading"
                              :updateRecentlyGeneratedNumber="
                                updateRecentlyGeneratedNumber
                              "
                              :hasRecentlyGeneratedNumber="
                                hasRecentlyGeneratedNumber
                              "
                              :profitPercentage="profitPercentage"
                            />
                          </ul>
                          <div v-show="!haveServicesReachedBottom">
                            <div class="elevated__bottom" />
                          </div>
                        </div>
                        <div
                          v-else-if="!areServicesLoading"
                          class="border-gray-200 dark:border-gray-600 mt-5 alert"
                        >
                          <p class="p-0 m-0 text-center">
                            No services have been found.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-show="errorCaptured && !isRentNumber && !isAddingNumber"
                class="error--container fadeEffect alert alert-danger text-center w-full mt-4"
              >
                <p class="text-danger flex-row align-center m-0">
                  <v-icon class="mr-2 text-danger">mdi-alert</v-icon>
                  <span>{{ errorCaptured }}</span>
                </p>
              </div>
            </div>

            <!-- mobile - selection modal -->
            <div
              class="add--number--modal mobile-only"
              v-if="renderMobileView && showBuyNumberContainerOnMobile"
            >
              <div
                class="add--number--container flex-row bg-gray-50 dark:bg-fox-800"
              >
                <div
                  class="add--number--modal--backdrop"
                  @click="() => closeWindow()"
                ></div>
                <div
                  class="add--number--modal--inner bg-gray-50 dark:bg-fox-800"
                >
                  <div
                    class="add--number--modal--header bg-gray-100 dark:bg-slate-600 flex-row justify-between align-center"
                  >
                    <div class="flex-row flex-nowrap align-center">
                      <span
                        v-show="mobileStep > 1 && mobileStep <= 3"
                        @click="() => !isAddingNumber && goBack()"
                        :class="
                          `go--back--btn ${
                            isAddingNumber ? 'disabled' : ''
                          }`.trim()
                        "
                      >
                        <v-icon size="30" class="text-orange-500"
                          >mdi-chevron-left</v-icon
                        >
                      </span>
                      <span v-if="mobileStep === 1">01. Choose a state</span>
                      <div
                        v-if="mobileStep === 2"
                        class="flex-row align-center"
                      >
                        <span class="mr-5">02. Choose a service</span>
                        <div
                          v-if="selectedCountry.id"
                          class="flex-row align-center"
                        >
                          <CountryFlagIcon
                            spanClass="country--flag mr-3"
                            defaultClass="mr-1 country--default--icon"
                            :countryId="getCountryAbbrFromId(1)"
                          />
                          <span>{{ selectedCountry.country }}</span>
                        </div>
                      </div>
                      <div
                        v-if="mobileStep === 3"
                        class="flex-row align-center"
                      >
                        <span v-if="isRentNumber" class="mr-5"
                          >03. Choose days amount</span
                        >
                        <span v-else class="mr-5">03. Make a purchase</span>
                        <div
                          v-if="selectedCountry.id"
                          class="flex-row align-center"
                        >
                          <CountryFlagIcon
                            spanClass="country--flag mr-3"
                            defaultClass="mr-1 country--default--icon"
                            :countryId="getCountryAbbrFromId(1)"
                          />
                          <span>{{ selectedCountry.country }}</span>
                        </div>
                      </div>
                      <!-- <div v-if="mobileStep === 4" class="flex-row align-center">
                        <span class="mr-5">04. Make a purchase</span>
                        <div
                          v-if="selectedCountry.id"
                          class="flex-row align-center"
                        >
                          <CountryFlagIcon
                            spanClass="country--flag mr-3"
                            defaultClass="mr-1 country--default--icon"
                            :countryId="getCountryAbbrFromId(1)"
                          />
                          <span>{{ selectedCountry.country }}</span>
                        </div>
                      </div> -->
                    </div>
                    <span
                      :class="isAddingNumber ? 'disabled' : ''"
                      @click="() => closeWindow()"
                    >
                      <v-icon>mdi-window-close</v-icon>
                    </span>
                  </div>
                  <!-- 1st step -->
                  <div class="w-full px-2" v-if="mobileStep === 1">
                    <div v-if="countriesList && countriesList.length > 0">
                      <SearchInput
                        v-model="countrySearch"
                        :autoFocus="true"
                        :isDisabled="
                          areServicesLoading ||
                          (countriesList && countriesList.length <= 0)
                        "
                        :clearSearchInput="() => clearSearchInput('country')"
                        placeholder="Find country"
                      />
                      <div :class="`items--list--container`">
                        <ul
                          @scroll="
                            (e) =>
                              updateListScrollingReachedBottom({
                                e,
                                type: 'countries',
                              })
                          "
                          class="countries--list list--container"
                        >
                          <CountryItem
                            :isMobile="true"
                            v-for="item in filteredCountries"
                            :changeSelectedCountry="changeSelectedCountry"
                            :isSelected="
                              selectedCountry && selectedCountry.id === item.id
                            "
                            :key="item.id"
                            :item="item"
                            :isRentNumber="isRentNumber"
                          />
                        </ul>
                        <div v-show="!haveCountriesReachedBottom">
                          <div class="elevated__bottom" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 2nd step -->
                  <div class="w-full px-2" v-else-if="mobileStep === 2">
                    <SearchInput
                      v-model="serviceSearch"
                      :isDisabled="
                        areServicesLoading ||
                        (allServices && allServices.length <= 0)
                      "
                      :autoFocus="true"
                      :clearSearchInput="() => clearSearchInput('service')"
                      placeholder="Find a service"
                    />
                    <div class="services--inner">
                      <div
                        v-show="areServicesLoading"
                        class="services--loader--container bg-gray-50 dark:bg-fox-800"
                      >
                        <span class="buffering--loader"></span>
                      </div>
                      <div
                        v-if="filteredServices && filteredServices.length > 0"
                        class="items--list--container"
                      >
                        <ul
                          @scroll="
                            (e) =>
                              updateListScrollingReachedBottom({
                                e,
                                type: 'services',
                              })
                          "
                          :style="{
                            overflowY: areServicesLoading ? 'hidden' : 'auto',
                          }"
                          class="services--list list--container"
                        >
                          <ServiceItem
                            :isMobile="true"
                            v-for="item in filteredServices"
                            :changeSelectedService="changeSelectedService"
                            :isSelected="
                              selectedService && selectedService.id === item.id
                            "
                            :key="item.id"
                            :item="item"
                            :isRentNumber="isRentNumber"
                            :onGenerateNumberClick="onGenerateNumberClick"
                            :selectedCountry="selectedCountry"
                            :isLoading="isAddingNumber || isLoading"
                            :updateRecentlyGeneratedNumber="
                              updateRecentlyGeneratedNumber
                            "
                            :hasRecentlyGeneratedNumber="
                              hasRecentlyGeneratedNumber
                            "
                            :profitPercentage="profitPercentage"
                          />
                        </ul>
                        <div v-show="!haveServicesReachedBottom">
                          <div class="elevated__bottom" />
                        </div>
                      </div>
                      <div
                        v-else-if="!areServicesLoading"
                        class="border-gray-200 dark:border-gray-600 mt-5 alert"
                      >
                        <p class="p-0 m-0 text-center">
                          No services have been found.
                        </p>
                      </div>
                      <div
                        v-show="errorCaptured && !isAddingNumber"
                        class="error--container fadeEffect alert alert-danger text-center w-full mt-4"
                      >
                        <p class="text-danger flex-row align-center m-0">
                          <v-icon class="mr-2 alert mb-0 text-danger"
                            >mdi-alert</v-icon
                          >
                          <span>{{ errorCaptured }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- <div
                    class="w-full p-4"
                    style="overflow-y: auto"
                    v-else-if="isRentNumber && mobileStep === 3"
                  >
                    <RentPeriodSelectionCard
                      :isMobile="true"
                      @increaseStepsOnMobile="() => this.mobileStep++"
                      :errorCaptured="errorCaptured"
                      :isAddingNumber="isAddingNumber"
                      :selectedService="selectedService"
                      :selectedPeriodTime.sync="selectedPeriodTime"
                      :selectedPeriodCount.sync="selectedPeriodCount"
                      :totalAmount="totalRentAmount"
                      :daysCount="daysCount"
                    />
                  </div> -->
                  <!-- 3rd step -->
                  <!-- isRentNumber ? mobileStep === 4 : mobileStep === 3" -->
                  <div class="w-full p-4" v-else-if="mobileStep === 3">
                    <div class="make--purchase--outer">
                      <div class="make--purchase--container">
                        <div
                          class="make--purchase--container--inner pt-3 pb-8 flex-row flex-nowrap align-center justify-between"
                        >
                          <div class="flex-column gap-3">
                            <div
                              v-if="selectedCountry.id"
                              class="flex-row align-center"
                            >
                              <CountryFlagIcon
                                spanClass="country--flag mr-3"
                                defaultClass="mr-1 country--default--icon"
                                :countryId="getCountryAbbrFromId(1)"
                              />
                              <span>{{ selectedCountry.country }}</span>
                            </div>
                            <div
                              class="flex-row align-items-center w-100 gap-2 justify-content-between"
                            >
                              <span
                                v-if="selectedService.id"
                                class="flex-row align-center"
                              >
                                <ServiceIcon
                                  size="20"
                                  :service="selectedService.name"
                                />
                                <span class="ml-2">{{
                                  selectedService.name
                                }}</span>
                              </span>
                              <!-- <span v-if="isRentNumber">
                                • Rent for {{ selectedPeriodCount }} {{ " " }}
                                {{ selectedPeriodTime
                                }}{{
                                  typeof selectedPeriodCount === "number" &&
                                  selectedPeriodCount > 1
                                    ? "s"
                                    : ""
                                }}
                                ({{ daysCount }} days).
                              </span> -->
                            </div>
                          </div>
                          <strong
                            v-if="
                              selectedService &&
                              (typeof selectedService.price === 'number' ||
                                typeof selectedService.price === 'string')
                            "
                            >${{ selectedService.price }}</strong
                          >
                        </div>
                        <div
                          class="flex-row align-center mt-7 mb-6 justify-between"
                        >
                          <strong class="uppercase">TOTAL</strong>
                          <!-- <strong
                            v-if="
                              isRentNumber && typeof totalRentAmount === 'number'
                            "
                          >
                            ${{ Math.round(totalRentAmount * 100) / 100 }}
                          </strong> -->
                          <strong
                            v-if="
                              selectedService &&
                              (typeof selectedService.price === 'number' ||
                                typeof selectedService.price === 'string')
                            "
                            >${{ selectedService.price }}</strong
                          >
                        </div>
                        <div
                          v-show="errorCaptured && !isAddingNumber"
                          class="error--container fadeEffect alert alert-danger text-center w-full mt-4"
                        >
                          <p class="text-danger flex-row align-center m-0">
                            <v-icon class="mr-1 mb-0 alert text-danger"
                              >mdi-alert</v-icon
                            >
                            <span>{{ errorCaptured }}</span>
                          </p>
                        </div>
                      </div>

                      <button
                        @click="
                          onGenerateNumberClick({
                            confirmed: true,
                            selectedPeriodCount: selectedPeriodCount,
                            selectedPeriodTime: selectedPeriodTime,
                          })
                        "
                        :disabled="isSumbitButtonDisabled"
                        :class="
                          `submit--btn mt-11 mx-auto mb-5 ${
                            isSumbitButtonDisabled ? 'disabled' : ''
                          }`.trim()
                        "
                        type="submit"
                        v-if="
                          !isLoadingBalance &&
                          typeof userBalance === 'number' &&
                          userBalance > 0
                        "
                      >
                        {{
                          isAddingNumber
                            ? "In progress..."
                            : isRentNumber
                            ? "Rent number"
                            : "Get number"
                        }}
                      </button>
                      <div v-else-if="!isUserLoggedIn">
                        <h5 class="h5">
                          You must login first
                        </h5>
                        <button
                          @click="() => directToLogin()"
                          class="submit--btn mt-3 mb-5"
                        >
                          {{isRentNumber ? 'Rent number' : 'Get number'}}
                        </button>
                      </div>
                      <div v-else class="py-2">
                        <h5 class="h5">
                          You don't have enough credit to get a number
                        </h5>
                        <button
                          @click="() => allowAddingCredit()"
                          class="submit--btn mt-3 mb-5"
                        >
                          Add credit now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div :class="`${isHomepageDesign ? 'flex justify-center text-center w-full' : ''} hidden ms:flex `">
            <div v-if="isUserLoggedIn">
            <input
              @click="onGenerateNumberClick({})"
              :disabled="isSumbitButtonDisabled"
              :class="
                `submit--btn mt-7 mb-9 ${
                  isSumbitButtonDisabled ? 'disabled' : ''
                }`.trim()
              "
              type="submit"
              v-if="
                !isLoadingBalance &&
                typeof userBalance === 'number' &&
                userBalance > 0
              "
              :value="
                isAddingNumber
                  ? 'In progress...'
                  : isRentNumber && selectedCountry.id
                  ? 'Rent number'
                  : isRentNumber && !selectedCountry.id
                  ? 'Rent number ( random )'
                  : !isRentNumber && selectedCountry.id
                  ? 'Get number'
                  : 'Get number ( random )'
              "
            />
          </div>
        </div> -->

        <!-- Active numbers list -->
        <ActiveNumbersList
          v-if="isHomepageDesign ? (activeNumbers && activeNumbers.length > 0) : true"
          :isLoadingNumbersList="isLoadingNumbersList"
          :copyText="copyText"
          :isLoading="isLoading"
          :activeNumbersList="activeNumbers"
          :deleteNumberFromList="deleteNumberFromList"
          :isRentNumber="isRentNumber"
          @updateActiveNumbers="$emit('updateActiveNumbers')"
        />
      </div>
      <div class="desktop-only">
        <div :class="`${isHomepageDesign ? 'flex justify-center text-center' : ''}`">
          <div v-if="isLoadingBalance"/>
          <div v-else-if="!isUserLoggedIn">
            <button
              @click="() => directToLogin()"
              class="submit--btn mt-3 mb-5"
            >
              {{isRentNumber ? 'Rent number' : 'Get number'}}
            </button>
          </div>
          <div
            v-else-if="
              typeof userBalance === 'number' &&
              userBalance <= 0
            "
            class="py-2 mt-3"
          >
            <h5 class="h5 mt-2 mb-2">
              You don't have enough credit to get a number
            </h5>
            <button
              @click="() => allowAddingCredit()"
              class="submit--btn mt-3 mb-5"
            >
              Add credit now
            </button>
          </div>
          <div v-else :class="`${isHomepageDesign ? 'flex justify-center text-center w-full' : ''} hidden ms:flex `">
            <div >
            <input
              @click="onGenerateNumberClick({})"
              :disabled="isSumbitButtonDisabled"
              :class="
                `submit--btn mt-7 mb-9 ${
                  isSumbitButtonDisabled ? 'disabled' : ''
                }`.trim()
              "
              type="submit"
              v-if="
                !isLoadingBalance &&
                typeof userBalance === 'number' &&
                userBalance > 0
              "
              :value="
                isAddingNumber
                  ? 'In progress...'
                  : isRentNumber && selectedCountry.id
                  ? 'Rent number'
                  : isRentNumber && !selectedCountry.id
                  ? 'Rent number ( random )'
                  : !isRentNumber && selectedCountry.id
                  ? 'Get number'
                  : 'Get number ( random )'
              "
            />
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ServiceIcon from "../../Generic/ServiceIcon.vue";
import ActiveNumbersList from "./ActiveNumbersList.vue";
import CountryItem from "./CountryItem.vue";
import SearchInput from "./SearchInput.vue";
import ServiceItem from "./ServiceItem.vue";
import CountryFlagIcon from "@/components/Generic/CountryFlagIcon.vue";
import RentPeriodSelectionCard from "./RentPeriodSelectionCard.vue";
import { mapGetters } from "vuex";

// TODO: refactor the mobile view to only contain one logic
export default {
  data() {
    return {
      areServicesLoading: false,
      allServices: [],
      selectedCountry: {},
      selectedService: "",
      countrySearch: "",
      serviceSearch: "",
      filteredCountries: [],
      filteredServices: [],
      selectedPeriodTime: "00",
      selectedPeriodCount: "00",
      mobileStep: 1,
      textCopiedConfirmation: false,
      hasRecentlyGeneratedNumber: false,
      haveServicesReachedBottom: false,
      haveCountriesReachedBottom: false,
      isSpecifyingDateModalOpen: false,
      tabletScreenBreakpoint: 821,
      renderMobileView: false,
      mediaQuery: window.matchMedia
        ? window.matchMedia(`(max-width:821px)`)
        : null,
    };
  },
  methods: {
    ...mapActions("main", ["mutateKeys"]),
    copyText({ text }) {
      this.copyToClipboard({ text }).then(() => {
        this.textCopiedConfirmation = true;
      });
    },
    updateRecentlyGeneratedNumber(bool) {
      if (typeof bool !== "boolean") {
        return;
      }
    },
    resetSelectedCountries() {
      if (this.countriesList?.length > 0) {
        const firstItem = this.countriesList[0];
        this.changeSelectedCountry({ countryItem: firstItem });
      }
    },
    changeTawkVisibility(showIt) {
      const chatContainer = document.querySelector(".widget-visible");
      if (chatContainer) {
        chatContainer.style.setProperty(
          "display",
          showIt ? "block" : "none",
          "important"
        );
      }
    },
    updateMobileViewState(e) {
      if (e?.matches) {
        // in the desktop view
        this.renderMobileView = false;
      } else {
        // in the tablet view
        this.renderMobileView = true;
      }
    },
    isMediaQueryCompatible() {
      return (
        typeof window.matchMedia !== "undefined" &&
        typeof window.matchMedia("") !== "undefined"
      );
    },
    injectMediaQueryListener() {
      if (this.isMediaQueryCompatible()) {
        this.mediaQuery = window.matchMedia(
          `(min-width: ${this.tabletScreenBreakpoint}px)`
        );
        this.updateMobileViewState(this.mediaQuery);
        this.mediaQuery?.addEventListener("change", this.updateMobileViewState);
      } else {
        // media query is not compatible (on devices before 2011)
        console.error("media query is not compatible");
        this.renderMobileView = true;
      }
    },
    ejectMediaQueryListener() {
      if (!this.isMediaQueryCompatible()) {
        return;
      }
      this.mediaQuery?.removeEventListener(
        "change",
        this.updateMobileViewState
      );
    },
    onGenerateNumberClick({
      selectedCountry,
      selectedService,
      confirmed,
      selectedPeriodCount,
      selectedPeriodTime,
    }) {
      // let randomCountryIndex = Math.floor((Math.random() * (this.countriesList.length ? this.countriesList.length : 0)) + 1);
      // console.log('random' , randomCountryIndex)
      // console.log("countries num" , this.countriesList[randomCountryIndex]?.id)
      // if (this.isRentNumber && !confirmed) {
      //   this.isSpecifyingDateModalOpen = true;
      // } else {
      // console.log("this.selectedCountry" , this.selectedCountry)
      this.onAddingNumberFormSubmission({
        selectedCountry: selectedCountry || this.selectedCountry,
        selectedService: selectedService || this.selectedService,
        selectedPeriodCount,
        selectedPeriodTime,
      })
        .then(() => {
          this.isSpecifyingDateModalOpen = false;
          this.selectedPeriodCount = "00";
          this.selectedPeriodTime = "00";
          if (this.showBuyNumberContainerOnMobile) {
            this.closeWindow();
          }
        })
        .catch(() => {
          console.error("failed");
        });
      // }
    },
    directToLogin(){
      this.notify({
        type: "error",
        msg: "You need to login to your account"
      });
      this.$router.push("/login");
    },
    allowAddingCredit() {
      this.mutateKeys({ key: "isAddCreditModalOpenGlobally", val: true });
      this.$router.push("/dashboard/billing");
    },
    clearSearchInput(type) {
      if (type === "country") {
        this.countrySearch = "";
      } else if (type === "service") {
        this.serviceSearch = "";
      }
    },
    changeSelectedCountry({ countryItem, isMobile = false }) {
      this.selectedCountry = countryItem;
      const tabletWidth = 1263;
      
      if (isMobile) {
        this.mobileStep = 2;
      }else if(window.innerWidth <= tabletWidth){
        this.$refs.servicesBoxRef?.scrollIntoView({
          block: 'center',
          behavior: 'auto'
        })
      }
    },
    goBack() {
      if (this.mobileStep > 1 && this.mobileStep <= 3) {
        this.mobileStep = this.mobileStep - 1;
        if (this.errorCaptured) {
          this.changeErrorCaptured("");
        }
      }
    },
    changeSelectedService({ serviceItem, isMobile = false }) {
      this.selectedService = serviceItem;
      if (isMobile) {
        this.mobileStep = 3;
      }
    },
    closeWindow() {
      if (this.isAddingNumber) {
        return;
      }
      this.selectedPeriodCount = "00";
      this.selectedPeriodTime = "00";
      this.changeErrorCaptured("");
      this.closeMobileModal();
    },
    updateServices({ countryId }) {
      const firstItem = this.countriesList[1].id;

      const usedCountryId = countryId;
      if (!countryId) {
        return;
      }
      // gets services
      this.areServicesLoading = true;
      this.changeErrorCaptured("");
      this.makeRequest({
        owner: this.requestOwners.user,
        method: this.apiRequests.user.getServices.method,
        authRequired: this.isUserLoggedIn,
        url: `${
          this.apiRequests.user.getServices.url
        }?country=${countryId}&service_type=unitedsms&term=${
          this.isRentNumber ? "long" : "short"
        }`,
      })
        .then((res) => {
          this.areServicesLoading = false;
          if (res?.success) {
            this.changeErrorCaptured("");
            const services = res?.data;
            if (services?.length > 0) {
              this.allServices = services
                ?.sort((a, b) => b.slug - a.slug)
                ?.map((service) => {
                  return {
                    ...service,
                    id: service.slug,
                    name: service.service,
                  };
                });
              this.$nextTick(() => {
                this.filteredServices = this.allServices;
                const foundService =
                  this.allServices?.length > 0 && this.selectedService?.id
                    ? this.allServices.find(
                        (el) => el.id == this.selectedService.id
                      )
                    : null;
                if (foundService) {
                  this.selectedService = foundService;
                  document.querySelector("#servicesList")?.scrollTo({
                    top:
                      document.querySelector("#servicesList .active")
                        .offsetTop -
                      document
                        .querySelector("#servicesList")
                        .getBoundingClientRect().height /
                        2 +
                      30,
                    behavior: "smooth",
                  });
                } else {
                  this.selectedService = this.allServices[0];
                }
              });
            } else {
              this.allServices = [];
            }
            this.serviceSearch = "";
          } else {
            this.allServices = [];
            this.changeErrorCaptured(
              res?.errors || "Failed to get services list"
            );
          }
        })
        .catch((err) => {
          console.error("err", err);
          this.areServicesLoading = false;
          this.changeErrorCaptured("Failed to get services list");
        });
    },
    filterList({ type, val }) {
      if (this.lowerString(type) === "country") {
        if (val === "") {
          this.filteredCountries = this.countriesList;
        } else {
          this.filteredCountries =
            this.countriesList?.length > 0
              ? this.countriesList.filter((country) => {
                  return this.lowerString(country.country).includes(
                    this.lowerString(val)
                  );
                })
              : [];
        }
      } else if (this.lowerString(type) === "service") {
        if (val === "") {
          this.filteredServices = this.allServices;
        } else {
          this.filteredServices =
            this.allServices?.length > 0
              ? this.allServices.filter((service) => {
                  return this.lowerString(service.name).includes(
                    this.lowerString(val)
                  );
                })
              : [];
        }
      }
    },
    updateListScrollingReachedBottom({ e, type }) {
      const hasReachedBottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (type === "services") {
        this.haveServicesReachedBottom = hasReachedBottom;
      } else if (type === "countries") {
        this.haveCountriesReachedBottom = hasReachedBottom;
      }
    },
  },
  computed: {
    ...mapGetters("main", ["isUserLoggedIn"]),
    isSumbitButtonDisabled() {
      return (
        this.areServicesLoading ||
        this.isAddingNumber ||
        !this.selectedService?.id ||
        this.selectedService.id === "00"
      );
    },
    isServiceInputDisabled() {
      return (
        this.areServicesLoading ||
        !this.selectedCountry?.id ||
        this.selectedCountry.id === "00"
      );
    },
    // totalRentAmount() {
    //   const {
    //     selectedService,
    //     selectedPeriodCount,
    //     isRentNumber,
    //     selectedPeriodTime,
    //     profitPercentage,
    //   } = this;
    //   if (
    //     isRentNumber &&
    //     typeof selectedService?.price !== "string" &&
    //     typeof selectedService?.price !== "number" &&
    //     profitPercentage
    //   ) {
    //     return "";
    //   }
    //   const partialPrice =
    //     selectedPeriodTime === "week"
    //       ? +selectedPeriodCount * 7 * +selectedService.price
    //       : selectedPeriodTime === "month"
    //       ? +selectedPeriodCount * 30 * +selectedService.price
    //       : "";

    //   return typeof partialPrice === "number"
    //     ? (partialPrice * (+profitPercentage + 100)) / 100
    //     : "";
    // },
    daysCount() {
      const { selectedPeriodTime, selectedPeriodCount } = this;
      return selectedPeriodTime === "week"
        ? +selectedPeriodCount * 7
        : selectedPeriodTime === "month"
        ? +selectedPeriodCount * 30
        : "";
    },
  },
  props: {
    profitPercentage: {
      required: false,
      default: null,
    },
    isRentNumber: {
      type: Boolean,
      required: false,
      default: false,
    },
    isHomepageDesign: {
      type: Boolean,
      required: false,
      default: false,
    },
    onAddingNumberFormSubmission: {
      type: Function,
      required: true,
    },
    countriesList: {
      type: Array,
      required: true,
    },
    isAddingNumber: {
      type: Boolean,
      required: false,
    },
    errorCaptured: {
      type: String,
      required: true,
      default: false,
    },
    changeErrorCaptured: {
      type: Function,
      required: true,
    },
    userBalance: {
      type: Number,
      required: true,
    },
    showBuyNumberContainerOnMobile: {
      type: Boolean,
      required: true,
    },
    changeShowingContainerOnMobileState: {
      type: Function,
      required: true,
    },
    closeMobileModal: {
      type: Function,
      required: true,
    },
    activeNumbers: {
      type: Array,
      required: true,
    },
    isLoadingBalance: {
      type: Boolean,
      required: true,
    },
    isLoadingNumbersList: {
      type: Boolean,
      required: true,
      default: false,
    },
    isLoadingCountries:{
      type: Boolean,
      required: true,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    deleteNumberFromList: {
      type: Function,
      required: true,
    },
  },
  watch: {
    isSpecifyingDateModalOpen(val) {
      if (typeof val === "boolean" && !val) {
        this.changeErrorCaptured("");
        this.selectedPeriodCount = "00";
        this.selectedPeriodTime = "00";
      }
    },
    mobileStep(val) {
      if (val == 2) {
        this.selectedPeriodCount = "00";
        this.selectedPeriodTime = "00";
      }
    },
    selectedCountry: {
      deep: true,
      immediate: false,
      handler(val) {
        if (val && val.id && !this.areServicesLoading) {
          this.updateServices({ countryId: val.id });
        }
      },
    },
    selectedPeriodTime() {
      if (this.selectedPeriodCount == "00") {
        this.selectedPeriodCount = 1;
      }
    },
    showBuyNumberContainerOnMobile(val) {
      if (typeof val === "boolean") {
        if (val) {
          this.mobileStep = 1;
          this.changeTawkVisibility(false);
        } else {
          this.changeTawkVisibility(true);
        }
      }
    },
    countriesList: {
      immediate: true,
      handler(val) {
        if (val) {
          this.filterList({ type: "country", val: "" });
          this.resetSelectedCountries();

          if (this.countriesList?.length > 0) {
            const firstItem = this.countriesList[1].id;

            this.updateServices({ countryId: firstItem });
          }
        }
      },
    },
    countrySearch: {
      immediate: true,
      handler(val) {
        this.filterList({ type: "country", val });
      },
    },
    serviceSearch: {
      immediate: true,
      handler(val) {
        this.filterList({ type: "service", val });
      },
    },
  },
  components: {
    SearchInput,
    CountryItem,
    ServiceItem,
    ServiceIcon,
    ActiveNumbersList,
    CountryFlagIcon,
    RentPeriodSelectionCard,
  },
  mounted() {
    this.resetSelectedCountries();
    this.injectMediaQueryListener();
  },
  beforeDestroy() {
    this.ejectMediaQueryListener();
  },
};
</script>

<style lang="scss" scoped>
.go--back--btn {
  cursor: pointer;
  padding: 0.1rem;
  border-radius: 3px;
  margin-right: 0.5rem;
  &:active {
    background: rgba(0, 0, 0, 0.1);
  }
  i {
    color: var(--primary-clr);
  }
}
.add--number--container {
  user-select: none;
  // background: var(--ultra-white);
  border: 1px solid var(--borders-gray);
  border-radius: 6px;
  box-shadow: 0 6px 16px rgb(0, 0, 0, 0.08);
  padding: 0.6rem 0.2rem;

  .list--container {
    padding: 0.5rem 6px 0 0;
    height: 400px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      box-shadow: none;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: var(--borders-gray);
      border: 2.5px solid #fff;
    }
  }
}

.add--number--container.desktop--version {
  font-size: 15px;
}
.add--number--modal {
  .add--number--container {
    border: none;
  }
  .add--number--modal--header {
    padding: 10px 16px;
    min-height: 53px;
  }
  ul {
    padding-left: 0;
    padding-right: 0;
  }
  .add--number--modal--inner,
  .add--number--modal--backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 400;
  }
  .add--number--modal--backdrop {
    top: 0;
    background: rgba(0, 0, 0, 0.4);
  }
  .add--number--modal--inner {
    top: 2rem;
    z-index: 800;
    .make--purchase--container {
      height: calc(100% - 110px);
    }
    .make--purchase--container--inner {
      border-bottom: 1px solid var(--borders-gray);
    }
    .submit--btn {
      width: 100%;
      border-radius: 0;
      max-width: none;
    }
  }
  .make--purchase--outer {
    height: calc(100% - 106px);
    order: 1;
    flex: 10 1 auto;
    overflow-y: auto;
  }
  .list--container {
    height: calc(100vh - 6rem - 45px);
  }
}
@media only screen and (min-width: 1200px) {
  .add--number--container {
    .countries--list {
      border-right: 1px solid var(--borders-gray);
    }
  }
}
.loading--div {
  height: 400px;
  border: var(--borders-gray);
  border-radius: 6px;
  display: grid;
  place-content: center;
  @media screen and (max-width: 821px){
    display: none;
  }
}
.services--inner {
  position: relative;
  min-height: 400px;
  .services--loader--container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: grid;
    z-index: 3;
    user-select: none;
    pointer-events: none;
    place-content: center;
  }
}
// fetching loader
.buffering--loader {
  width: 45px;
  height: 45px;
  display: inline-block;
  position: relative;
}
.buffering--loader::after,
.buffering--loader::before {
  content: "";
  box-sizing: border-box;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: var(--primary-clr);
  position: absolute;
  left: 0;
  top: 0;
  animation: animBufferingLoader 2s linear infinite;
}
.buffering--loader::after {
  animation-delay: 0.2s;
}

// homepage design
#homeDesign{
  // .services--inner {
  //   .services--loader--container {
  //     background: #cbeeff1a;
  //   }
  // }
  .add--number--container {
    border: none;
    border-radius: 5px;
    box-shadow: none;
    .list--container {
      &::-webkit-scrollbar-thumb {
        border-radius: 9px;
        background: #7388926E;
        border: none;
      }
    }
  }
}
body.dark{
  #homeDesign{
    .add--number--container {
      .list--container {
        &::-webkit-scrollbar-thumb {
          background: #FFFFFF;
        }
      }
    }
  }
}


@keyframes animBufferingLoader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
</style>
