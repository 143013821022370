<template>
  <div class=" relative">
    <div class="app-container pb-[50px] md:pb-[57px] lg:pb-[64px] pt-[40px] sm:pt-[80px] md:pt-[150px]">
      <h3
        class="text-center font-[700] text-fontColor-1 uppercase dark:text-light-1 text-[18.5px] xs:text-[22px] md:text-[26px] lg:text-[28px]"
      >
      CUSTOMER <span class="text-first"> FEEDBACK</span>
      </h3>

      <div class="flex justify-center" v-if="testimonials?.length">
        <div class="sliders w-full sm:w-[80%] lg:w-[789px] md:pt-7">
            <!-- Slider main container -->
  <div ref="swiper" class="swiper">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">

      <div
            v-for="(item , index) in testimonials" :key="index"
            class="swiper-slide"
            >
            <div class="bg-[#1E282D] relative rounded-[20px] md:rounded-[35px] lg:rounded-b-[130px] lg:rounded-t-[50px] pt-[40px] md:pt-[80px] mt-[100px]">

              <div class="bg-first w-[116px] h-[116px] sm:w-[126px] sm:h-[126px] md:w-[156px] md:h-[156px] lg:w-[163px] lg:h-[163px] rounded-full flex justify-center items-center absolute top-[-80px] left-[50%] translate-x-[-50%]">
                <img v-if="item?.image" class="w-[90%] md:w-[88%] rounded-full object-cover " :src="item?.image" alt="">
                <img  v-else class="w-[90%] md:w-[88%] rounded-full object-cover " src="../../assets/imgs/home/person.jpeg" alt="" />
              </div>
              <div>
                  <h3 class="text-white font-bold text-[22px] md:text-[25px] pt-3.5 text-center lg:text-[27px] uppercase">{{ item?.name }}</h3>
                  <h5 class="text-first text-center pt-0.5 text-[15.5px] md:text-[16px] lg:text-[17px] uppercase">{{ item?.job }} </h5>
                </div>
              <div
                class="h-[155px] text-center text-[16px] pb-1 pt-2.5 mx-auto w-[90%] md:text-[19px] md:text-start md:w-[80%]"
              >
                <div>
                  <p class="text-fontColor-7 ellipsis-x5 font-normal text-[15px] md:text-[17px]">
                {{ item?.message }}
                  </p>
                </div>
              </div>
            </div>
            </div>
    </div>
    <!-- If we need pagination -->
    <div class="swiper-pagination"></div>

    <!-- If we need scrollbar -->
    <div class="swiper-scrollbar"></div>
  </div>
        </div>
      </div>
    </div>

    <div class=" min-h-[180px] absolute bottom-0 bg-first md:bg-transparent">
      <img src="../../assets/imgs/home/background.svg" alt="" />
    </div>
  </div>
</template>
  
<script>
// imports
import img from "../../assets/images/placeholder.jpg";
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'



export default {
  data(){
    return {
      reload:true,
    }
  },
  props: {
    testimonials: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    handleImgError(e) {
      e.target.src = img;
    }
  },
  mounted(){
    new Swiper(this.$refs.swiper, {
      // configure Swiper to use modules
      modules: [Navigation, Pagination],
      // Optional parameters
      loop: true,
      // If we need pagination
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
      },
      slidesPerView:1,
      speed:500,
      grabCursor: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      // And if we need scrollbar
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    })
  },
}

</script>



<style lang="scss">
.swiper.swiper-initialized.swiper-horizontal.swiper-rtl.swiper-backface-hidden {
  width: 100%;
}

.sliders {
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 0px;
}
.swiper-pagination{
  position: relative;
}
.sliders
  .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  width: 100%;
  height: 15px;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  row-gap: 5px;
  align-items: flex-end;

  @media only screen and (max-width: 573px){
    margin-bottom: 1rem;
    margin-bottom: 0.8rem;
  }
}

@media (max-width: 300px) {
  .sliders
    .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    align-items: center;
  }
}

.sliders
  .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal
  span {
  width: 27px;
  height: 8px;
  background: #C26821 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  cursor: pointer;
}

.sliders
  .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal
  span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #fff 0% 0% no-repeat padding-box;
}

@media (max-width: 670px) {
  .sliders
    .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal
    span {
    width: 27px;
    height: 7px;
  }
}
</style>