<template>
     <!-- <button type="button" class="absolute top-3 left-2">x</button> -->
    <!-- <div>
        <div class="sticky top-4 z-[18] mx-auto w-[95%] sm:w-[70%] md:w-[65%]" v-if="isShown && imageUrl">       
            <a v-if="path" :href="path" target="_blank"  class="w-full h-full">
               
                <img @error="onImageError" :src="imageUrl" alt
                    class="w-full h-auto max-h-[100px] border border-gray-50 dark:border-gray-900 object-contain" width="100%" height="100" />
            </a>
            <span v-else>
                <img :src="imageUrl" alt
                    class="w-full h-auto max-h-[100px] border border-gray-50 dark:border-gray-900 object-contain" width="100%" height="100" />
            </span>
        </div>
    </div> -->
 
    <div style="display: flex;  justify-content: center;">

               <a href="https://buyfromfox.com?ref=1">
            <img src="../../assets/images/Banner.gif" alt="Your GIF" class="max-w-full h-auto rounded shadow-lg" style="width: 700px;">
        </a>   
 </div>
 

    
</template>

<script>
export default {
    data(){
        return {
            isShown:  true
        }
    },
    props: {
        imageUrl: {
            type: String,
            required: true,
            default: ""
        },
        path: {
            type: String,
            required: true,
            default: "#"
        },
    },
    methods: {
        onImageError(e){
            this.isShown = false;
            e.onerror = null;
            return true;
        }
    }
}
</script>

<style></style>