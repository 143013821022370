<template>
    <div class="message-item mb-3 flex items-start space-x-4">
          <div class="hidden md:block w-14">
            <!-- <span class="mt-2 block w-full p-2.5 border-2 border-white bg-[#1778F2] rounded-full">
              <svg class="w-8 h-8 fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path
                  d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"
                ></path>
              </svg>
            </span> -->
            <ServiceIconVue :service="service" />
          </div>

          <div class="w-full md:space-y-3 p-3 bg-gray-100 dark:bg-fox-900 rounded">
            <p class="mb-0 flex items-center justify-between text-orange-600 font-bold">
              <span class="uppercase">{{ service }}</span>
              <!-- <span>
                <svg class="w-6 h-6 fill-orange-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="m2.394 13.742 4.743 3.62 7.616-8.704-1.506-1.316-6.384 7.296-3.257-2.486zm19.359-5.084-1.506-1.316-6.369 7.279-.753-.602-1.25 1.562 2.247 1.798z"></path>
                </svg>
              </span> -->
            </p>

            <p v-if=" msg.msg" class="mb-0 text-xl dark:text-gray-100">
              {{ msg.msg }}</p>
            <span class="text-muted" v-else>No Message</span>
            <p class="mb-0 flex-row align-center justify-end">
              <span v-if="msg.read_at" class="mr-2"><strong class="mr-1" >Read at</strong> {{ msg.read_at }}</span>
               <v-icon :style="{color: msg.read_at ? 'var(--info)': 'var(--txt-muted)', fontSize: '20px'}">mdi-check-all</v-icon>
            </p>
            <!-- <p class="mb-0 flex-row justify-between"> -->
              <!-- <span v-if="msg.read_at">Read: {{ msg.read_at }}</span> -->
              <button @click="deleteMessage" class="text-rose-700 hover:text-rose-800">
                <svg class="w-5 h-5 fill-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M6 7H5v13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7H6zm4 12H8v-9h2v9zm6 0h-2v-9h2v9zm.618-15L15 2H9L7.382 4H3v2h18V4z"></path>
                </svg>
              </button>
            <!-- </p> -->
          </div>
        </div>
</template>

<script>
import ServiceIconVue from '@/components/Generic/ServiceIcon.vue';

export default {
    components: { ServiceIconVue },
    props: {
        msg: {
            type: Object,
            required: true,
        },
        service: {
            type: String,
            required: true,
        }
    },
    methods: {
        async deleteMessage() {
            try {
                const res = await this.makeRequest({
                    owner: this.requestOwners.user,
                    method: this.apiRequests.user.deleteMessage.method,
                    authRequired: true,
                    url: this.apiRequests.user.deleteMessage.url,
                    formData: {
                        message_id: this.msg.id
                    }
                });

                if (res?.success) {
                    this.$emit('message-deleted', this.msg.id);
                    this.notify({
                        type: "success",
                        msg: "Message deleted successfully."
                    });
                } else {
                    throw new Error("Failed to delete message");
                }
            } catch (error) {
                console.error('Error deleting message:', error);
                this.notify({
                    type: "error",
                    msg: "Failed to delete message."
                });
            }
        }
    }
}
</script>

<style>

</style>
