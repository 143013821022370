<template>
  <div>
    <div class="w-full h-full">
      <div class="flex flex-no-wrap">
        <!-- Start of SIDEBAR -->
        <div id="sidebar-menu" class="z-40 sidebar-opened md:block fixed -left-[300px] md:sticky h-screen grow overflow-y-auto">
          <Sidebar @closeSidebar="() => toggleSidebar('mobile')" />
        </div>
        <!-- End of SIDEBAR -->

        <!-- Start of DASHBOARD -->
        <div class="w-full shrink h-screen overflow-y-auto overflow-x-hidden relative dark:bg-fox-900">
          <div @click="toggleSidebar('mobile')" id="page-over" class="hidden md:hidden z-30 absolute w-full h-full bg-[#000000CC]"></div>
          <header class="w-full bg-orange-500">
            <div class="px-4 py-3.5 flex items-center justify-end space-x-4">
              <!-- Sidebar Toggle Button (Desktop) -->
              <div @click="toggleSidebar" id="sidebar-toggle-btn" class="hidden md:block -ml-4 p-2 bg-fox-800 rounded-r fixed left-[315px] top-3.5 z-90 cursor-pointer">
                <span>
                  <svg class="w-7 h-7 fill-gray-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M4 11h12v2H4zm0-5h16v2H4zm0 12h7.235v-2H4z"></path>
                  </svg>
                </span>
              </div>

              <!-- Sidebar Toggle Button (Mobile) -->
              <div @click="toggleSidebar('mobile')" id="sidebar-toggle-btn-mobile" class="z-5 md:hidden p-2 top-3.5 left-4 bg-fox-800 rounded cursor-pointer absolute">
                <span>
                  <svg class="w-7 h-7 fill-gray-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M4 11h12v2H4zm0-5h16v2H4zm0 12h7.235v-2H4z"></path>
                  </svg>
                </span>
              </div>

              <!-- DarkMode Toggle Button -->
              <div>
                <button @click="() => toggleTheme()" class="theme--toggler p-1.5 border border-dashed border-white rounded-full">
                  <v-icon v-if="getLocalStorage && getLocalStorage.isDarkmode" class="light__mode__icon">mdi-white-balance-sunny</v-icon>
                  <v-icon v-else class="dark__mode__icon">mdi-moon-waning-crescent</v-icon>
                </button>
              </div>

              <!-- User Area -->
              <div class="user--info">
                <div @click="() => toggleDropdown()"  class="user-area px-2.5 py-2 inline-flex items-center space-x-3 bg-[#00000025] rounded">
                  <span class="w-7 h-7 inline-flex justify-center items-center bg-gray-50 rounded">
                    <svg class="w-4 h-4 fill-gray-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"></path>
                    </svg>
                  </span>
                  <span v-if="getLocalStorage && getLocalStorage.user && getLocalStorage.user.name" class="text-gray-100 uppercase font-bold">{{ getLocalStorage.user.name }}</span>
                </div>   

                  <div v-show="isDropdownOpen" id="dropdown" class=" z-10 w-44 bg-[#ffff] rounded divide-y divide-gray-100 shadow dark:bg-gray-700">
                      <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="multiLevelDropdownButton">
                        <li @click="() => openProfileDialog()">
                          <a href="#" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">My profile</a>
                        </li>
                      </ul>
                  </div>
              </div>

            </div>
          </header>
          <router-view></router-view>
        </div>
        <div class="dropdown--backdrop" @click="() => isDropdownOpen = false" v-show="isDropdownOpen"></div>
        <!-- End of DASHBOARD -->
      </div>
    </div>
      <!-- profile modal -->
      <v-dialog v-model="isProfileModalOpen" class="add--number--modal" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5 mt-2">Profile info</span>
            </v-card-title>
            
            <ProfileModalBody />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="() => isProfileModalOpen = false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
  </div>
</template>

<script>
import Sidebar from '../../components/Main/Dashboard/Sidebar.vue';
import ProfileModalBody from "../../components/Main/ProfileModalBody";
import Banner from "../../components/Generic/Banner.vue";
import { mapGetters } from 'vuex';

export default {
  data(){
    return {
      isDropdownOpen: false,
      isProfileModalOpen: false
    }
  },
  computed: {
    ...mapGetters('main', ['getLocalStorage']),
  },
  name: 'UserDashboardLayout',
  components: {
      Sidebar,
      ProfileModalBody,
      Banner
  },
  methods: {
    openProfileDialog(){
      this.isProfileModalOpen = true;
      this.isDropdownOpen = false;
    },
    toggleDropdown(){
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    closeSidebarMobile() {
      const sibebarEl = document.getElementById('sidebar-menu');

      if (sibebarEl.classList.contains('left-0')) {
        sibebarEl.classList.remove('left-0');
        sibebarEl.classList.add('-left-[300px]');
      }
    },
    toggleSidebar(device) {
      const sibebarEl = document.getElementById('sidebar-menu');

      if (device == 'mobile') {
        if (sibebarEl.classList.contains('-left-[300px]')) {
          sibebarEl.classList.remove('-left-[300px]');
          sibebarEl.classList.add('left-0');
          document.getElementById('page-over').classList.remove('hidden');
        } else if (sibebarEl.classList.contains('left-0')) {
          sibebarEl.classList.remove('left-0');
          sibebarEl.classList.add('-left-[300px]');
          document.getElementById('page-over').classList.add('hidden');
        }
      } else {
        if (sibebarEl.classList.contains('sidebar-opened')) {
          sibebarEl.classList.remove('sidebar-opened');
          sibebarEl.classList.add('sidebar-close');
          document.getElementById('sidebar-toggle-btn').classList.remove('left-[315px]');
          document.getElementById('sidebar-toggle-btn').classList.add('left-[80px]');
        } else if (sibebarEl.classList.contains('sidebar-close')) {
          sibebarEl.classList.remove('sidebar-close');
          sibebarEl.classList.add('sidebar-opened');
          document.getElementById('sidebar-toggle-btn').classList.remove('left-[80px]');
          document.getElementById('sidebar-toggle-btn').classList.add('left-[315px]');
        }
      }
    },
  },
};
</script>

<style lang="scss">
#sidebar-menu {
  transition: all 0.25s ease-out;

  .sidebar-inner {
    transition: padding 0.25s ease-out;
  }
}

#sidebar-toggle-btn {
  transition: left 0.25s ease-out;
  z-index: 33;
}

.sidebar-opened {
  width: 300px !important;

  .hide-if-open {
    display: none;
  }
  .sidebar-inner {
    padding: 0 1rem;
  }
}

.sidebar-close {
  width: 65px !important;

  .hide-if-close {
    display: none;
  }

  .sidebar-inner {
    padding: 0 0.5rem;
  }
}
.dropdown--backdrop{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left:0;
  right:0;
  bottom:0;
  background: transparent;
  z-index: 90;
}
.user--info{
  .user-area{
    cursor: pointer;
  }
  position: relative;
  #dropdown{
    z-index: 100;
    position:absolute;
    right:0;
    top: 48px;
    ul{
      padding:0;
      margin:0;
      li{
        cursor: pointer;
      }
    }
  }
}
</style>
