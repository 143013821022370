<template>
  <div class="bg-fontColor-1 dark:!bg-[#1E282D] py-2.5">
    <div class="main-header-container flex justify-between items-center w-full">
      <!-- left -->
      <div class="w-[30%] md:w-[20%]">
        <router-link to="/">
          <img class="object-contain w-[85px] h-[45px] md:w-[91px] md:h-[51px]" width="94" height="52" src="../../assets/imgs/logo.svg" alt />
        </router-link>
      </div>
      <!-- middle -->
      <ul
        class="hidden w-[40%] md:w-[60%] mx-auto md:flex justify-center items-center p-0 gap-2 lg:gap-[24px] text-[17px] lg:text-[18px] xl:text-[18.5px]"
      >
        <li>
          <RouterLink :class="`p-2 ${currentPath === '/' ? '!text-first' : '!text-[#75868E]' }`" to="/">Home</RouterLink>
        </li>
        <li @click="() => scrollIntoViewFun('feature')">
          <RouterLink class="p-2 !text-[#75868E]" to>Features</RouterLink>
        </li>
        <li @click="() => scrollIntoViewFun('how-use')">
          <RouterLink class="p-2 !text-[#75868E] " to >How to use?</RouterLink>
        </li>
        <li>
          <RouterLink :class="`p-2 ${currentPath === '/contact_us' ? '!text-first' : '!text-[#75868E]' }`" to="/contact_us">Contact us</RouterLink>
        </li>
      </ul>
      <!-- right -->
      <div class="mw-[30%] md:w-[20%] justify-self-end">
        <div class="hidden md:flex gap-3 justify-end items-center">
          <button              
              aria-label="Change theme"
              aria-labelledby="Change theme"
              class="bg-[#35434A] select-none flex justify-center items-center rounded-sm w-[31px] h-[31px]" @click="toggleTheme">
            <img
              v-if="getLocalStorage && getLocalStorage.isDarkmode"
              class="dark:block object-contain w-[17px] h-[17px]"
              src="../../assets/imgs/lightmode.svg"
              :draggable="false"
              alt
            />
            <img v-else class="block dark object-contain w-[17px] h-[17px]" :draggable="false" src="../../assets/imgs/darkmode.svg" alt />
            <span class="sr-only">Change theme</span>
          </button>
          <div v-if="!isLoggedIn" class="flex flex-wrap gap-y-1">
            <button class="mr-3">
              <RouterLink
                class="h-full w-full text-white flex font-normal justify-center items-center md:text-[17px]"
                to="/login"
              >LOGIN</RouterLink>
            </button>
            <button class="bg-gradient-to-t whitespace-nowrap from-second to-third p-0 rounded-[3px]">
              <RouterLink
                class="h-full w-full flex text-white py-1 px-2 justify-center items-center md:text-[15px]"
                to="/signup"
              >Create account</RouterLink>
            </button>
          </div>
          <div v-else class="relative" id="tooltip-toggler">
            <button @click="openToolTip" aria-label="My profile" aria-labelledby="My profile" class="bg-[#35434A] p-1 rounded-sm w-[31px] h-[31px]">
              <img class="object-contain" src="../../assets/imgs/user.svg" alt />
              <span class="sr-only">My profile</span>
            </button>
            <div
              v-if="toolTip"
              class="absolute w-[180px] max-w-none flex flex-col mt-1 justify-center items-center p-0 overflow-hidden shadow-md rounded-md right-0 bg-[#475257] z-30 text-fontColor-1"
            >
              <button class="hover:!font-bold font-[500] hover:bg-first/80 p-0 w-full">
                <RouterLink
                  to="/dashboard"
                  class="!text-white !font-[inherit] flex items-center justify-between px-4 py-2 gap-2 w-full dark:!text-[#fff] text-base"
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M13 9V3h8v6h-8ZM3 13V3h8v10H3Zm10 8V11h8v10h-8ZM3 21v-6h8v6H3Zm2-10h4V5H5v6Zm10 8h4v-6h-4v6Zm0-12h4V5h-4v2ZM5 19h4v-2H5v2Zm4-8Zm6-4Zm0 6Zm-6 4Z"/></svg>
                <span class="!font-[inherit]">My dashboard</span>
                </RouterLink>
              </button>     
              <div class="h-[1px] w-full bg-light-3"></div>       
              <button
                :disabled="isLoading"
                @click="logout"
                :class="`${isLoading ? 'disabled' : ''} px-4 py-2 hover:!font-bold hover:bg-first/80 hover:text-first flex items-center gap-2 w-full justify-between text-white dark:!text-[#fff] font-[500] text-base`"
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1"/></svg>
              <span>Logout</span>
              </button>
            </div>
          </div>
        </div>
        <div class="flex gap-3 md:hidden select-none">
          <button           
              aria-label="Change theme"
              aria-labelledby="Change theme"
              class="bg-[#35434A] w-[31px] h-[31px] flex justify-center items-center rounded-sm" @click="toggleTheme">
            <img
              v-if="getLocalStorage && getLocalStorage.isDarkmode"
              class="dark:block object-contain w-[17px] h-[17px]"
              :draggable="false"
              src="../../assets/imgs/lightmode.svg"
              alt
            />
            <img v-else class="block object-contain w-[17px] h-[17px]" :draggable="false" src="../../assets/imgs/darkmode.svg" alt />
            <span class="sr-only">Change theme</span>
          </button>
          <button class="bg-[#35434A] w-[31px] h-[31px] flex justify-center items-center rounded-sm" @click="togglePopup">
            <img class="h-[22px]" src="../../assets/imgs/burger-menu-white.svg" alt :draggable="false" />
          </button>
        </div>
      </div>
    </div>
    <!-- start header popup section  -->
    <div
      ref="overlay"
      class="overlay hidden fixed md:hidden h-[100vh] w-[100vw] z-10"
      @click="closePopup"
    ></div>
    <transition name="mobile-menu">
      <div
        v-if="popup"
        class="absolute md:hidden mt-[7px] w-full z-20 bg-[#E3E3E3] dark:bg-[#35434A]"
      >
        <div v-if="!isLoggedIn" class="pl-[28px] flex gap-4 pt-6">
          <button
            @click="closePopup"
            class="bg-gradient-to-t from-fontColor-1 to-fontColor-3 rounded-[3px] p-0"
          >
            <RouterLink
              class="h-full w-full text-white flex justify-center items-center py-1 px-2"
              to="/login"
            >LOGIN</RouterLink>
          </button>
          <button
            @click="closePopup"
            class="bg-gradient-to-t from-second to-third p-0 rounded-[3px]"
          >
            <RouterLink
              class="h-full w-full flex justify-center text-white items-center py-1 px-2"
              to="/signup"
            >Create account</RouterLink>
          </button>
        </div>
        <div v-else class="pl-[28px] flex gap-4 pt-6">
          <div class="flex flex-col gap-2">
            <button
              :disabled="isLoading"
              @click="() => {closePopup(); ($router.push('/dashboard')) }"
              :class="`${isLoading ? 'disabled' : ''} bg-gradient-to-t from-fontColor-1 to-fontColor-3 rounded-[3px] p-0`"
            >
              <span class="h-full w-full text-white flex justify-center items-center py-1 px-2">My dashboard</span>
            </button>   
                    
            <button
              :disabled="isLoading"
              @click="() => {closePopup(); logout() }"
              :class="`${isLoading ? 'disabled' : ''} bg-gradient-to-t from-fontColor-1 to-fontColor-3 rounded-[3px] p-0`"
            >
              <span class="h-full w-full text-white flex justify-center items-center py-1 px-2">Logout</span>
            </button>
          </div>
        </div>
        <ul class="popup-nav m-auto h-fit rounded-sm text-[14px] xs:text-[18px] pt-2 pb-4 px-4 ">
          <li class="cursor-pointer -responsive" @click="closePopup">
            <RouterLink
              :class="`${currentPath === '/' ? '!text-first' : '!text-[#75868E]' } !text-fontColor-1 dark:!text-[#8a9ea7] block h-full w-full p-3 rounded-[8px]`"
              to="/"
            >Home</RouterLink>
          </li>
          <li class="cursor-pointer" @click="() => scrollIntoViewFun('feature')">
            <RouterLink
              :class="` !text-fontColor-1 dark:!text-[#8a9ea7] block h-full w-full p-3 rounded-[8px]`"
              to
            >Features</RouterLink>
          </li>
          <li class="cursor-pointer" @click="() => scrollIntoViewFun('how-use')">
            <RouterLink
              :class="`!text-fontColor-1 dark:!text-[#8a9ea7] block h-full w-full p-3 rounded-[8px]`"
              to
            >How to use?</RouterLink>
          </li>
          <li @click="closePopup">
            <RouterLink
              :class="`${currentPath === '/contact_us' ? '!text-first' : '!text-[#75868E]' } !text-fontColor-1 dark:!text-[#8a9ea7] block h-full w-full p-3 rounded-[8px]`"
              to="/contact_us"
            >Contact us</RouterLink>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import LogoutModal from "../../components/Main/LogoutModal.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Header",
  components: { LogoutModal },
  mounted() {
    document.addEventListener("click", this.closeToolTip);
  },
  computed: {
    ...mapGetters("website", { isLoggedIn: "getIsAuth" }),
    currentPath(){
      return this.$route.path;
    }
  },
  data() {
    return {
      isLoading: false,
      logoutModal: false,
      popup: false,
      toolTip: false
    };
  },

  methods: {
    ...mapActions("main", ["updateMemory"]),
    onLinkClick() {
      this.$emit("closeSidebar");
    },
    closeModal() {
      this.logoutModal = false;
    },
    closeMenue(e) {
      let menuBar = document.getElementById("header-menu-popup");
      if (
        !e.target.closest(".header-nav") &&
        !e.target.closest(".toggle-menu") &&
        window.innerWidth < 992
      ) {
        menuBar.style.display = "none";
      }
      if (window.innerWidth > 992 && menuBar) {
        menuBar.style.display = "none";
      }
    },
    changeLoadingState(state) {
      this.isLoading = state;
    },
    closePopup() {
      this.popup = false;
      this.$refs.overlay.classList.add("hidden");
    },
    scrollIntoViewFun(sec) {
      const ele = document.getElementById(sec);
      if (this.$route.path == "/") {
        if (ele && ele.scrollIntoView) {
          ele.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start"
          });
        }
      } else {
        this.$router.push("/");
        setTimeout(() => {
          const element = document.getElementById(sec);

          if (element && element.scrollIntoView) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "start"
            });
          }
        }, 100);
      }
      this.closePopup();
    },
    openToolTip() {
      this.toolTip = true;
    },
    closeToolTip(e) {
      if (!e.target.closest("#tooltip-toggler")) {
        this.toolTip = false;
      }
    },
    togglePopup() {
      if (!this.popup) {
        this.popup = true;
        this.$refs.overlay.classList.remove("hidden");
      } else {
        this.popup = false;
        this.$refs.overlay.classList.add("hidden");
      }
    },
    onLogoutConfirmation() {
      this.toolTip = false;
      this.changeLoadingState(true);
      const fromLocalStorage = localStorage.getItem("fox_sims");
      const token = JSON.parse(fromLocalStorage)?.user?.token;
      axios
        .post(this.apiRequests.user.logout.url, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "Application/json",
            Authorization: `Bearer ${token} `
          }
        })
        .then(res => {
          this.changeLoadingState(false);

          if (res.status == 200) {
            // delete user obj from localStorage
            this.updateMemory({
              type: "update",
              newObject: {},
              destination: this.requestOwners.user
            });

            // set a global user role state ( not auth )
            this.$store.dispatch("website/setIsAuth", false);
            this.notify({
              type: "success",
              msg: res?.data?.message
            });
            
            // temporary -- will be removed later
            window.location.reload();
            // xxxx  ------  xxxxx
          } else {
            this.notify({
              type: "error",
              msg: res?.data?.message
            });
          }
        })
        .catch(err => {
          this.changeLoadingState(false);
          console.error("err", err);
          this.notify({
            type: "error",
            msg: err.errors || "An error has occurred. Please try again later."
          });
        });
    },
    logout(){
      this.confirmDialog({
        msg: `Logout?`,
        buttonLabels: { no: "No", yes: "Yes" },
        confirmedFunc: () => {
          this.onLogoutConfirmation();
        }
      });
    },
    navigate(e) {
      const section =
        e.target.innerHTML.trim().toLowerCase() == "about us"
          ? "about-us"
          : e.target.innerHTML.trim().toLowerCase();
      if (this.$route.path === "/") {
        document.getElementById(section)?.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      } else {
        this.$router.push("/");
        setTimeout(() => {
          document.getElementById(section)?.scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
        }, 100);
      }
    },
    toggleMenu() {
      let menuBar = document.getElementById("header-menu-popup");
      if (menuBar.style.display == "" || menuBar.style.display == "none") {
        menuBar.style.display = "flex";
      } else if (menuBar.style.display == "flex") {
        menuBar.style.display = "none";
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeToolTip);
  }
};
</script>

<style lang="scss" scoped>
.main-header {
  height: 80px !important;
  background-image: linear-gradient(to bottom, #fc9232, #f97314);
  width: 100%;
  position: relative;
  .header-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .logo {
      width: 80px;
      > img {
        display: block;
        width: 100%;
      }
    }
    .header-menu {
      transition: 0.3s;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
      gap: 20px;
      @media (min-width: 1199px) {
        width: 63%;
      }
      @media (max-width: 992px) {
        display: none;
      }

      .header-links {
        display: flex;
        justify-content: end;
        align-items: center;
        margin: 0 0 0 40px;
        padding: 0;
        gap: 10px;
        flex-grow: 1;

        > a {
          font-size: 16px;
          color: #ffffff;
          opacity: 0.8;
          transition: 0.3s;
          &:last-of-type {
            li {
              margin-right: 0;
            }
          }
          > li {
            font-weight: 400;
            color: inherit;
            opacity: inherit;
            position: relative;
            transition: none;
            margin-right: 1rem;
            &::after {
              transition: background-color 0.2s linear;
              height: 3px;
              background-color: transparent;
            }
          }
          &:hover {
            opacity: 1;
          }
        }
        > .router-link-exact-active {
          opacity: 1;
          > li {
            padding: 0.4rem 0;
            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 3px;
              background-color: #fff;
              left: 0;
              bottom: 0;
            }
          }
        }
      }
    }
    .header-menu-popup {
      transition: 0.3s;
      display: none;
      @media (max-width: 992px) {
        display: none;
        position: absolute;
        z-index: 999;
        top: 80px;
        background-color: #f97314;
        right: 15px;
        width: 250px;
        flex-direction: column-reverse;
        padding: 10px 15px;
      }
    }
    .toggle-menu {
      display: none;
      flex-direction: column;
      padding: 5px;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      justify-content: space-evenly;
      align-items: center;
      //   border: 1px solid #000;
      background-color: #fff;
      > span {
        width: 30px;
        height: 1px;
        background-color: #f97314;
        border-radius: 5px;
      }
      @media (max-width: 992px) {
        display: flex;
      }
    }
  }
}
</style>
