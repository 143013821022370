<template>
  <div class="active--numbers--list col-xl-6 col-lg-5 col-md-12 col-sm-12 pt-5 px-0 flex-column">
    <div class="active--numbers--list--inner mx-5 bg-gray-50 dark:bg-fox-800">
      <div class="mt-4">
        <h5 class="h5 mb-5">Active numbers</h5>
      </div>
      <div class="active--numbers--scrollable--list">
        <div v-if="isLoadingNumbersList" class="active--numbers--loading--skeleton">
          <ul>
            <li v-for="(_, i) in Array.from({length: 4})" :key="i" class="py-2">
              <div
                role="status"
                class="flex-row align-center justify-between max-w-sm animate-pulse"
              >
                <span class="text-lg font-bold flex-row align-items-center">
                  <div role="status" class="max-w-sm animate-pulse mr-2">
                    <div
                      class="h-5 bg-gray-200 rounded-full dark:bg-gray-700 mb-4"
                      :style="{width: '25px'}"
                    ></div>
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div role="status" class="max-w-sm animate-pulse">
                    <div class="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                    <span class="sr-only">Loading...</span>
                  </div>
                </span>
                <div class="flex-row align-center">
                  <div role="status" class="max-w-sm animate-pulse">
                    <div
                      class="h-5 bg-gray-200 rounded-full dark:bg-gray-700 mb-4 mr-2"
                      :style="{width: '25px'}"
                    ></div>
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div role="status" class="max-w-sm animate-pulse">
                    <div
                      class="h-5 bg-gray-200 rounded-full dark:bg-gray-700 mb-4"
                      :style="{width: '25px'}"
                    ></div>
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
         
        </div>
        <div v-else-if="activeNumbersList && activeNumbersList.length && !isLoading">
          <ul>
            <ActiveNumberItem
              :newMessages="newMessages ? newMessages.filter(el => el.phoneNumber && el.phoneNumber.number && el.phoneNumber.number.toString() === item.number.toString()) : []"
              v-for="item in activeNumbersList.slice(0,6)"
              :key="item.id"
              :item="item"
              :copyText="copyText"
              :isRentNumber="isRentNumber" 
              :deleteNumberFromList="deleteNumberFromList"
              @reactivateNum="reactivateNumber"
              :onRenewingNumberConfirmation="onRenewingNumberConfirmation"
              :isReactivatingNumber="isReactivatingNumber"
              :isAutoRenewingNumber="isAutoRenewingNumber"
            />
          </ul>
          <button class="submit--btn" @click="() => directMeToMessages()">Go to messages</button>
        </div>
        <div v-else class="active--nums--empty">
          <div
            class="p-5 mt-5 mx-3 mb-3 flex items-center justify-center border-2 border-dashed dark:border-gray-700 border-gray-200 text-center"
          >
            <p class="mb-0 text-lg">No activated numbers are available</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { routesNames } from "@/utilities/basicData";
import ActiveNumberItem from "./ActiveNumberItem.vue";
//renew number =>> indicates whether the number is expired or available
//active number =>> indicates whether the number is online or offline

export default {
  data() {
    return {
      abortController: new AbortController(),
      newMessages: [],
      gettingMessagesInterval: undefined,
      isReactivatingNumber: false, 
      // isActivatingNumber: false,
      isAutoRenewingNumber: false
    };
  },
  methods: {
    directMeToMessages() {
      this.$router.push(routesNames.user.messages);
    },
    getNewMessages() {
      this.makeRequest({
        owner: this.requestOwners.user,
        method: this.apiRequests.user.getNewMessages.method,
        authRequired: true,
        url: this.apiRequests.user.getNewMessages.url,
        formData: {},
        abortControllerSignal: this.abortController.signal
      }).then(res => {
        if (res?.success) {
          if (res.data?.hasNewMessages && res.data?.messages?.length > 0) {
            this.newMessages = res.data?.messages?.slice(0, 15) || [];
          }
        } else {
          this.notify({
            type: "error",
            msg: res.errors || "Failed to fetch numbers list."
          });
        }
      });
    },
    onRenewingNumberConfirmation(numberId, state){
      return new Promise((resolve, reject) => {
        if(!numberId){
          reject();
          return;
        }
        this.isAutoRenewingNumber = true;
        this.makeRequest({
          owner: this.requestOwners.user,
          method: this.apiRequests.user.autoRenew.method,
          authRequired: true,
          url: `${this.apiRequests.user.autoRenew.url}/${numberId}`,
          formData: JSON.stringify({
            is_renewable: +!state
          }),
          abortControllerSignal: this.abortController.signal
        })
          .then(res => {
            this.isAutoRenewingNumber = false;
            // failed
            if (!res?.success) {
              reject();
              this.notify({
                type: "error",
                msg: res.message
              });
            } else {
              // succeeded
              resolve();
              this.$emit("updateActiveNumbers");
              this.notify({
                type: "success",
                msg: res.message || 'Done successfully'
              });
            }
          })
          .catch(err => {
            reject();
            // failed
            this.isAutoRenewingNumber = false;
            this.notify({
              msg: err.errors || `${state ? 'Failed to cancel auto-renew' : 'Failed to auto-renew'}`,
              type: "error"
            });
          });
      })

    },
    // activateNumber(numberId){
    //   if(!this.isRentNumber || !numberId){
    //     return;
    //   }
    //   this.isActivatingNumber = true;
    //   this.makeRequest({
    //     owner: this.requestOwners.user,
    //     authRequired: true,
    //     method: this.apiRequests.user.activateNumber.method,
    //     url: `${this.apiRequests.user.activateNumber.url}/${numberId}`,
    //     abortControllerSignal: this.abortController.signal
    //   })
    //     .then(res => {
    //       this.isActivatingNumber = false;
    //       // failed
    //       if (res?.error) {
    //         this.notify({
    //           type: "error",
    //           msg: res.message
    //         });
            
    //       }else{
    //          this.$emit("updateActiveNumbers");
    //       }
    //     })
    //     .catch(() => {
    //       this.isActivatingNumber = false;
    //       this.notify({
    //           type: "error",
    //           msg: "Failed to re-activate number"
    //         });
    //     });
    // },
    reactivateNumber(numberId){
      if(!this.isRentNumber || !numberId){
        return;
      }
      this.isReactivatingNumber = true;
      this.makeRequest({
        owner: this.requestOwners.user,
        authRequired: true,
        method: this.apiRequests.user.reactivateNumber.method,
        url: `${this.apiRequests.user.reactivateNumber.url}/${numberId}`,
        abortControllerSignal: this.abortController.signal
      })
        .then(res => {
          this.isReactivatingNumber = false;
          // failed
          if (res?.error) {
            this.notify({
              type: "error",
              msg: res.message
            });
            
          }else{
             this.$emit("updateActiveNumbers");
          }
        })
        .catch((err) => {
          this.isReactivatingNumber = false;
          this.notify({
              type: "error",
              msg: err?.errors || "Failed to re-activate number"
            });
        });
    },
    updateNumbersMessages(){
            // resets interval
            window.clearInterval(this.gettingMessagesInterval);
            this.gettingMessagesInterval = undefined;
            // creates a new interval
              this.gettingMessagesInterval = setInterval(() => {
                    this.getNewMessages();
                }, 10500);
    },
  },
  watch: {
    "activeNumbersList.length": {
      immediate: true,
      handler(length){
        if(length > 0){
          this.updateNumbersMessages();
        }
      }
    }
  },
  props: {
    activeNumbersList: {
      type: Array,
      required: true,
      default: () => []
    },
    copyText: {
      type: Function,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false
    },
    isRentNumber: {
      type: Boolean,
      required: true,
      default: false
    },
    isLoadingNumbersList: {
      type: Boolean,
      required: true,
      default: false
    },
    deleteNumberFromList: {
      type: Function,
      required: true
    }
  },
  components: { ActiveNumberItem },
  beforeDestroy(){
    this.abortController.abort();
    window.clearInterval(this.gettingMessagesInterval);
  }
};
</script>

<style lang="scss" scoped>
.active--numbers--loading--skeleton {
  height: 407px;
  overflow-y: auto;
}
.active--numbers--list {
  .active--nums--empty {
    height: 407px;
  }
  .active--numbers--list--inner {
    border: 1px solid var(--borders-gray);
    border-radius: 6px;
    box-shadow: 0 6px 16px rgb(0, 0, 0, 0.08);
    padding: 0.5rem 1.2rem;
    .active--numbers--scrollable--list {
      .submit--btn {
        width: 100%;
        border-radius: 0;
        max-width: none;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
    ul {
      padding: 0;
      overflow-y: auto;
      overflow-x: hidden;
      height: 340px;
    }
    h5 {
      color: var(--primary-clr);
      letter-spacing: 0.08rem;
    }
    span {
      font-size: 15.5px;
    }
  }
  @media only screen and (max-width: 1180px) {
    .active--numbers--list--inner.active--numbers--list--inner {
      margin-left: 0 !important;
      margin-right: 0 !important;
      span {
        font-size: 15px;
      }
    }
  }
}
#homeDesign{
  .active--numbers--list {
    .active--numbers--list--inner {
      border: none;
      background: #7388921A;
      h5 {
        color: #1E282D;
      }
    }
  }
}
body.dark{
  #homeDesign{
  .active--numbers--list {
      .active--numbers--list--inner {
        background: #1C282D;
        h5 {
          color: #e6e4e4;
        }
      }
    }
  }
}
</style>