<template>
  <div>
    <div v-if="isLoading" class="h-24 mb-2 bg-gray-100 dark:bg-fox-900 flex items-center justify-center rounded">
      <span class="animate-spin h-8 w-8 border-4 border-gray-300 border-t-gray-500 rounded-full"></span>
    </div>

    <div v-if="!messagesList.length > 0 && !isLoading" class="h-24 mb-2 bg-gray-100 dark:bg-fox-900 flex items-center justify-center rounded">
      <span class="text-lg">No messages for this number</span>
    </div>
 
    <div v-else>
      <!-- Message item -->
      <div v-for="(msg, i) in messagesList && messagesList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)" :key="i" class="message-outer w-full">
        <MessagesItem :msg="msg" :service="service" />
      </div>

      <!-- Pagination -->
      <div v-if="totalPages" class="flex items-center justify-end space-x-1 font-bold">
        <button :disabled="this.currentPage <= 1" @click="changePage(-1)" class="px-1 py-2 text-white bg-transparent disabled:opacity-25">
          <svg class="w-8 h-8 fill-orange-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path>
          </svg>
        </button>

        <button class="px-4 py-2 bg-orange-200 text-gray-800 rounded-md">{{ currentPage }} / {{ totalPages }}</button>

        <button :disabled="this.currentPage >= this.totalPages" @click="changePage(1)" class="px-1 py-2 bg-transparent disabled:opacity-25">
          <svg class="w-8 h-8 fill-orange-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import MessagesItem from './MessagesItem.vue';
export default {
  props: {
    tzid: {
      type: String,
      required: true
    },
    isRentNumber: {
      type: Boolean,
      required: true,
      default: false
    },
    isActive: {
      type: Boolean,
      required: true
    },
    newMsgs: {
      type: Array,
      required: false
    },
    filterNumbersBy: {
      type: String,
      required: true
    },
    oldMsgs: {
      type: Array,
      required: true
    },
    service: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      messagesList: [],
      isLoading: false,
      // Pagination
      totalPages: undefined,
      currentPage: 1,
      itemsPerPage: 5,
    };
  },
  components: {
    MessagesItem
},
  methods: {
    removeDuplicatedMsgs(msgs) {
      return msgs?.length > 0 ? Array.from(new Set(msgs.map((item) => item?.msg))).map((uniqueMsg) => msgs.find((el) => el?.msg === uniqueMsg)) : [];
    },
    getMessagesList(){
      if(this.filterNumbersBy !== "available"){
        this.messagesList = this.removeDuplicatedMsgs(this.oldMsgs);
      }else{
          this.isLoading = true;
          this.makeRequest({
            owner: this.requestOwners.user,
            method: this.apiRequests.user.getMessagesList.method,
            authRequired: true,
            url: `${this.apiRequests.user.getMessagesList.url}?tzid=${this.tzid}&service_type=unitedsms&term=${this.isRentNumber ? 'long' : 'short'}`,
            formData: {},
          })
            .then((res) => {
              if (res?.success) {
                this.isLoading = false;
                let msgs = res.data?.messages?.slice(0, 100) ?? [];
                this.messagesList = this.removeDuplicatedMsgs(msgs);

                // Calc total pages
                this.totalPages = Math.floor(this.messagesList.length / this.itemsPerPage);
              } else {
                this.isLoading = false;
              }
            })
            .catch((err) => {
              this.isLoading = false;
              console.error(err);
            });  
      }

    },
    changePage(to) {
      if (to === 'last') {
        this.currentPage = this.totalPages;
      } else if (this.currentPage + to >= 1 && this.currentPage + to <= this.totalPages) {
        this.currentPage = this.currentPage + to;
      }
    },
    changePage(to) {
      if (to === 'last') {
        this.currentPage = this.totalPages;
      } else if (this.currentPage + to >= 1 && this.currentPage + to <= this.totalPages) {
        this.currentPage = this.currentPage + to;
      }
    },
  },
  mounted() {
    this.getMessagesList();
  },
  watch:{
    newMsgs:{
      deep: false,
      immediate: true,
      handler(val){
        if(val && Array.isArray(val) && val.length > 0){
            let newList = this.messagesList?.length > 0 ? [...this.messagesList] : [];
            val.forEach((msg) => {
              newList.push(msg);
              newList = this.removeDuplicatedMsgs(newList);
            })
            this.messagesList = newList;
        }
      }
    },
    // isActive(val){
    //   if(typeof val === 'boolean' && val){
    //     this.getMessagesList();
    //   }
    // }
  }
};
</script>

<style></style>
