<template>
  <li @click="() => closeBar()" class="nav-item dashboard-sidebar-menu" :class="isActive ? 'active' : ''">
    <router-link :to="link" :title="text" class="nav-link flex-row flex-nowrap justify-between align-center">
      <div>
        <v-icon class="link-icon" size="20" >mdi-{{icon}}</v-icon>
        <span class="link-title">
          {{ text }}
        </span>   
      </div>
      <span v-show="typeof counter === 'number' && counter > 0" class="counter__badge">{{counter}}</span>
    </router-link>
  </li>
</template>


<script>
export default {
  props: ["isActive", "link", "dataFeatherIcon", "icon","text", "closeSidebar", "counter"],
  methods: {
    closeBar() {
      if((window.innerWidth || document.documentElement.offsetWidth) < 990){
        this.closeSidebar();
      }
    }
  }
};
</script>
<style scoped>
  span.counter__badge{
    background-color: #bf1f1f;
    color: #fff;
    font-size: 11px;
    border-radius: 20px;
    line-height: 11px;
    min-height: 11px;
    min-width: 11px;
    padding: 5px 7px;
    border: 1px solid #c93a3a;    
  }
</style>