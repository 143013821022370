<template>
    <li @click="changeSelectedCountry({countryItem: item, isMobile})" :id="item.id" ref="countryItem" :class="`country--item flex-row ${(isSelected && !isMobile) ? 'active': ''}`">
        <div class="country--item--inner flex-row align-center">
                                                                      <!-- getCountryAbbrFromId(item.country) -->
            <CountryFlagIcon spanClass="country--flag mr-3" defaultClass="mr-1 country--default--icon" :countryId="getCountryAbbrFromId(1)" :isSelected="(isSelected && !isMobile)"/>
            <span class="country--name">{{item.country}}</span>
        </div>
    </li>
</template>

<script>
import CountryFlagIcon from "@/components/Generic/CountryFlagIcon.vue";

export default {
    props: {
        item:{
            type: Object,
            required: true    
        },
        isSelected: {
            type: Boolean,
            required: true
        },
        changeSelectedCountry: {
            type: Function,
            required: true
        },
        isMobile: {
            type: Boolean,
            required:false,
            default: false
        },
        isRentNumber: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        CountryFlagIcon
    },
    watch: {
        isSelected:{
            immediate: false,
            handler(bool){
                // smoothly scrolls to the active item in a way it places it on top of list container
                if(bool && !this.isMobile &&this.$refs?.countryItem){
                    this.$refs.countryItem.parentNode.scrollTo({
                        top: this.$refs.countryItem.offsetTop,
                        behavior: 'smooth'
                    });
                }
            }     
        }
    }
}
</script>

<style lang="scss" scoped>
    .country--item{
        width: 100%;
        padding-bottom: 10px;
        cursor: pointer;
        .country--item--inner{
            width:100%;
            height: 35px;
            margin: 0;
            line-height: 30px;
            padding: 0 0.95rem;
            border-radius: 5px;
            transition: 0.2s linear;
            border: 1px solid transparent;
            align-items: center;
            flex-wrap: nowrap;
            transition-property: color, background, border-color;
            .country--flag{
                font-size: 1.3rem;
            }
            .country--default--icon{
                font-size: 1.5rem;
            }
            .country--name{
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                white-space: nowrap;
            }
        }           
        &:hover{
            .country--item--inner{
                color: var(--primary-clr);
            }
        }
    }
    .country--item.active{
        .country--item--inner{
            transition: filter 0.2s linear;
            background: var(--primary-clr);
            border-color: var(--primary-clr);
            color: #fff;
            z-index: 1;
        }
        &:hover{
            .country--item--inner{
                filter: brightness(1.1);
            }
        }  
    }
    #homeDesign{
        .country--item.active{
            .country--item--inner{
                background: #FA8122;
            }
        }
    }
</style>