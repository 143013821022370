<template>
  <section class="app-container pt-[23px] md:pt-[50px]">
    <div class="flex flex-col align-center">

      <h2
        class="uppercase text-[20px] sm:text-[23px] md:text-[24px] lg:text-[25px] xl:text-[27px] 2xl:text-[27.4px] 3xl:text-[28px] font-[700]"
      >
        <span class="text-first"> select the service </span>
        {{ " " }}
        <span class="text-[#1E282D] dark:text-white"> you need? </span>
      </h2>
      <!-- method selector -->
      <div
        class="flex flex-nowrap flex-col ms:flex-row gap-x-0 gap-y-[0.8rem] mt-[1.25rem] md:mt-8 lg:mt-10 2xl:mt-12 mb-2 text-[18px] sm:text-[19px] md:text-[20px] lg:text-[22px] 3xl:text-[24px]"
      >
        <!-- get a number button -->
        <button
          :disabled="isLoadingBalance"
          :class="
            `${
              currentNumberMethod == 'get'
                ? 'bg-first text-white'
                : 'bg-first text-white ms:bg-lightGray ms:dark:bg-[#53646D] ms:text-[#FFFFFFCC]'
            } hover:brightness-105 transition-colors duration-200 ms:uppercase rounded-[10px] ms:rounded-[30px_0px_0px_30px] xl:rounded-[40px_0px_0px_40px] 2xl:rounded-[150px_0px_0px_150px] px-[10px] md:px-[15px] sm:px-[28px] py-1.5 lg:px-8 xl:px-10 2xl:px-11 lg:py-2`.trim()
          "
          aria-labelledby="get a number"
          aria-label="get a number"
          @click="() => changetCurrentNumberMehthod('get')"
        >
          Get a number
        </button>
        <!-- rent a number button -->
        <button
          :disabled="isLoadingBalance"
          :class="
            `${
              currentNumberMethod == 'rent'
                ? 'bg-first text-white'
                : 'bg-first text-white ms:bg-lightGray ms:dark:bg-[#53646D] ms:text-[#FFFFFFCC]'
            } hover:brightness-105 transition-colors duration-200 ms:uppercase rounded-[10px] ms:rounded-[0px_30px_30px_0px] xl:rounded-[0px_40px_40px_0px] 2xl:rounded-[0px_150px_150px_0px] px-[10px] md:px-[15px] sm:px-[28px] py-1.5 lg:px-8 xl:px-10 2xl:px-11 lg:py-2`.trim()
          "
          aria-labelledby="rent a number"
          aria-label="rent a number"
          @click="() => changetCurrentNumberMehthod('rent')"
        >
          Rent a number
        </button>
      </div>
      <!-- get number box -->
      <div v-show="currentNumberMethod === 'get'" class="w-full">
        <GetNumberVue
          ref="getNumberComponentRef"
          :isLoadingBalance="isLoadingBalance"
          :isLoadingCountries="isLoadingCountries"
          :userBalance="userBalance"
          :countriesList="allCountries"
          :updateUserBalance="updateUserBalance"
          :isHomepageDesign="true"
        />
      </div>
      <!-- rent number box -->
      <div v-show="currentNumberMethod === 'rent'" class="w-full">
        <RentNumberComponent
          ref="rentNumberComponentRef"
          :isLoadingBalance="isLoadingBalance"
          :isLoadingCountries="isLoadingCountries"
          :userBalance="userBalance"
          :countriesList="allCountries"
          :updateUserBalance="updateUserBalance"
          :isHomepageDesign="true"
        />
      </div>
    </div>
  </section>
</template>

<script>
import RentNumberComponent from "@/components/Main/Dashboard/RentNumberComponent.vue";
import GetNumberVue from "@/components/Main/Dashboard/GetNumberComponent.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      abortController: new AbortController(),
      isLoadingBalance: false,
      userBalance: 0,
      isLoadingCountries: false,
      allCountries: [],
      mobileViewPortInPixels: 821,
      currentNumberMethod: "get", //get - rent
    };
  },
  components: {
    RentNumberComponent,
    GetNumberVue,
  },
  computed: {
    ...mapGetters("main", ["isUserLoggedIn"]),
  },
  methods: {
    changeUserNumber(newVal) {
      this.userBalance = newVal;
    },
    updateUserBalance() {
      this.isLoadingBalance = true;
      this.makeRequest({
        owner: this.requestOwners.user,
        method: this.apiRequests.user.getProfile.method,
        authRequired: this.isUserLoggedIn,
        url: this.apiRequests.user.getProfile.url,
      })
        .then((res) => {
          this.isLoadingBalance = false;
          this.changeUserNumber(res.data?.balance || 0);
        })
        .catch((err) => {
          this.isLoadingBalance = false;
          this.notify({
            msg: "Failed to get balance",
            type: "error",
          });
        });
    },
    changetCurrentNumberMehthod(newMethod) {
      if (newMethod){
        if (this.currentNumberMethod !== newMethod) {
          // update the 'currentNumberMethod' with the selected method
          this.currentNumberMethod = newMethod;
        }         
        // open the numbers list modal if on mobile
        if (window.innerWidth <= this.mobileViewPortInPixels) {
            const getNumberComponentRef = this.$refs["getNumberComponentRef"];
            const rentNumberComponentRef = this.$refs["rentNumberComponentRef"];

            if (newMethod === "get") {
              getNumberComponentRef?.changeShowingContainerOnMobileState();
            } else if (newMethod === "rent") {
              rentNumberComponentRef?.changeShowingContainerOnMobileState();
            }
        }
      }

    },
    fetchAvailableCountries() {
      return new Promise((resolve, reject) => {
        this.isLoadingCountries = true;
        this.makeRequest({
          owner: this.requestOwners.user,
          method: this.apiRequests.user.getCountriesList.method,
          authRequired: this.isUserLoggedIn,
          url: `${this.apiRequests.user.getCountriesList.url}?service_type=unitedsms&term=short`,
          abortControllerSignal: this.abortController.signal,
        })
          .then((res) => {
            resolve();
            this.isLoadingCountries = false;
            const newData = res?.data || [];

            if (res?.success && Array.isArray(newData)) {
              if (newData.length > 0) {
                const setAllCountries = newData.map((el) => {
                  return {
                    country: el.name,
                    id: el.code,
                  };
                });
                this.allCountries = [
                  { country: "Any State", id: null },
                  ...setAllCountries,
                ];
              } else {
                this.allCountries = [];
              }
            } else {
              this.notify({
                msg: "Failed to get countries list",
                type: "error",
              });
            }
          })
          .catch(() => {
            reject();
            this.isLoadingCountries = false;
            this.notify({
              type: "error",
              msg: "Failed to fetch numbers list.",
            });
          });
      });
    },
  },
  mounted() {
    this.fetchAvailableCountries().then(() => {
      if (this.isUserLoggedIn) {
        this.updateUserBalance();
      }
    });
  },
  beforeDestroy() {
    this.abortController.abort();
  },
};
</script>

<style></style>
