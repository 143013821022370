import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './design/Index.scss';
import VueConfirmDialog from '../node_modules/vue-confirm-dialog/src/index';
import VueMoment from "vue-moment";

import 'vue-toast-notification/dist/theme-sugar.css';
import './assets/custom.scss';
import "./assets/core.css";

import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/dist/vuetify.min.css';
import VueMeta from 'vue-meta';
import vSelect from 'vue-select';
import VueToast from 'vue-toast-notification';
import { globalMixins } from './utilities';
import vuetify from './plugins/vuetify';
import Vuelidate from "vuelidate";
import 'vue-awesome/icons'
import 'vue-awesome/icons/flag'

import Icon from 'vue-awesome/components/Icon'

import './assets/tailwind.css';

Vue.component('v-select', vSelect);
Vue.component('v-icon', Icon)
Vue.use(VueMeta);
Vue.config.productionTip = false;
Vue.use(VueConfirmDialog);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
Vue.use(Vuelidate);
Vue.use(VueMoment);

Vue.config.ignoredElements = ['vue-select '];
// global mixins
Vue.mixin(globalMixins);
// global filter
Vue.filter('formatNumber', function (num) {
	if (typeof num !== 'number') return;
	if (num >= 1000000000) {
		return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
	}
	if (num >= 1000000) {
		return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
	}
	if (num >= 1000) {
		return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
	}
	return num;
});
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

// Register service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('Service Worker registered successfully:', registration.scope);
    }).catch(error => {
      console.log('Service Worker registration failed:', error);
    });
  });
}
