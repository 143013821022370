<template>
    <span class="service--icon">
        <v-avatar v-if="lowercasedService === 'facebook'" class="message--user--img" color="#1877f2" :size="size">
            <v-icon dark >
                mdi-facebook
            </v-icon>
        </v-avatar>
        <v-avatar v-else-if="lowercasedService === 'twitter'" class="message--user--img" color="#1da1f2" :size="size">
            <v-icon dark >
                mdi-twitter
            </v-icon>
        </v-avatar>
        <v-avatar v-else-if="lowercasedService === 'youtube'" class="message--user--img" color="#ff0000" :size="size">
            <v-icon dark >
                mdi-youtube
            </v-icon>
        </v-avatar>
        <v-avatar v-else-if="lowercasedService === 'instagram'" class="message--user--img" color="#c32aa3" :size="size">
            <v-icon dark >
                mdi-instagram
            </v-icon>
        </v-avatar>
        <v-avatar v-else-if="lowercasedService === 'snapchat'" class="message--user--img" color="#fffc00" :size="size">
            <v-icon dark >
                mdi-snapchat
            </v-icon>
        </v-avatar>
        <v-avatar v-else-if="lowercasedService === 'linkedin'" class="message--user--img" color="#0a66c2" :size="size">
            <v-icon dark >
                mdi-linkedin
            </v-icon>
        </v-avatar>
        <v-avatar v-else-if="lowercasedService === 'reddit'" class="message--user--img" color=" #ff4500" :size="size">
            <v-icon dark >
                mdi-reddit
            </v-icon>
        </v-avatar>
        <v-avatar v-else-if="lowercasedService === 'skype'" class="message--user--img" color="#00aff0" :size="size">
            <v-icon dark >
                mdi-skype
            </v-icon>
        </v-avatar>
        <v-avatar v-else-if="lowercasedService === 'whatsapp'" class="message--user--img" color="#25d366" :size="size">
            <v-icon dark >
                mdi-whatsapp
            </v-icon>
        </v-avatar>
        <v-avatar v-else class="message--user--img" color="#F97314" :size="size">
  
            <strong :style="{color: '#fff'}">{{typeof this.service === "string" ? this.service.charAt(0).toUpperCase() : ''}}</strong>
        </v-avatar>
    </span>

</template>

<script>
export default {
    props: {
        service: {
            type: String,
            required: true,
            default: ""
        },
        size: {
            type: [Number, String],
            required: false,
            default: 40
        }
    },
    computed: {
        lowercasedService(){
            return this.service ? this.lowerString(this.service) : ''
        }
    }
}
</script>

<style scoped>
    .service--icon{
        display: grid;
        place-content: center;
    }
</style>