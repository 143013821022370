<template>
  <div id="page">
    <!-- loading progress bar -->
    <div v-if="loadingPercent < 100" class="loading--progress--bar">
      <div class="loading--bar--inner" :style="{ width: loaded }"></div>
    </div>
    <!-- component -->
    <slot />
  </div>
</template>

<script>
import appConfig from '@/configs/appConfig.json';
export default {
  data() {
    return {
      loadingPercent: 0,
      loadTime: 0,
      interval: null,
    };
  },
  props: {
    title: {
      type: String,
      required: false,
      default: appConfig.title_en,
    },
    description: {
      type: String,
      required: false,
      default: appConfig.description,
    },
  },
  metaInfo() {
    return {
      title: this.title,
      titleTemplate: `${this.title ? '%s |' : ''} ${appConfig.title_en}`,
      meta: [{ name: 'description', content: this.description }],
    };
  },
  methods: {
    doProgress() {
      let step = this.loadTime / 100;
      this.interval = setInterval(() => {
        this.loadingPercent++;
      }, step);
    },
  },
  computed: {
    loaded() {
      return this.loadingPercent + '%';
    },
  },
  watch: {
    loadingPercent(val) {
      if (val >= 100) {
        this.loadTime = 0;
        clearInterval(this.interval);
      }
    },
  },
  created() {
    let perfData = window.performance.timing;
    let estimatedTime = Math.abs(perfData.loadEventEnd - perfData.navigationStart);
    this.loadTime = parseInt((estimatedTime / 1000) % 60) * 100;
    this.doProgress();
  },
};
</script>

<style lang="scss" scoped></style>
