import { localStorageBasicData } from "../../utilities/basicData";
import { locStorage, isString } from "../../utilities/tools";
export const state = {
    localStorageCopy: JSON.parse(JSON.stringify(localStorageBasicData)),
    paypalRequestsCount: 0,
    keys : {
        isLoadingGlobally: false,
        isAddCreditModalOpenGlobally: false
    },
    // test code
    loader: false,
    localStorageBasicData: JSON.parse(JSON.stringify(localStorageBasicData)),
    isLoggedIn: false,
    token: "",
    footerData: {
      social: {
        facebook: "",
        whatsapp: "",
        linked_in: "",
        instagram: ""
      },
    },
    testimonials:[],
    faqs:[],
    faqsSections:[],
    onboarding:[],
    sellectedCategoryId:'',
    terms: "",
    policy: "",
}

export const mutations = {
    mutateKeys: (state, payload) => {
        const { key, val } = payload;
        const trimmedKey = key.trim();
        if(trimmedKey && (Object.keys(state.keys).map(el => el).indexOf(trimmedKey) !== -1)){
            state.keys[trimmedKey] = (val !== undefined) ? val : !state.keys[trimmedKey];
        }else{
            console.error("Key does not exist.");
        }
        return;
    },
    updateMemory: (state, payload) => {
        const parsedBasicData = JSON.parse(JSON.stringify(localStorageBasicData)) 
        const { type, itemId, item, destination, storage, newObject, createEmptyObject = false } = payload;

        if (type === "add" && typeof item === "object" && destination && state.localStorageCopy?.hasOwnProperty(destination)) {
            let newArr = JSON.parse(JSON.stringify(state.localStorageCopy?.[destination]));
            if (Array.isArray(newArr)) {
                newArr.unshift(item);
                newArr = Array.from(
                    new Set(newArr.map((x) => x.id))
                ).map((w) => newArr.find((el) => el.id === w));
                locStorage({ type: "set", newData: newArr, destination });
                const newLocStorageArr = {...state.localStorageCopy,[destination] : newArr };
                Object.keys(parsedBasicData || {}).forEach(key => {
                    if(!newLocStorageArr.hasOwnProperty(key)){
                        newLocStorageArr[key] = parsedBasicData[key];
                    }
                });
                state.localStorageCopy = newLocStorageArr;
            }
        } else if (type === "delete" && itemId && destination && state.localStorageCopy?.hasOwnProperty(destination)) {
            let newArr = JSON.parse(JSON.stringify(state.localStorageCopy?.[destination]));
            if (Array.isArray(newArr)) {
                const delIndex = newArr.map(item => item.id).indexOf(itemId);
                if (delIndex !== -1) {
                    newArr.splice(delIndex, 1);
                    locStorage({ type: "set", newData: newArr, destination });
                }
                state.localStorageCopy = {...state.localStorageCopy,[destination] : newArr }

            }
        } else if(type === "update" && destination && state.localStorageCopy?.hasOwnProperty(destination)){
            if(typeof newObject === "object" || Array.isArray(newObject) || isString(newObject) || typeof newObject === "boolean"){
                 locStorage({ type: "set", newData: newObject, destination });
                    state.localStorageCopy = {
                        ...state.localStorageCopy,
                        [destination]: newObject
                    }
            }
           
        } else if (type === "set" && typeof storage === "object" && storage !== null) {
                state.localStorageCopy = {
                    cart: storage.cart || [],
                    language: storage.language || "en",
                    user:  storage.user || {},
                    admin:  storage.admin || {},
                    auth: storage.auth || {},
                    isDarkmode: typeof storage.isDarkmode ? storage.isDarkmode : false,
                    siteSettings: typeof storage.siteSettings ? storage.siteSettings : false,
                }
            if(createEmptyObject){
                locStorage({ type: "reset", newData: storage});
            }
        }else{
            return ;
        }
    },
    updatePaypalRequestsCount(state, newNumber){
        if(typeof newNumber !== 'number'){
            return;
        }
        state.paypalRequestsCount = newNumber;
    },
    // test code
    updateOnboarding(state, payload){
        state.onboarding = payload
    },
      updateSelectedCategoryId(state, payload){
        state.sellectedCategoryId = payload
    },
    updateFaqsSections(state, payload){
        state.faqsSections = payload
    },
    updateFaqs(state, payload){
        state.faqs = payload
    },
    updaTetestimonials(state, payload){
        state.testimonials = payload
    },
    updateLocalStorageBasicData(state, payload){
        const updateLocalStorge = JSON.stringify(payload)
        localStorage.setItem('fox-sims' , updateLocalStorge);
        state.localStorageBasicData = payload;
    },
    updateIsLoggedIn(state, payload) {
        state.isLoggedIn = payload;
    },
    updateToken(state, payload) {
        state.token = `${payload}`
    },
    updateTerms(state, payload) {
        state.terms = payload
    },
    updatePolicy(state, payload) {
        state.policy = payload
    },
    updateLoader(state, payload) {
        state.loader = payload
    },
    turnLoaderOn(state) {
        state.loader = true;
    },
    turnLoaderOff(state) {
        state.loader = false;
    },
    updateFooterData(state, payload) {
        state.footerData.social.facebook = payload.social_media.facebook;
        state.footerData.social.instagram = payload.social_media.instagram;
        state.footerData.social.linked_in = payload.social_media.linked_in;
        state.footerData.social.whatsapp = payload.social_media.whatsapp;
    },
}

export const actions = {
    mutateKeys: (state, payload) => {
        return state.commit("mutateKeys", payload);
    },
    updateMemory(state, payload) {
        state.commit("updateMemory", payload);
    },
    updatePaypalRequestsCount(state, payload) {
        state.commit("updatePaypalRequestsCount", payload);
    },
     // test code
    updateOnboarding(state, payload){
        state.commit("updateOnboarding", payload);
    },
    updateSelectedCategoryId(state, payload){
        state.commit("updateSelectedCategoryId", payload);
    },
    updateFaqsSections(state, payload){
        state.commit("updateFaqsSections", payload);
    },
    updateFaqs(state, payload){
        state.commit("updateFaqs", payload);
    },
    updaTetestimonials(state, payload){
        state.commit("updaTetestimonials", payload);
    },
    updateLocalStorageBasicData(state, payload){
        state.commit("updateLocalStorageBasicData", payload);
    },
    updateIsLoggedIn(state, payload) {
        state.commit("updateIsLoggedIn", payload);
    },
    updateToken(state, payload) {
        state.commit("updateToken", payload);
    },
    updateTerms(state, payload) {
        state.commit("updateTerms", payload);
    },
    updatePolicy(state, payload) {
        state.commit("updatePolicy", payload);
    },
    updateLoader(state, payload) {
        state.commit("updateLoader", payload);
    },
    turnLoaderOn(state, payload) {
        state.commit("turnLoaderOn", payload);
    },
    turnLoaderOff(state, payload) {
        state.commit("turnLoaderOff", payload);
    },
    updateFooterData(state, payload) {
        state.commit("updateFooterData", payload);
    }
}

export const getters = {
    getKeys: state => state.keys,
    getLocalStorage: state => state.localStorageCopy,
    getAdminToken: state => state.localStorageCopy?.admin.token || "",
    getUserToken: state => state.localStorageCopy?.user.token || "",
    getUserBalance: state => state.localStorageCopy?.user.balance || 0,
    getCurrentUser: state => state.localStorageCopy?.user || {},
    isUserLoggedIn: store => !!(store?.localStorageCopy?.user?.token),
    getLoadingGloballyState: state => typeof state.keys.isLoadingGlobally === "boolean" ? state.keys.isLoadingGlobally : false,
    getPaypalRequestsCount: state => typeof state.paypalRequestsCount === 'number' ? state.paypalRequestsCount : 0,
    // test code
}