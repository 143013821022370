export const state = {
	websiteData: [],
	social: [],
	features: [],
	carousel: [],
	aboutUsItems: [],
	isAuth: false,
	isLoadingWebsiteData: false,
};

export const mutations = {
	setWebsiteData(state, payload) {
		state.websiteData = payload;
	},
	setSocial(state, payload) {
		state.social = payload;
	},
	setFeatures(state, payload) {
		state.features = payload;
	},
	setCarousel(state, payload) {
		state.carousel = payload;
	},
	setAboutUsItems(state, payload) {
		state.aboutUsItems = payload;
	},
	setIsAuth(state, payload) {
		state.isAuth = payload;
	},
	updateLoadingWebsiteState(state, payload){
		state.isLoadingWebsiteData = payload;
	}
};

export const actions = {
	setWebsiteData(state, payload) {
		state.commit('setWebsiteData', payload);
		// add social media
		let allSocial = [];
		allSocial.push(
			{ facebook: payload?.siteData?.facebook },
			{ twitter: payload?.siteData?.twitter },
			{ instagram: payload.siteData?.instagram },
			{ discord: payload.siteData?.discord}
		);
		state.commit('setSocial', allSocial);
		// add features
		let features = [];
		features.push(
			{
				icon: payload?.siteData?.full_feature_first_icon,
				content: payload?.siteData?.full_feature_first_text,
				title: payload?.siteData?.full_feature_first_title,
			},
			{
				icon: payload?.siteData?.full_feature_second_icon,
				content: payload?.siteData?.full_feature_second_text,
				title: payload?.siteData?.full_feature_second_title,
			},
			{
				icon: payload?.siteData?.full_feature_third_icon,
				content: payload?.siteData?.full_feature_third_text,
				title: payload?.siteData?.full_feature_third_title,
			}
		);
		state.commit('setFeatures', features);
		// add carousel
		let carousel = [];
		carousel.push(
			payload?.siteData?.slider_first,
			payload?.siteData?.slider_second,
			payload?.siteData?.slider_third,
			payload?.siteData?.slider_forth
		);
		state.commit('setCarousel', carousel);
		// add About Us Items
		let aboutUsItems = [];
		aboutUsItems.push(
			{
				title: payload?.siteData?.about_item_title_first,
				text: payload?.siteData?.about_item_article_first,
				img: payload?.siteData?.about_item_img_first,
			},
			{
				title: payload?.siteData?.about_item_title_second,
				text: payload?.siteData?.about_item_article_second,
				img: payload?.siteData?.about_item_img_second,
			}
		);
		state.commit('setAboutUsItems', aboutUsItems);
	},
	setIsAuth(state, payload) {
		state.commit('setIsAuth', payload);
	},
	updateLoadingWebsiteState(state, payload) {
		state.commit('updateLoadingWebsiteState', payload);
	}
};

export const getters = {
	getIsAuth(state) {
		return state.isAuth;
	},
	getSocial(state) {
		return state.social;
	},
	aboutUs(state) {
		return state.websiteData?.siteData?.about_us;
	},
	getLoadingWebsiteState(){
		return state.isLoadingWebsiteData;
	},
	getPackages(state) {
		return state.websiteData?.packages;
	},
	getCarousel(state) {
		return state.carousel;
	},
	getAboutUsItems(state) {
		return state.aboutUsItems;
	},
	getFeatures(state) {
		return state.features;
	},
	getFooterContent(state) {
		return state.websiteData?.siteData?.footer_title;
	},
	getEmail(state) {
		return state.websiteData?.siteData?.email_link;
	},
	getContactText(state) {
		return state.websiteData?.siteData?.contact_text;
	},
	getWebsiteData(state){
		return state.websiteData?.siteData || {};
	}
};
