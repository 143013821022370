var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"app-container pt-[23px] md:pt-[50px]"},[_c('div',{staticClass:"flex flex-col align-center"},[_c('h2',{staticClass:"uppercase text-[20px] sm:text-[23px] md:text-[24px] lg:text-[25px] xl:text-[27px] 2xl:text-[27.4px] 3xl:text-[28px] font-[700]"},[_c('span',{staticClass:"text-first"},[_vm._v(" select the service ")]),_vm._v(" "+_vm._s(" ")+" "),_c('span',{staticClass:"text-[#1E282D] dark:text-white"},[_vm._v(" you need? ")])]),_c('div',{staticClass:"flex flex-nowrap flex-col ms:flex-row gap-x-0 gap-y-[0.8rem] mt-[1.25rem] md:mt-8 lg:mt-10 2xl:mt-12 mb-2 text-[18px] sm:text-[19px] md:text-[20px] lg:text-[22px] 3xl:text-[24px]"},[_c('button',{class:`${
            _vm.currentNumberMethod == 'get'
              ? 'bg-first text-white'
              : 'bg-first text-white ms:bg-lightGray ms:dark:bg-[#53646D] ms:text-[#FFFFFFCC]'
          } hover:brightness-105 transition-colors duration-200 ms:uppercase rounded-[10px] ms:rounded-[30px_0px_0px_30px] xl:rounded-[40px_0px_0px_40px] 2xl:rounded-[150px_0px_0px_150px] px-[10px] md:px-[15px] sm:px-[28px] py-1.5 lg:px-8 xl:px-10 2xl:px-11 lg:py-2`.trim(),attrs:{"disabled":_vm.isLoadingBalance,"aria-labelledby":"get a number","aria-label":"get a number"},on:{"click":() => _vm.changetCurrentNumberMehthod('get')}},[_vm._v(" Get a number ")]),_c('button',{class:`${
            _vm.currentNumberMethod == 'rent'
              ? 'bg-first text-white'
              : 'bg-first text-white ms:bg-lightGray ms:dark:bg-[#53646D] ms:text-[#FFFFFFCC]'
          } hover:brightness-105 transition-colors duration-200 ms:uppercase rounded-[10px] ms:rounded-[0px_30px_30px_0px] xl:rounded-[0px_40px_40px_0px] 2xl:rounded-[0px_150px_150px_0px] px-[10px] md:px-[15px] sm:px-[28px] py-1.5 lg:px-8 xl:px-10 2xl:px-11 lg:py-2`.trim(),attrs:{"disabled":_vm.isLoadingBalance,"aria-labelledby":"rent a number","aria-label":"rent a number"},on:{"click":() => _vm.changetCurrentNumberMehthod('rent')}},[_vm._v(" Rent a number ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentNumberMethod === 'get'),expression:"currentNumberMethod === 'get'"}],staticClass:"w-full"},[_c('GetNumberVue',{ref:"getNumberComponentRef",attrs:{"isLoadingBalance":_vm.isLoadingBalance,"isLoadingCountries":_vm.isLoadingCountries,"userBalance":_vm.userBalance,"countriesList":_vm.allCountries,"updateUserBalance":_vm.updateUserBalance,"isHomepageDesign":true}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentNumberMethod === 'rent'),expression:"currentNumberMethod === 'rent'"}],staticClass:"w-full"},[_c('RentNumberComponent',{ref:"rentNumberComponentRef",attrs:{"isLoadingBalance":_vm.isLoadingBalance,"isLoadingCountries":_vm.isLoadingCountries,"userBalance":_vm.userBalance,"countriesList":_vm.allCountries,"updateUserBalance":_vm.updateUserBalance,"isHomepageDesign":true}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }