import Vue from "vue";
import Vuetify from "vuetify";
import store from "../store";
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: store.getters["main/getLocalStorage"]?.isDarkmode,
    themes: {
      light: {
          primary: colors.orange.darken1,
          anchor: colors.orange.darken1,
          secondary: colors.grey.darken1,
          accent: colors.shades.black,
          error: colors.red.accent3
      },
      dark: {
        primary: colors.orange.darken1,
        anchor: colors.orange.darken1,
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3
      },
    },
  },
});
