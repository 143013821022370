<template>
          <!-- add new number -->
          <AddNumberSection
            :isLoadingBalance="isLoadingBalance"
            :isLoadingNumbersList="isLoadingNumbersList"
            :isLoadingCountries="isLoadingCountries"
            :showBuyNumberContainerOnMobile="showBuyNumberContainerOnMobile"
            :changeErrorCaptured="changeErrorCaptured"
            :userBalance="Number(userBalance)"
            :errorCaptured="errorCaptured"
            :closeMobileModal="() => changeShowingContainerOnMobileState(false)"
            :changeShowingContainerOnMobileState="changeShowingContainerOnMobileState"
            :onAddingNumberFormSubmission="onAddingNumberFormSubmission"
            :countriesList="countriesList"
            :isAddingNumber="isAddingNumber"
            :activeNumbers="numbersList"
            :isRentNumber="true"
            :isLoading="isLoading"
            :deleteNumberFromList="deleteNumberFromList"
            :profitPercentage="profitPercentage"
            @updateActiveNumbers="getNumbersList()"
            :isHomepageDesign="isHomepageDesign"
            
          />
</template>
  
  <script>
import AddNumberSection from "@/components/Main/Dashboard/AddNumberSection";
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isLoading: false,
      errorCaptured: "",
      isAddingNumber: false,
      profitPercentage: 0,
      showBuyNumberContainerOnMobile: false,
      gettingNumberUpdatesInterval: undefined,
      isLoadingNumbersList: false,
      isCheckingForUpdates: false,
      numbersList: [],
      abortController: new AbortController(),
    };
  },
  components: {
    AddNumberSection
  },
  computed: {
    ...mapGetters("main", ["isUserLoggedIn"]),
  },
  props: {
    isLoadingBalance: {
      type: Boolean,
      default: false,
      required: true
    },
    isLoadingCountries: {
      type: Boolean,
      default: false,
      required: true,
    },
    countriesList: {
      type: Array,
      default: () => ([]),
      required: true
    },
    userBalance: {
      type: [Number, String],
      default: 0,
      required: true
    },
    isHomepageDesign: {
      type: Boolean,
      required: false,
      default: false,
    },
    updateUserBalance: {
      type: Function,
      required: true
    }
  },
  methods: {
    // getProfitPercentage(){
    //   return new Promise((resolve, reject) => {
    //     this.isLoading = true;
    //     this.makeRequest({
    //         owner: this.requestOwners.user,
    //         method: this.apiRequests.user.getSettings.method,
    //         authRequired: true,
    //         url: this.apiRequests.user.getSettings.url,
    //       })
    //         .then(res => {
    //           resolve();
    //           this.isLoading = false;
    //           if (res?.success) {
    //             this.profitPercentage = +res.data?.profit_percentage;
    //           }
    //         })
    //         .catch(err => {
    //           this.isLoading = false;
    //           reject();
    //         });
    //   });
    // },
    deleteNumberFromList(numberId) {
      if (!numberId || this.numbersList?.length <= 0) {
        return;
      }
      this.numbersList = this.numbersList.filter(el => el.id !== numberId);
    },
    getNumbersList() {
      return new Promise((resolve, reject) => {
        if(!this.isUserLoggedIn){
          resolve();
          return;
        }
        this.isLoadingNumbersList = true;
        this.makeRequest({
          owner: this.requestOwners.user,
          method: this.apiRequests.user.getNumbersList.method,
          authRequired: this.isUserLoggedIn,
          url: `${this.apiRequests.user.getNumbersList.url}?service_type=unitedsms&term=long`,
          formData: {},
          abortControllerSignal: this.abortController.signal,
        })
          .then(res => {
            resolve();             
            this.isLoadingNumbersList = false;
            if (res?.success) {
              if (res?.data?.length > 0) {
                    // temporary
                    // res.data.push({
                    //     "id": 9832,
                    //     "tzid": "234344",
                    //     "country": "MA",
                    //     "number": "16818772477",
                    //     "status": "available",
                    //     "state": 1,
                    //     "service": "Facebook",
                    //     "service_slug": "Facebook",
                    //     "service_type": "unitedsms",
                    //     "created_at": "2024-02-26T10:13:26.000000Z",
                    //     "ltr_status": "offline",
                    //     "till_change": "2024-02-27 00:037:40",
                    //     "is_renewable": false,
                    //     "expires_at": "2024-03-27 06:13:25",
                    //     "messages": [
                    //         {
                    //             "id": 615034,
                    //             "msg": "",
                    //             "read_at": "4 hours ago"
                    //         }
                    //     ]
                    // });
                    // xxx temporary xxx
                    this.numbersList = res.data?.filter(el => {
                      // const timeDiff = new Date(el?.expires_at).getTime() - new Date();
                      // const totalMs = new Date(el?.created_at).getTime() + timeDiff;
                      // const calcedTimeByMs = totalMs - new Date().getTime();
                      // return  this.lowerString(el.status)?.trim() === 'available' && calcedTimeByMs > 0;
                      return this.lowerString(el.status)?.trim() === 'available' && el?.created_at?.slice(0,10) !== el?.expires_at?.slice(0,10) // to check if creation and expiration in not the same day
                  })?.sort((a,b) => b.id - a.id);
              }
            } else {
              this.notify({
                type: "error",
                msg: res.errors || "Failed to fetch numbers list."
              });
            }
          })
          .catch(err => {
            reject();
            this.isLoadingNumbersList = false;
            this.notify({
              type: "error",
              msg: err.errors || "Failed to fetch numbers list."
            });
          });
      });
    },
    checkNumberStatusUpdates(){
      this.isCheckingForUpdates = true;
      this.makeRequest({
        owner: this.requestOwners.user,
        method: this.apiRequests.user.checkNumberStatusUpdates.method,
        authRequired: this.isUserLoggedIn,
        url: this.apiRequests.user.checkNumberStatusUpdates.url,
        abortControllerSignal: this.abortController.signal,
      })
        .then(res => {
          this.isCheckingForUpdates = false;
          if(res?.data?.is_updated){
            this.getNumbersList();
          }
        })
        .catch(err => {
          this.isCheckingForUpdates = false;
          this.notify({
            msg: err.errors || "Failed to get balance",
            type: "error"
          });
        });
    },
    stopTimer_sIntervalForNumberUpdates(){
      if(this.gettingNumberUpdatesInterval && this.gettingNumberUpdatesInterval > 0){
          window.clearInterval(this.gettingNumberUpdatesInterval);
          this.gettingNumberUpdatesInterval = undefined;
      }
    },
    startCounterForNumberUpdates() {
      if(!this.isUserLoggedIn){
        return;
      }
      const minutesToUpdateBy = 3;
      this.stopTimer_sIntervalForNumberUpdates();
      this.gettingNumberUpdatesInterval = setInterval(() => {
        this.checkNumberStatusUpdates();
      }, (minutesToUpdateBy * 6000));
    },
    changePeriod(chartBy) {
      this.requests_count_period = chartBy || "month";
    },
    changeErrorCaptured(newState) {
      this.errorCaptured = newState;
    },
    changeShowingContainerOnMobileState(newBool) {
      this.showBuyNumberContainerOnMobile =
        typeof newBool === "boolean"
          ? newBool
          : !this.showBuyNumberContainerOnMobile;
    },
    onAddingNumberFormSubmission({ selectedCountry, selectedService, selectedPeriodCount, selectedPeriodTime }) {
  let currentCountry = selectedCountry?.id
      return new Promise((resolve, reject) => {     
        if( !selectedService ){
          return reject();
        }

        if ((+this.userBalance || 0) <= 0) {
          this.notify({
            type: "error",
            msg: `You don't have enough credit to proceed`
          });
          return reject();
        }
        if (!selectedCountry?.id) {
          // this.notify({
          //   type: "error",
          //   msg: `You must select a country.`
          // });
          // reject();
          // return;
          currentCountry = null
        }
        if (!selectedService?.id) {
          this.notify({
            type: "error",
            msg: `You must select a service.`
          });
          reject();
          return;
        }

        this.isAddingNumber = true;
        // on adding new number
        // const codedServiceLabel = window.btoa(unescape(encodeURIComponent(selectedService)));
        // const formToSend = new FormData();
        // formToSend.append("service", selectedService.id);
        // formToSend.append("country", selectedCountry.id);
        // formToSend.append("period", selectedPeriodTime);
        // formToSend.append("count", selectedPeriodCount);


        this.makeRequest({
          owner: this.requestOwners.user,
          method: this.apiRequests.user.generateNumber.method,
          authRequired: true,
          url: `${this.apiRequests.user.generateNumber.url}?service_type=unitedsms&term=long&country=${currentCountry }&service=${selectedService.id}`,
          abortControllerSignal: this.abortController.signal,
        })
          .then(res => {
            this.isAddingNumber = false;
            if (res?.success) {
              resolve();
              this.getNumbersList();
              this.updateUserBalance();
              this.errorCaptured = "";
              this.notify({
                type: "success",
                msg: res?.message || "Done successfully"
              });
            } else {
              reject();
                   this.notify({
                type: "error",
                msg:  res?.errors ||"Failed to generate new number. Please try again later"
              });
              this.errorCaptured =
                res?.errors || "Failed to generate new number...";
            }
          })
          .catch(err => {
                this.notify({
                type: "error",
                msg:  err?.errors ||"Failed to generate new number. Please try again later"
              });
            reject();
            
            this.isAddingNumber = false;
            this.errorCaptured = "Failed to generate new number. Please try again later";
          });
      });
    }
  },
  mounted() {
    if(this.isUserLoggedIn){
      this.getNumbersList();     
      // this.getProfitPercentage();
      this.startCounterForNumberUpdates();
    }
  },
  beforeDestroy(){
    this.abortController.abort();
    this.stopTimer_sIntervalForNumberUpdates();
  }
};
</script>
  
  <style lang="scss" scoped>
#rentNumber {
  padding-left: 2rem;
  padding-right: 2rem;
  @media only screen and (max-width: 680px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
div.loading--box.loading--box {
  // overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 6;
}
</style>
  