<template>
  <!-- ADMIN LAYOUT -->
  <div class="admin--layout">
    <div class="main-wrapper flex-column">
      <!-- Header -->
      <header class="admin--header">
        <nav class="navbar flex-row bg-orange-500 text-white align-items-center flex-nowrap justify-content-start">
          <div class="sidebar-toggler" @click="togglerClick">
            <v-icon class="text-white">mdi-menu</v-icon>
          </div>
          <div class="flex-row w-full px-2 flex-nowrap align-items-center justify-content-between">
            <p class="ml-4 mb-0" style="font-size: 24px; white-space:nowrap;">
              <span class="text-inherit font-[900]">Fox Sims</span>
            </p>
            <button @click="() => toggleTheme()" class="theme--toggler">
              <v-icon v-if="getLocalStorage && getLocalStorage.isDarkmode" class="light__mode__icon">mdi-white-balance-sunny</v-icon>
              <v-icon v-else class="dark__mode__icon">mdi-moon-waning-crescent</v-icon>
            </button>
          </div>

        </nav>
       
        <!-- sidebar -->
        <nav class="sidebar">
          <div class="sidebar-header">
            <router-link exact to="/" class="sidebar-brand">
              <img width="70" src="../../assets/images/main-imgs/logo.svg" />
            </router-link>

            <div class="sidebar-toggler active barToggler" @click="togglerClick">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div class="sidebar-toggler not-actived-none xToggler d-none" @click="togglerClick">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div class="sidebar-body">
            <ul class="nav">
              <!---------------->
              <!--- MAIN ---->
              <!---------------->
              <li class="nav-item nav-category">Main</li>
              <div class v-for="(menu, index) in menus.main" :key="'main' + index">
                <SidebarItem
                  :link="menu.link"
                  :icon="menu.icon"
                  :text="menu.text"
                  :counter="menu.counter"
                  :isActive="menu.link === routesNames.admin.faqCategories ? $router.history.current.path.includes(routesNames.admin.faqCategories) : $router.history.current.path === menu.link"
                  :closeSidebar="closeSidebar"
                />
              </div>

              <!----------------->
              <!--- SETTINGS ---->
              <!----------------->
              
              <div class="mt-4" v-for="(menu, index) in menus.settings" :key="'settings' + index">
                <SidebarItem
                  :link="menu.link"
                  :icon="menu.icon"
                  :text="menu.text"
                  :isActive="$router.history.current.path === menu.link"
                  :closeSidebar="closeSidebar"
                />
              </div>

              <!-- LOGOUT -->
              <li @click="() => onLogout()" class="nav-item">
                <div class="nav-link" title="Logout">
                  <v-icon class="link-icon" size="20">mdi-logout</v-icon>
                  <i class="link-icon" data-feather="log-out"></i>
                  <span class="link-title">Logout</span>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <div class="page-wrapper">
        <div class="page-content margin-lg">
          <!-- different views -->
          <v-main id="adminView">
            <transition name="fade" mode="out-in">
              <v-theme-provider root>
                  <router-view></router-view>
              </v-theme-provider>
            
            </transition>
          </v-main>
        </div>
        <!-- footer -->
        <AdminFooter />
      </div>
    </div>
  </div>
</template>

<script>
import "../../assets/demo_1.css";
import SidebarItem from "@/components/Admin/Sidebar/SidebarItem.vue";
import AdminFooter from "@/components/Admin/Footer/Footer";
import { routesNames } from "../../utilities/basicData";
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "AdminLayout",
  components: {
    SidebarItem,
    AdminFooter
  },
  computed: {
    ...mapGetters("main", {payPalOrdersCount: "getPaypalRequestsCount"}),
    menus() {
      return {
            main: [
            {
              link: routesNames.admin.dashboard,
              icon: "view-dashboard",
              text: "Dashboard"
            },
            {
              link: routesNames.admin.users,
              icon: "account-group",
              text: "Manage Users"
            },
            {
              link: routesNames.admin.instructions,
              icon: "package-variant",
              text: "Manage How it works"
            },
            {
              link: routesNames.admin.feedback,
              icon: "message-alert",
              text: "Customer Feedback"
            },
            {
              link: routesNames.admin.faqCategories,
              icon: "frequently-asked-questions",
              text: "Frequently Asked questions"
            },
            {
              link: routesNames.admin.admins,
              icon: "account-star",
              text: "Manage Admins"
            },
            {
              link: routesNames.admin.paypalRequests,
              icon: "credit-card-multiple",
              text: "PayPal Requests",
              counter: this.payPalOrdersCount
            },
          ],
          settings: [
              {
                link: routesNames.admin.settings,
                icon: "cog",
                text: "Settings"
              },
            ] 
        }
      }
  },
  data() {
    return {
      paypalRequestsInterval: undefined,
      recallPaypalRequestsApiEvery: 20000,
    };
  },
  methods: {
    ...mapActions("main", ["mutateKeys", "updatePaypalRequestsCount"]),   
    stopPaypalInterval(){
      if(this.paypalRequestsInterval && this.paypalRequestsInterval > 0){
        window.clearInterval(this.paypalRequestsInterval);
        this.paypalRequestsInterval = undefined;  
      }
    },
    runPaypalRequestsChecker() {
      this.makeRequest({
            owner: this.requestOwners.admin,
            authRequired: true,
            method: this.apiRequests.admin.checkPaypalRequestsCount.method,
            url: this.apiRequests.admin.checkPaypalRequestsCount.url,
          })
            .then(res => {
              if (res?.success) {
                // succeeded
                if(res.data?.hasOwnProperty("payPalOrdersCount")){
                    this.updatePaypalRequestsCount(+res.data?.payPalOrdersCount?.toLocaleString());
                }else{
                   this.stopPaypalInterval();
                }
              }
            })
    },

    onLogoutConfirmation() {
      this.mutateKeys({key: "isLoadingGlobally", val: true});
      this.makeRequest({
            owner: this.requestOwners.admin,
            authRequired: true,
            method: this.apiRequests.admin.logout.method,
            url: this.apiRequests.admin.logout.url,
          })
            .then(res => {
              this.mutateKeys({key: "isLoadingGlobally", val: false});
              // failed
              if (!res?.success) {
                this.notify({
                  type: "error",
                  msg: res.message
                });
              } else {
                // succeeded

                this.updateMemory({
                  type: "update",
                  newObject: {},
                  destination: this.requestOwners.admin
                });

                this.notify({
                  type: "success",
                  msg: res.message
                });

                this.$router.push({path: routesNames.admin.login});
              }
            })
            .catch(() => (this.mutateKeys({key: "isLoadingGlobally", val: false})));
    },
    onLogout() {
      this.confirmDialog({
        msg: `Logout?`,
        buttonLabels: { no: "No", yes: "Yes" },
        confirmedFunc: () => {
          this.onLogoutConfirmation();
        }
      });
    },
    togglerClick(e) {
      if (window.matchMedia("(min-width: 992px)").matches) {
        $("body").toggleClass("sidebar-folded");
        if ($("body").hasClass("sidebar-folded")) {
          // show x toggler
          $(".xToggler").removeClass("d-none");
          $(".barToggler").addClass("d-none");
          $("body").removeClass("sidebar-responsive");
        } else {
          $(".xToggler").addClass("d-none");
          $(".barToggler").removeClass("d-none");
          $("body").addClass("sidebar-responsive");
        }
      } else if (window.matchMedia("(max-width: 991px)").matches) {
        $("body").toggleClass("sidebar-open");
      }
    },
    closeSidebar() {
      $("body").removeClass("sidebar-open");
    }
  },
  mounted(){
    this.stopPaypalInterval();
    this.paypalRequestsInterval = setInterval(() => {
      this.runPaypalRequestsChecker();
    }, this.recallPaypalRequestsApiEvery);
  },
  beforeDestroy() {
    this.stopPaypalInterval();
  }
};
</script>

<style lang="scss" scoped>
.admin--layout {
  .theme--toggler{
    border: none;
    background: transparent;
    padding: 0.2rem 1rem;
    border-radius: 0.4rem;
    .light__mode__icon{
      color: rgb(235, 222, 224);
    }
    .dark__mode__icon{
      color: rgb(233, 234, 234);
    }
  }
  .link-title {
    text-transform: uppercase;
  }
  .sidebar-brand{
    img {
      width: 60px;
      height: auto;
      object-fit: contain;
    }
  }
  .admin--header {
    position: sticky;
    top: 0;
    left: 0;
    padding: 0;
    border-bottom: 1px solid var(--quaternary-clr);
    min-height: var(--admin-header);
    z-index: var(--z-header);
  }
}
</style>