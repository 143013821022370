export const localStorageBasicData = Object.freeze({
	cart: [],
	language: 'en',
	user: {},
	admin: {},
	auth: {},
    siteSettings: {},
    isDarkmode: true
});

// methods names
const httpMethods = {
	post: 'POST',
	get: 'GET',
	delete: 'DELETE',
	put: 'PUT',
};

export const routesNames = Object.freeze({
	//---- ADMIN ----
	admin: {
		login: '/admin/login',
		dashboard: '/admin',
		users: '/admin/users',
		admins: '/admin/admins',
        instructions: '/admin/instructions',
        settings: "/admin/settings",
        paypalRequests: "/admin/paypalRequests",
        feedback: "/admin/feedback",
        faqCategories: "/admin/frequently_asked_questions",
	},
	//---- MAIN ----
	user: {
		login: '/login',
		homepage: '/',
		signup: '/signup',
		forgotPassword: '/forgot_password',
		resetPassword: '/reset_password',
		dashboard: '/dashboard',
        contact: '/contact_us',
        terms: "/terms_of_service",
        privacy: "/privacy_policy",
        messages: "/dashboard/messages",
        billing: "/dashboard/billing",
        rentNumber: "/dashboard/rentNumber",
	},
	errors: {
		_400: '/400',
		_403: '/403',
		_404: '/404',
		_500: '/500',
		_503: '/503',
	},
});
// requests owners
export const requestOwners = {
	user: 'user',
	admin: 'admin',
};
// host
// "https://back-end.foxsims.com"
export const productionUrl = "https://back-end.foxsims.com";
export const developmentUrl = "https://back-end.foxsims.com";

export let hostUrl = process.env.NODE_ENV === "development" ? developmentUrl : productionUrl;
// api requests
const rootRequest = {
	admin: `${hostUrl}/api/admin`,
	user: `${hostUrl}/api`,
};
export const apiRequests = Object.freeze({
    admin: {
        login: {url: `${rootRequest.admin}/auth/login`, method: httpMethods.post},
        logout: {url: `${rootRequest.admin}/logout`, method: httpMethods.post},
        getAdminsList: {url: `${rootRequest.admin}/admins`, method: httpMethods.get},
        editAdminsItem: {url: `${rootRequest.admin}/admins`, method: httpMethods.put},
        deleteAdminsItem: {url: `${rootRequest.admin}/admins`, method: httpMethods.delete},
        addAdminsItem: {url: `${rootRequest.admin}/admins`, method: httpMethods.post},
        getUsersList: {url: `${rootRequest.admin}/users`, method: httpMethods.get},
        editUsersItem: {url: `${rootRequest.admin}/users`, method: httpMethods.put},
        getUserInvoices: {url: `${rootRequest.admin}/invoices/user`, method: httpMethods.post},
        deleteUsersItem: {url: `${rootRequest.admin}/users`, method: httpMethods.delete},
        addUsersItem: {url: `${rootRequest.admin}/users`, method: httpMethods.post},
        chanageUserCredit: {url: `${rootRequest.admin}/user/balance`, method: httpMethods.put},
        getUsersMessage: {url: `${rootRequest.admin}/getUserSms`, method: httpMethods.get},
        getPackages: {url: `${rootRequest.admin}/packages`, method: httpMethods.get},
        editPackage: {url: `${rootRequest.admin}/packages`, method: httpMethods.put},
        deletePackage: {url: `${rootRequest.admin}/packages`, method: httpMethods.delete},
        addPackage: {url: `${rootRequest.admin}/packages`, method: httpMethods.post},
        getDashboardData: {url: `${rootRequest.admin}/dashboard`, method: httpMethods.get},
        getDashboardCharts: {url: `${rootRequest.admin}/chart`, method: httpMethods.get},
        getWebsiteData: {url: `${rootRequest.admin}/site_data`, method: httpMethods.get},
        updateWebsiteData: {url: `${rootRequest.admin}/site_data`, method: httpMethods.post},
        getBalance: {url: `${rootRequest.admin}/getBalance`, method: httpMethods.get},
        getPaypalRequests: {url: `${rootRequest.admin}/orders/payPal`, method: httpMethods.get},
        confirmPaypalRequest: {url: `${rootRequest.admin}/order/status/transition_to_complete`, method: httpMethods.put},
        checkPaypalRequestsCount: {url: `${rootRequest.admin}/orders/unread_payPal_count`, method: httpMethods.get},
        deleteRequest: {url: `${rootRequest.admin}/order`, method: httpMethods.delete},
        // Feedbacks
        getFeedbacks: {url: `${rootRequest.admin}/feedback`, method: httpMethods.get},
        addFeedback: {url: `${rootRequest.admin}/feedback`, method: httpMethods.post},
        editFeedback: {url: `${rootRequest.admin}/feedback`, method: httpMethods.post},
        deleteFeedback: {url: `${rootRequest.admin}/feedback`, method: httpMethods.delete},
        // FAQ Categories
        getFAQCategories: {url: `${rootRequest.admin}/category`, method: httpMethods.get},
        addFAQCategory: {url: `${rootRequest.admin}/category`, method: httpMethods.post},
        editFAQCategory: {url: `${rootRequest.admin}/category`, method: httpMethods.put},
        deleteFAQCategory: {url: `${rootRequest.admin}/category`, method: httpMethods.delete},
        // FAQ Questions & Answers
        getFAQs: {url: `${rootRequest.admin}/faq`, method: httpMethods.get},
        addFAQ: {url: `${rootRequest.admin}/faq`, method: httpMethods.post},
        editFAQ: {url: `${rootRequest.admin}/faq`, method: httpMethods.put},
        deleteFAQ: {url: `${rootRequest.admin}/faq`, method: httpMethods.delete},
        // Instructions
        getInstructions: {url: `${rootRequest.admin}/onboarding`, method: httpMethods.get},
        addInstruction: {url: `${rootRequest.admin}/onboarding`, method: httpMethods.post},
        editInstruction: {url: `${rootRequest.admin}/onboarding`, method: httpMethods.post},
        deleteInstruction: {url: `${rootRequest.admin}/onboarding`, method: httpMethods.delete},
    },
    user: {
        login: {url: `${rootRequest.user}/auth/login`, method: httpMethods.post},
        logout: {url: `${rootRequest.user}/logout`, method: httpMethods.post},
        signup: {url: `${rootRequest.user}/auth/register`, method: httpMethods.post},
        forgotPassword: {url: `${rootRequest.user}/forgot`, method: httpMethods.post},
        resetPassword: {url: `${rootRequest.user}/reset-password`, method: httpMethods.post},
        countriesList: {url: `${rootRequest.user}/countries`, method: httpMethods.get},
        websiteData: {url: `${rootRequest.user}/site_data`, method: httpMethods.get},
        contactUs: {url: `${rootRequest.user}/contactUs`, method: httpMethods.post},
        setEmailNotification: {url: `${rootRequest.user}/phoneNumbers/setEmail`, method: httpMethods.get},
        getNumbersList: {url: `${rootRequest.user}/phoneNumbers/list`, method: httpMethods.get},
        getMessagesList: {url: `${rootRequest.user}/getSms`, method: httpMethods.get},
		getBillingsList: {url: `${rootRequest.user}/billings`, method: httpMethods.get},
		addCredit: {url: `${rootRequest.user}/order/store`, method: httpMethods.post},
		stripPayment: {url: `${rootRequest.user}/create-payment-intent`, method: httpMethods.post},
        getUserDashboard: {url: `${rootRequest.user}/home`, method: httpMethods.get},
        getRentNumbers: {url: `${rootRequest.user}/phoneNumbers/rented`, method: httpMethods.get},
        editRentNumberLabel: {url: `${rootRequest.user}/phoneNumbers/label`, method: httpMethods.post},
        getUserHomeChart: {url: `${rootRequest.user}/chart`, method: httpMethods.get},
        getServices: {url: `${rootRequest.user}/getNumberStats`, method: httpMethods.get},
        generateNumber: {url: `${rootRequest.user}/generateNumber`, method: httpMethods.post},
        getNewMessages: {url: `${rootRequest.user}/newMessages`, method: httpMethods.get},
        deleteMessage: {url: `${rootRequest.user}/messages`, method: httpMethods.post},
        getProfile: {url: `${rootRequest.user}/user`, method: httpMethods.get},
        balanceUpdate: {url: `${rootRequest.user}/userBalanceUpdate`, method: httpMethods.put},
        autoRenew: {url: `${rootRequest.user}/phoneNumbers`, method: httpMethods.put},
        checkNumberStatusUpdates: {url: `${rootRequest.user}/phoneNumbers/ltr_status/check`, method: httpMethods.get},
        getCountriesList: {url: `${rootRequest.user}/getCountries`, method: httpMethods.get},
        getSettings: {url: `${rootRequest.user}/settings`, method: httpMethods.get},
        reactivateNumber: {url: `${rootRequest.user}/phoneNumbers/activate`, method: httpMethods.get},
        contact_us: {url: `${rootRequest.user}/contactUs`, method: httpMethods.post},
    }
});

// MESSAGE RESPONSES

export const messageResponses = {
    success: "TZ_NUM_ANSWER"
}

export const countriesIDs = [
    { country: "Russia", id: 7 },
    { country: "Ukraine", id: 380 },
    { country: "Germany", id: 49 },
    { country: "United Kingdom", id: 44 },
    { country: "France", id: 33 },
    { country: "Spain", id: 34 },
    { country: "Sweden", id: 46 },
    { country: "Netherlands", id: 31 },
    { id: 359, country: "Bulgaria" },
    { id: 212, country: "Morocco" },
    { id: 91, country: "India" },
    { id: 45, country: "Denmark" },
    { id: 351, country: "Portugal" },
    { id: 357, country: "Cyprus" },
    { id: 1000, country: "Canada" },
    { id: 1, country: "United States" },
    { id: 420, country: "Czech Republic" },
    { id: 48, country: "Poland" },
    { id: 40, country: "Romania" },
    { id: 62, country: "Indonesia" },
    { id: 63, country: "Philippines" },
    { id: 998, country: "Uzbekistan" },
    { id: 84, country: "Vietnam" },
    { id: 371, country: "Latvia" },
    { id: 370, country: "Lithuania" },
    { id: 372, country: "Estonia" },
    { id: 381, country: "Serbia" },
    { id: 373, country: "Moldova" },
    { id: 995, country: "Georgia" },
    { id: 77, country: "Kazakhstan" },
    { id: 55, country: "Brazil" },
    { id: 52, country: "Mexico" },
    { id: 54, country: "Argentina" },
    { id: 90, country: "Turkey" },
    { id: 20, country: "Egypt" },
    { id: 972, country: "Israel" },
    { id: 967, country: "Yemen" },
    { id: 852, country: "Hong Kong" },
    { id: 234, country: "Nigeria" },
    { id: 353, country: "Ireland" },
    { id: 225, country: "Ivory Coast" },
    { id: 233, country: "Ghana" },
    { id: 232, country: "Sierre Lyone" },
    { id: 254, country: "Kenya" },
    { id: 509, country: "Haiti" },
    { id: 886, country: "Taiwan" },
    { id: 58, country: "Venezuela" },
    { id: 57, country: "Colombia" },
    { id: 60, country: "Malaysia" },
    { id: 504, country: "Honduras" },
    { id: 92, country: "Pakistan" },
    { id: 226, country: "Burkina Faso" },
    { id: 220, country: "Gambia" },
    { id: 992, country: "Tajikistan" },
    { id: 964, country: "Iraq" },
    { id: 98, country: "Iran" },
    { id: 994, country: "Azerbaijan" },
    { id: 93, country: "Afghanistan" },
    { id: 591, country: "Bolivia" },
    { id: 231, country: "Liberia" },
    { id: 94, country: "Sri Lanka" },
    { id: 976, country: "Mongolia" },
    { id: 977, country: "Nepal" },
    { id: 223, country: "Mali" },
    { id: 235, country: "Chad" },
    { id: 358, country: "Finland" },
    { id: 30, country: "Greece" },
    { id: 65, country: "Singapore" },
    { id: 375, country: "Belorussia" },
    { id: 32, country: "Belgium" },
    { id: 237, country: "Cameroon" },
    { id: 39, country: "Italy" },
    { id: 593, country: "Ecuador" },
    { id: 966, country: "Saudi Arabia" },
    { id: 41, country: "Switzerland" },
    { id: 66, country: "Thailand" },
    { id: 962, country: "Jordan" },
    { id: 389, country: "North Macedonia" },
    { id: 51, country: "Peru" },
    { id: 505, country: "Nicaragua" },
    { id: 249, country: "Sudan" },
    { id: 503, country: "Salvador" },
    { id: 256, country: "Uganda" },
    { id: 260, country: "Zambia" },
    { id: 265, country: "Malawi" },
    { id: 245, country: "Guinea Bissau" },
    { id: 222, country: "Mauritania" },
    { id: 216, country: "Tunisia" },
    { id: 228, country: "Togo" },
    { id: 221, country: "Senegal" },
    { id: 43, country: "Austria" },
    { id: 385, country: "Croatia" },
    { id: 86, country: "China" },
]

export const countriesAlpha2 = {
    "AF": "Afghanistan",
    "AX": "Aland Islands",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua And Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BA": "Bosnia And Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "CV": "Cape Verde",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo",
    "CD": "Congo, Democratic Republic",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "CI": "Cote D\"Ivoire",
    "HR": "Croatia",
    "CU": "Cuba",
    "CY": "Cyprus",
    "CZ": "Czech Republic",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (Malvinas)",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island & Mcdonald Islands",
    "VA": "Holy See (Vatican City State)",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle Of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KR": "Korea",
    "KP": "North Korea",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People\"s Democratic Republic",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libyan Arab Jamahiriya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MK": "North Macedonia",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia, Federated States Of",
    "MD": "Moldova",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "AN": "Netherlands Antilles",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestinian Territory, Occupied",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Reunion",
    "RO": "Romania",
    "RU": "Russia",
    "RW": "Rwanda",
    "BL": "Saint Barthelemy",
    "SH": "Saint Helena",
    "KN": "Saint Kitts And Nevis",
    "LC": "Saint Lucia",
    "MF": "Saint Martin",
    "PM": "Saint Pierre And Miquelon",
    "VC": "Saint Vincent And Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome And Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierre Lyone",
    "SG": "Singapore",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia And Sandwich Isl.",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard And Jan Mayen",
    "SZ": "Swaziland",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan",
    "TJ": "Tajikistan",
    "TZ": "Tanzania",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad And Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks And Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "GB": "United Kingdom",
    "US": "United States",
    "UM": "United States Outlying Islands",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "VN": "Vietnam",
    "VG": "Virgin Islands, British",
    "VI": "Virgin Islands, U.S.",
    "WF": "Wallis And Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
  }
