<template>
  <v-card id="rentPeriodSelectionCard">
    <v-card-title>
      <span class="text-h6 mt-2">Select rental period</span>
    </v-card-title>
    <v-row class="w-full px-2 mx-0 mt-1 mb-0 flex-row justify-content-center">
      <!-- period selection -->
      <v-col cols="12" md="6" lg="6" sm="12">
        <v-select
          :value="selectedPeriodCount"
          @input="$emit('update:selectedPeriodCount', $event)"
          :disabled="isAddingNumber"
          :label="countInputLabel"
          :items="[{ text: 'Select count', value: '00', disabled: true}, ...Array.from({length: this.maxCount}).map((_, index) => {
                    const modifiedIndex = index +1;
                    return {
                        text: modifiedIndex.toString(),
                        value: modifiedIndex
                    }
                })]"
          item-disabled="disabled"
        />
      </v-col>

      <v-col cols="12" md="6" lg="6" sm="12">
        <v-select
          :disabled="isAddingNumber"
          :value="selectedPeriodTime"
          @input="$emit('update:selectedPeriodTime', $event)"
          label="Duration"
          :items="periodsOptions"
          item-disabled="disabled"
        />
      </v-col>
    </v-row>
    <!-- total amount -->
    <v-row :class="`w-full px-2 mx-0 flex-row justify-content-center ${isAddingNumber ? 'disabled' : ''}`.trim()">
      <v-col cols="12" md="12" lg="12" sm="12">
        <div
          v-if="(selectedPeriodTime && selectedPeriodCount) && (selectedPeriodTime != '00' && selectedPeriodCount != '00')"
          class="total_amount"
        >
          <!-- <div>
            Selected service:
            <strong>{{ selectedService.name }}</strong>
          </div> -->
          <div>
            For:
            <strong>{{ selectedPeriodCount }} {{ " " }} {{ selectedPeriodTime }}{{ typeof selectedPeriodCount === 'number' && selectedPeriodCount > 1 ? 's' : '' }} ({{ daysCount }} days)</strong>
          </div>
          <div v-if="(typeof totalAmount === 'number' || typeof totalAmount === 'string')" class="mt-1">
            Total amount: {{  " " }}
            <strong>${{ Math.round(totalAmount * 100) / 100 }}</strong>
          </div>
        </div>
      </v-col>
    </v-row>
    
    <div v-if="!isMobile">
        <!-- loading animation -->
        <div v-if="isAddingNumber" class="loader--container">
            <span class="adding__number__loader"></span>
        </div>
        <!-- error captured -->
        <div
        v-else-if="errorCaptured"
        class="error--container fadeEffect alert alert-danger text-center mt-6 mx-5"
        >
        <p class="text-danger flex-row align-center m-0">
            <v-icon class="mr-2 text-danger">mdi-alert</v-icon>
            <span>{{errorCaptured}}</span>
        </p>
        </div>   
    </div>

    <!-- action buttons -->
    <v-card-actions v-if="isMobile" class="mt-2">
      <v-spacer></v-spacer>

      <v-btn
        :class="isConfirmationBtnDisabled ? 'disabled': ''"
        color="orange darken-1"
        text
        @click="() => $emit('increaseStepsOnMobile')"
      >Confirm</v-btn>
    </v-card-actions>
    <v-card-actions v-else class="mt-2">
      <v-spacer></v-spacer>
      <v-btn
        :disabled="isAddingNumber"
        color="blue darken-1"
        text
        @click="() => $emit('closeModal')"
      >Cancel</v-btn>
      <v-btn
        :class="isConfirmationBtnDisabled ? 'disabled': ''"
        color="orange darken-1"
        text
        @click="() => $emit('submitRequest', {confirmed: true, selectedPeriodCount, selectedPeriodTime})"
      >Confirm</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'RentPeriodSelectionCard',
  data() {
    return {
      maxCount: 3,
      periodsOptions: [
        { text: "Select duration", value: "00", disabled: true },
        { text: "Weeks(s)", value: "week" },
        { text: "Months(s)", value: "month" }
      ]
    };
  },
  props: {
    selectedPeriodCount: {
        type: [String, Number],
        default: null
    },
    selectedPeriodTime: {
        type: [String, Number],
        default: null
    },
    totalAmount: {
        type: [String, Number]
    },
    daysCount: {
        type: [String, Number]
    },
    selectedService: {
        type: Object,
        required: true,
        default: () => {}
    },
    errorCaptured: {
      type: String,
      required: true,
      default: ""
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false
    },
    isAddingNumber: {
        type: Boolean,
        required: false,
        default: false
    }
  },
  computed: {

    isConfirmationBtnDisabled() {
      return this.isAddingNumber || (
        !this.selectedPeriodCount ||
        this.selectedPeriodCount === "00" ||
        !this.selectedPeriodTime || this.selectedPeriodTime === "00"
      );
    },
    countInputLabel() {
      return this.selectedPeriodTime && this.selectedPeriodTime != "00"
        ? `Number of ${this.selectedPeriodTime}s`
        : "Count";
    }
  }
};
</script>

<style lang="scss" scoped>
#rentPeriodSelectionCard {
  .loader--container {
    display: grid;
    place-items: center;
    text-align: center;
    margin: 3rem 0 0.8rem 0;
    padding: 0 0.5rem;
    height: 50px;
    .adding__number__loader {
      width: 34px;
      height: 34px;
      position: relative;
      background-image: linear-gradient(var(--dark) 11px, transparent 0),
        linear-gradient(var(--primary-clr) 11px, transparent 0),
        linear-gradient(var(--primary-clr) 11px, transparent 0),
        linear-gradient(var(--dark) 11px, transparent 0);
      background-repeat: no-repeat;
      background-size: 11px 11px;
      background-position: left top, left bottom, right top, right bottom;
      animation: rotate 1s linear infinite;
    }
    @keyframes rotate {
      0% {
        width: 34px;
        height: 34px;
        transform: rotate(0deg);
      }
      50% {
        width: 20px;
        height: 20px;
        transform: rotate(180deg);
      }
      100% {
        width: 34px;
        height: 34px;
        transform: rotate(360deg);
      }
    }
  }
}
</style>