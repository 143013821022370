<template>
  <div class="bg-fontColor-1 text-white">
    <div class="app-container">
      <div class="flex justify-between flex-col pt-[20px] md:pt-[30px] pb-[30px] sm:pb-[48px] md:pb-[60px] xl:pb-[80px] 3xl:pb-[90px] gap-4 lg:gap-20 lg:flex-row">
        <div class=" flex flex-col items-center gap-3">
          <img class=" h-[70px] lg:h-[120px] xl:h-[150px] object-contain" src="../../assets/imgs/logo.svg" alt="" />
          <span class=" font-bold text-center text-[17px] md:text-[18px] lg:text-[19px] xl:text-[21px]">{{ websiteData?.siteData?.footer_title }}</span>
        </div>
        <div class="flex gap-8 md:gap-10 lg:gap-12 xl:gap-15 2xl:gap-16 3xl-xl:gap-20 3xl:gap-17 md:pt-[25px] xl:pt-[38px] flex-col items-center footer__links sm:justify-evenly sm:items-start sm:flex-row">
          <div>
            <h5 class="text-[25px] mb-[10px] lg:mb-8 xl:mb-9 2xl:mb-10 md:text[26px] lg:text-[28px] xl:text-[32px]">Quick Links</h5>
            <ul class="p-0 flex flex-col gap-1.5 !text-[#FFFFFF85] text-[18px] sm:text-start md:text-[20px] xl:text-[21px]">
              <li><RouterLink class="!text-[#FFFFFF85] hover:underline" to="/contact_us">Contact us</RouterLink></li>
              <li><RouterLink class="!text-[#FFFFFF85] hover:underline" to="/privacy_policy">Privacy policy</RouterLink></li>
              <li><RouterLink class="!text-[#FFFFFF85] hover:underline" to="terms_of_service">Terms of condition</RouterLink></li>
            </ul>
          </div>

          <div>
            <h5 class="text-[25px] mb-[10px] lg:mb-8 xl:mb-9 2xl:mb-10 md:text[26px] lg:text-[28px] xl:text-[32px] footer__links sm:text-start">Get in Touch</h5>
            <div class="flex flex-wrap gap-3 justify-center">
              <a :href="`mailto:${websiteData?.siteData?.email_link}`" class="hover:brightness-90 transition-all duration-150">
                <img src="../../assets/imgs/email.svg" class="w-[28px] h-[28px] rounded-[3px] object-cover md:w-[29px] md:h-[29px]" width="29" height="29" alt="">
              </a>
              <span class="text-[18px] lg:text-[20px] xl:text-[24px"><a :href="`mailto:${websiteData?.siteData?.email_link}`" class="hover:!text-first !text-white transition-colors duration-150">{{ websiteData?.siteData?.email_link }}</a></span>
            </div>
            <ul class="p-0 flex gap-2 pt-7 sm:pt-4 footer__links sm:justify-start">
              <li>
                <a :href="websiteData?.siteData?.twitter" target="_blank" class="hover:brightness-90 transition-all duration-150">
                  <img src="../../assets/imgs/twitter.svg" class="w-[28px] h-[28px] rounded-[3px] object-cover md:w-[29px] md:h-[29px]" width="29" height="29" alt="">
                </a>
              </li>
              <li>
                <a :href="websiteData?.siteData?.facebook" target="_blank" class="hover:brightness-90 transition-all duration-150">
                  <img src="../../assets/imgs/fb.svg" class="w-[28px] h-[28px] rounded-[3px] object-cover md:w-[29px] md:h-[29px]" width="29" height="29" alt="">
                </a>
              </li>
              <li>
                <a :href="websiteData?.siteData?.instagram" target="_blank" class="hover:brightness-90 transition-all duration-150">
                  <img src="../../assets/imgs/insta.svg" class="w-[28px] h-[28px] rounded-[3px] object-cover md:w-[29px] md:h-[29px]" width="29" height="29" alt="">
                </a>
              </li>
              <li>
                <a :href="websiteData?.siteData?.discord" target="_blank" class="hover:brightness-90 transition-all duration-150">
                  <img src="../../assets/imgs/dicord.svg" class="w-[28px] h-[28px] rounded-[3px] object-cover md:w-[29px] md:h-[29px]" width="29" height="29" alt="">
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="text-[#FFFFFF70] flex justify-center items-center text-[14px] md:text-[17px] h-[70px] border-t-[2px] border-t-[#7b7a7b24] "
      >
        © {{ currentYear }} FoxSims All rights reserved.
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState("website", ["websiteData"]),
    currentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>

<style scoped>
  @media screen and (max-width: 576px) {
    .footer__links{
      text-align: center;
      justify-content: center;
    }
  }

</style>