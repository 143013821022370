import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import HomeView from '../views/Pages/Main/Homepage.vue';
// // LAYOUTS
import MainLayout from '../views/Layouts/Main.vue';
import AdminLayout from '../views/Layouts/Admin.vue';
import UserDashboardLayout from '../views/Layouts/UserDashboard.vue';
import PublicLayout from '../views/Layouts/Public.vue';
import { locStorage } from '@/utilities/tools';
import { routesNames, requestOwners } from '../utilities/basicData';

Vue.use(VueRouter);

const isAuth = ({owner}) => {
  const lsCopy = locStorage({ type: 'get' });
  const userToken = lsCopy?.user?.token || store.getters['main/getUserToken'];
  const adminToken = lsCopy?.admin?.token || store.getters['main/getAdminToken'];
  return owner === "user" ? userToken : adminToken;
};

const isAuthenticated = ({ owner, nextFunc }) => {
  owner = typeof owner === 'string' ? owner?.toLowerCase() : '';
  const lsCopy = locStorage({ type: 'get' });
  const userToken = lsCopy?.user?.token || store.getters['main/getUserToken'];
  const adminToken = lsCopy?.admin?.token || store.getters['main/getAdminToken'];

  if (owner === requestOwners.user && !userToken) {
    nextFunc(routesNames.user.login);
    return;
  } else if (owner === requestOwners.admin && !adminToken) {
    nextFunc(routesNames.admin.login);
    return;
  }
  nextFunc();
};

const routes = [
  // Main
  {
    path: routesNames.user.homepage,
    component: MainLayout,
    children: [
      {
        path: routesNames.user.homepage,
        component: HomeView,
        name: 'homepage',
      },
      {
        path: routesNames.user.contact,
        component: () => import('@/views/Pages/Main/Contact'),
        name: 'Contactpage',
      },
      {
        path: routesNames.user.privacy,
        component: () => import('@/views/Pages/Main/PrivacyPolicy'),
        name: 'privacyPolicy',
      },
      {
        path: routesNames.user.terms,
        component: () => import('@/views/Pages/Main/Terms'),
        name: 'termsOfService',
      },
      {
        path: routesNames.user.login,
        component: () => import('@/views/Pages/Main/Auth/Login'),
        beforeEnter: (to, from, next) => {
          if (!isAuth({owner: "user"})) {
            next();
          } else {
            next({ path: routesNames.user.homepage });
          }
        },
      },
      {
        path: routesNames.user.signup,
        component: () => import('@/views/Pages/Main/Auth/SignUp'),
        beforeEnter: (to, from, next) => {
          if (!isAuth({owner: "user"})) {
            next();
          } else {
            next({ path: routesNames.user.homepage });
          }
        },
      },
      {
        path: routesNames.user.forgotPassword,
        component: () => import('@/views/Pages/Main/Auth/ForgotPassword'),
        beforeEnter: (to, from, next) => {
          if (!isAuth({owner: "user"})) {
            next();
          } else {
            next({ path: routesNames.user.homepage });
          }
        },
      },
      {
        path: routesNames.user.resetPassword,
        component: () => import('@/views/Pages/Main/Auth/ResetPassword'),
        beforeEnter: (to, from, next) => {
          if (!isAuth({owner: "user"})) {
            next();
          } else {
            next({ path: routesNames.user.homepage });
          }
        },
      },
    ],
  },
  // Admin
  {
    component: AdminLayout,
    path: routesNames.admin.dashboard,
    children: [
      {
        path: '/',
        name: 'adminHomepage',
        component: () => import('@/views/Pages/Admin/Dashboard.vue'),
        beforeEnter: (to, from, next) => isAuthenticated({ owner: requestOwners.admin, nextFunc: next }),
      },
      {
        path: routesNames.admin.instructions,
        name: 'instructionsPages',
        component: () => import('@/views/Pages/Admin/ManageInstructions.vue'),
        beforeEnter: (to, from, next) => isAuthenticated({ owner: requestOwners.admin, nextFunc: next }),
      },
      {
        path: routesNames.admin.feedback,
        name: 'feedbackPages',
        component: () => import('@/views/Pages/Admin/Feedback.vue'),
        beforeEnter: (to, from, next) => isAuthenticated({ owner: requestOwners.admin, nextFunc: next }),
      },
      {
        path: routesNames.admin.faqCategories,
        name: 'faqCategoriesPages',
        component: () => import('@/views/Pages/Admin/FAQCategories.vue'),
        beforeEnter: (to, from, next) => isAuthenticated({ owner: requestOwners.admin, nextFunc: next }),
      },
      {
        path: `${routesNames.admin.faqCategories}/:id`,
        name: 'faqCategoriesPages',
        component: () => import('@/views/Pages/Admin/FAQCategoryQuestions.vue'),
        beforeEnter: (to, from, next) => isAuthenticated({ owner: requestOwners.admin, nextFunc: next }),
      },
      {
        path: routesNames.admin.users,
        name: 'usersPage',
        component: () => import('@/views/Pages/Admin/Users.vue'),
        beforeEnter: (to, from, next) => isAuthenticated({ owner: requestOwners.admin, nextFunc: next }),
      },
      {
        path: routesNames.admin.settings,
        name: 'settingsPage',
        component: () => import('@/views/Pages/Admin/Settings'),
        beforeEnter: (to, from, next) => isAuthenticated({ owner: requestOwners.admin, nextFunc: next }),
      },
      {
        path: routesNames.admin.admins,
        name: 'adminsPage',
        component: () => import('@/views/Pages/Admin/Admins'),
        beforeEnter: (to, from, next) => isAuthenticated({ owner: requestOwners.admin, nextFunc: next }),
      },
      {
        path: routesNames.admin.paypalRequests,
        name: 'paypalRequestsPage',
        component: () => import('@/views/Pages/Admin/PaypalRequests'),
        beforeEnter: (to, from, next) => isAuthenticated({ owner: requestOwners.admin, nextFunc: next }),
      },
    ],
  },
  {
    component: () => import('@/views/Pages/Admin/Login'),
    path: '/admin/login',
    beforeEnter: (to, from, next) => {
      if (!isAuth({owner: "admin"})) {
        next();
      } else {
        next({ path: routesNames.admin.dashboard });
      }
    },
  },

  // Main Website Routes

  {
    component: UserDashboardLayout,
    path: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'userDashboardHome',
        component: () => import('@/views/Pages/Main/Dashboard/Dashboard.vue'),
        beforeEnter: (to, from, next) => isAuthenticated({ owner: requestOwners.user, nextFunc: next }),
      },
      {
        path: routesNames.user.messages,
        name: 'userDashboardMessages',
        component: () => import('@/views/Pages/Main/Dashboard/Messages.vue'),
        beforeEnter: (to, from, next) => isAuthenticated({ owner: requestOwners.user, nextFunc: next }),
      },
      {
        path: routesNames.user.billing,
        name: 'userDashboardBilling',
        component: () => import('@/views/Pages/Main/Dashboard/Billing.vue'),
        beforeEnter: (to, from, next) => isAuthenticated({ owner: requestOwners.user, nextFunc: next }),
      },
      {
        path: routesNames.user.rentNumber,
        name: 'userDashboardRentNumber',
        component: () => import('@/views/Pages/Main/Dashboard/RentNumber.vue'),
        beforeEnter: (to, from, next) => isAuthenticated({ owner: requestOwners.user, nextFunc: next }),
      },
    ],
  },

  // Public layout - without layouts
  {
    component: PublicLayout,
    path: '/login',
    children: [
      {
        path: '',
        name: 'testpage',
        component: () => import('@/views/Pages/Public/PublicTest.vue'),
      },
    ],
  },

  {
    component: () => import('@/views/Pages/PageNotFound.vue'),
    path: '*',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, _, savedPosition) {
    if (to.hash) {
        const element = document.getElementById(to.hash.replace(/#/, ''));
        if (element && element.scrollIntoView) {
            element.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      return {
        el: to.hash,
      };
    }else if(savedPosition){
      // when going back to a previous page, scroll to the exact same position they were in
      return savedPosition;
    }else{
      // otherwise, when opening any page, scroll them to the very top
      return { top: 0, left: 0 };
    }
  },
});
// router.beforeEach((to, _, next) => {
//   window.scrollTo({
//     top: 0,
//     left: 0,
//     behavior: 'auto',
//   });
//   next();
// });
export default router;
