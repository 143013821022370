<template>
  <div>
    <div v-if="isLoadingInfo" class="fade--effect section--loading">
      <span />
    </div>
    <div class="profile--container flex-column">
      <div class="edit--state--toggler flex-row">
        <span @click="() => isEditMode =! isEditMode">
          <v-icon v-if="!isEditMode">mdi-pencil-outline</v-icon>
          <v-icon v-else>mdi-information-outline</v-icon>
        </span>
      </div>
      <div v-if="!isEditMode" class="profile--inner flex-column">
        <p>
          <strong v-show="userInfo.name">Username: {{" "}}</strong>
          <span>{{userInfo.name}}</span>
        </p>
        <p>
          <strong v-show="userInfo.email">Email: {{" "}}</strong>
          <span>{{userInfo.email}}</span>
        </p>
      </div>
      <div v-else>
        <v-text-field v-model="formData.name" label="Username" value="Username"></v-text-field>
        <v-text-field
          v-model="formData.email"
          :autofocus="true"
          label="Email address"
          value="example"
          suffix="@gmail.com"
        ></v-text-field>
        <v-text-field
          v-model="formData.password"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPass ? 'text' : 'password'"
          name="password"
          label="Password"
          hint="At least 8 characters"
          counter
          @click:append="showPass = !showPass"
        ></v-text-field>
        <v-text-field
          v-model="formData.password_confirmation"
          :append-icon="showConfirmationPass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showConfirmationPass ? 'text' : 'password'"
          name="password-confirmation"
          label="Confirm Password"
          hint="At least 8 characters"
          counter
          @click:append="showConfirmationPass = !showConfirmationPass"
        ></v-text-field>
        <div class="mt-2" v-show="receivedError">
          <p class="text-danger">{{receivedError}}</p>
        </div>

        <div class="submit-btn">
          <button @click="() => saveChanges()" :class="`${isSubmissionBtnDisabled ? 'disabled': ''}`">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoadingInfo: false,
      userInfo: {},
      showPass: false,
      showConfirmationPass: false,
      isEditMode: false,
      receivedError: "",
      formData: {
        name: "",
        email: "",
        password: "",
        password_confirmation: ""
      },
      isSavingChanges: false
    };
  },
  computed: {
    isSubmissionBtnDisabled() {
      return (
        this.isLoadingInfo || this.isSavingChanges ||
        !this.formData.email ||
          (this.formData.password ? !this.formData.password_confirmation : false) || !this.formData.name
      );
    }
  },
  methods: {
    updateInfo(){
      this.isLoadingInfo = true;
      this.makeRequest({
        owner: this.requestOwners.user,
        method: this.apiRequests.user.getProfile.method,
        authRequired: true,
        url: this.apiRequests.user.getProfile.url
      })
        .then(res => {
          this.isLoadingInfo = false;
          this.userInfo = res.data;
          const { email ="", name = ""} = res.data;
          this.formData = {
            ...this.formData,
            email,
            name
          };
        })
        .catch(err => {
          this.isLoadingInfo = false;
          this.notify({
            msg: err.errors || "Failed to get profile info",
            type: "error"
          });
        });
    },
    saveChanges() {
      if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.formData.email)){
        this.notify({
                    msg: "Please enter a valid email.",
                    type: "error"
                });
        return;
      }
      if(!/^.{3,40}$/.test(this.formData.name)){
        this.notify({
                    msg: "Username should be between 3 and 40 characters.",
                    type: "error"
                });
        return;
      }
      if(this.formData.password && (this.formData.password !== this.formData.password_confirmation)){
        this.notify({
                    msg: "The password confirmation has to match the password above it.",
                    type: "error"
                });
        return;
      }
      this.receivedError = "";
      if(this.formData.email && this.formData.name && (this.formData.password ? this.formData.password_confirmation : true)){
          this.isSavingChanges = true;
          this.makeRequest({
            owner: this.requestOwners.user,
            method: 'PUT',
            authRequired: true,
            url: this.apiRequests.user.getProfile.url,
            formData: {
              name: this.formData.name,
              email: this.formData.email,
              ...(this.formData.password && {
                password:this.formData.password,
                password_confirmation:this.formData.password_confirmation
              })

            }
          })
            .then(res => {
              this.isSavingChanges = false;

              if(res?.success){ 
                this.notify({
                    msg: res.message,
                    type: "success"
                });
                this.updateInfo();
                this.isEditMode = false;
                this.receivedError = "";
                this.formData = {
                  ...this.formData,
                  password: "",
                  password_confirmation: ""
                }
                this.showPass = false;
                this.showConfirmationPass = false;
              }else{
                this.receivedError = res.message;
              }
            })
            .catch(err => {
              this.isSavingChanges = false;
              this.notify({
                msg: err.errors || "Failed to save changes",
                type: "error"
              });
            });
      }else{
        this.notify({
          type: "info",
          msg: "Please make sure all fields are filled."
        })
      }

    }
  },
  mounted() {
    // gets balance from user profile
    this.updateInfo();
  },
};
</script>

<style lang="scss" scoped>
.submit-btn {
  margin-top: 2.5rem;
  button {
    cursor: pointer;
    background: var(--primary-clr);
    color: #fff;
    text-align: center;
    padding: 0.3rem 0.7rem;
    border-radius: 0.3rem;
    display: inline-block;
    width: max-content;
    font-size: 1.3rem;
    transition: filter 0.2s linear;
    i {
      color: inherit;
    }
    &:hover {
      filter: brightness(1.1);
    }
    &:active {
      filter: brightness(1.2);
    }
    @media only screen and (max-width: 680px) {
      font-size: 1.1rem;
    }
  }
}
.edit--state--toggler {
  justify-content: flex-end;
  span {
    cursor: pointer;
    background: var(--primary-clr);
    color: #fff;
    text-align: center;
    padding: 0.3rem 0.5rem;
    border-radius: 0.3rem;
    display: inline-block;
    width: max-content;
    transition: filter 0.2s linear;
    i {
      color: inherit;
    }
    &:hover {
      filter: brightness(1.1);
    }
    &:active {
      filter: brightness(1.2);
    }
  }
}
.profile--container {
  padding: 25px 20px;
  .profile--inner {
    font-size: 1.3rem;
    line-height: 1.5;
    min-height: 100px;
    align-content: center;
    p {
      margin-bottom: 0.6rem;
    }
    @media only screen and (max-width: 680px) {
      font-size: 1.1rem;
    }
  }
}
</style>