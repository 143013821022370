<template>
  <div class="min-h-screen flex flex-col">
    <Header></Header>

    <v-main class="grow dark:bg-fox-900">
      <router-view></router-view>
    </v-main>

    <Footer></Footer>

    <div class="loading-holder" v-if="isLoading">
      <Loading />
    </div>
    <!-- <ScrollTopTopBtn /> -->
    <Script src="https://www.google.com/recaptcha/api.js" async defer></Script>
  </div>
</template>

<script>
import Header from '@/components/Main/Header';
import Footer from '@/components/Main/Footer';
import Loading from '@/components/Main/Loading';
// import ScrollTopTopBtn from "@/components/Main/ScrollTopTopBtn";
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'MainLayout',
  components: {
    Header,
    Footer,
    Loading,
    // ScrollTopTopBtn
  },
  computed: {
    ...mapGetters('website', ['doneTodos', 'getSocial']),
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    ...mapActions("main", ["updateLoader", "updatePolicy", "updateTerms", "updaTetestimonials", "updateFaqs", "updateOnboarding", "updateFaqsSections", "updateSelectedCategoryId"]),
    ...mapActions("website", ["updateLoadingWebsiteState"]),
    changeLoadingState(state) {
      this.updateLoadingWebsiteState(state);
      this.isLoading = state;
    },
  },
  mounted() {
    this.changeLoadingState(true);
    axios
      .get(this.apiRequests.user.websiteData.url)
      .then((res) => {
          const data = res?.data?.data;
          this.changeLoadingState(false);
          this.$store.dispatch('website/setWebsiteData', data);
          // test code
          this.updateLoader(true)
          this.updatePolicy(data?.siteData?.privacy_policy)
          this.updateTerms(data?.siteData?.terms_of_services)
          this.updaTetestimonials(data?.feedbacks)
          this.updateFaqs(data?.faqs)
          this.updateOnboarding(data?.onboarding)
          this.updateMemory({
              type: "update",
              newObject: data?.siteData || {},
              destination: 'siteSettings',
          });
          
          // get faqs categories
          const allOfFaqs =  data?.faqs
          let uniqueArray = []
          allOfFaqs.forEach((item)=>{
            const isFoundElement = uniqueArray.find((i)=>{
              return i.id == item.category.id
            })
            if(!isFoundElement){
              uniqueArray.push(item.category)
            }
          })

          if(uniqueArray?.length){
            this.updateFaqsSections(uniqueArray)
            this.updateSelectedCategoryId(uniqueArray[0].id)
          }
      })
      .catch((err) => {
        this.changeLoadingState(false);
        this.notify({
          type: 'error',
          msg: err.errors || "An error has occurred. Please try again later.",
        });
      });

    //check if user is is authenticated or not and set the state in the store

    const fromLocalStorage = localStorage.getItem('fox_sims');
    const token = JSON.parse(fromLocalStorage)?.user?.token;

    if (token) {
      this.$store.dispatch('website/setIsAuth', true);
    } else {
      this.$store.dispatch('website/setIsAuth', false);
    }
  },
};
</script>

<style lang="scss" scoped></style>
