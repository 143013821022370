import Vue from "vue";
import axios from "axios";
import store from "../store";
import { locStorage, isString } from "../utilities/tools";
import { mapGetters, mapActions } from "vuex";
import router from "../router/index";
import placeholderImg from "../assets/images/placeholder.jpg";
import { routesNames, apiRequests, requestOwners, messageResponses, countriesAlpha2, countriesIDs } from "../utilities/basicData";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);


const globalMixins = {
  data() {
    return {
      countriesAlpha2,
      countriesIDs,
      routesNames,
      apiRequests,
      requestOwners,
      messageResponses,
      errorMsg: "An error occurred. Please try again later.",
    };
  },
  computed: {
    ...mapGetters("main", {isLoadingGlobally: "getLoadingGloballyState", getUserToken: "getUserToken", getAdminToken: "getAdminToken", getLocalStorage: "getLocalStorage", getKeys: "getKeys"}),
    ...mapGetters("website", ["getWebsiteData"])
  },
  methods: {
    ...mapActions("main", ["updateMemory"]),
    locStorage,
    isString,
    getCountryAbbrFromId(countryId){
      const parsedCountryIds = JSON.parse(JSON.stringify(countriesIDs));
      const countryIDIndex = parsedCountryIds?.map(country => +country.id).indexOf(+countryId);
      if(countryIDIndex !== -1){
        const countryName = parsedCountryIds[countryIDIndex]?.country;
        if(!countryName){
          return;
        }
        const countryCode = Object.entries(countriesAlpha2).find(([_, value]) => {
          return this.lowerString(value) === this.lowerString(countryName);
        })?.[0]?.toUpperCase();
        if(!countryCode){
          return;
        }
        return countryCode;
      }
    },
    notify({ type, msg }) {
      this.$toast.open({
        message: msg,
        type: type,
        duration: 5000,
        pauseOnHover: true,
        position: 'bottom-left'
      });
    },
    toggleTheme(){
      const currentMode = this.getLocalStorage?.isDarkmode;
     
      if(typeof currentMode === "boolean"){
        this.updateMemory({
                          type: "update",
                          newObject: !currentMode,
                          destination: "isDarkmode",
                        });
      }
    },
    copyToClipboard({text}) {       
      return new Promise((resolve, reject) => {
        if(navigator.clipboard.writeText){
          navigator.clipboard.writeText(text);
          resolve();
        }else{
          reject();
        }
      });

    },
    confirmDialog: ({ msg, buttonLabels, confirmedFunc }) => {
      Vue.$confirm({
        position: "bottom",
        message: msg || "Are you sure?",
        button: {
          no: buttonLabels?.no || "No",
          yes: buttonLabels?.yes || "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            confirmedFunc();
          }
        },
      });
    },
    lowerString: (txt) =>
      typeof txt === "string"
        ? txt.toLowerCase()
        : typeof txt === "number"
        ? txt
        : "",
    trimText: (txt, limit) =>
      txt
        ? `${
            txt.split("").length > limit
              ? txt.split("").slice(0, limit).join("") + "..."
              : txt
          }`
        : "",
    capFirstLetter: (txt) =>
      typeof txt === "string"
        ? `${txt.charAt(0)?.toUpperCase()}${txt.slice(1)}`
        : "",
    changeSelectedLanguage(language) {
      this.updateMemory({
        type: "update",
        newObject: language,
        destination: "language",
      });
    },
    onImageError(e) {
      e.target.src = placeholderImg;
        e.onerror = null;
        return true;
    },
    async makeRequest({
      owner,
      method,
      url,
      authRequired = true,
      formData = {},
      withFiles = false,
      recaptchaToken = "",
      abortControllerSignal=null 
  }) {
      const errorMsg = "An error occurred. Please try again later.";
      owner = this.lowerString(owner);
      const lsCopy = this.locStorage({type: "get"});
      const userToken = store.getters["main/getUserToken"];
      const adminToken = store.getters["main/getAdminToken"];
      let activeToken = "";
      switch (owner) {
          case requestOwners.admin:
              activeToken = adminToken;
              if (authRequired && !adminToken) {
                  router?.replace(routesNames.admin.login);
                  return Promise.reject("Unauthenticated");
              } else {
                  break;
              }
  
          case requestOwners.user:
              activeToken = userToken;
              if (authRequired && !userToken) {
                router?.replace(routesNames.user.login);
                  return Promise.reject("Unauthenticated");
              } else {
                  break;
              }
          default: {
          }
      }
  
      return axios({
          method,
          url,
          timeout: 60000,
          ...(formData && { data: formData }),
          headers: {
              Accept: "application/json",
              ...(recaptchaToken && {"recaptcha-token": recaptchaToken}),
              "Content-Type": withFiles
                  ? "multipart/form-data"
                  : "application/json",
              lang: lsCopy?.language || this.getLocalStorage.language,
              ...(authRequired && {
                  Authorization: `Bearer ${activeToken}`,
              }),
          },
          ...(abortControllerSignal && {'signal':abortControllerSignal}), 
      })
          .then((res) => res.data)
          .catch((err) => {
              const errorObj = err.response;

              switch (errorObj?.status) {
                  case 401: {
                      if(!authRequired){
                        return;
                      }
                      this.updateMemory({
                        type: "update",
                        newObject: {},
                        destination: owner,
                      });
                      if(owner === requestOwners.user){
                          router.replace(routesNames.user.login);
                      }else{
                          router.replace(routesNames.admin.login);
                      }
                      break;
                  }
                  // case 400: {
                  //     router.push(routesNames.errors._400);
                  //     break;
                  // }
                  // case 403: {
                  //     router.push(routesNames.errors._403);
                  //     break;
                  // }
                  // case 404: {
                  //     router.push(routesNames.errors._404);
                  //     break;
                  // }
                  // case 500: {
                  //     router.push(routesNames.errors._500);
                  //     break;
                  // }
                  case 503: {
                      router.push(routesNames.errors._503);
                      break;
                  }
                  default: {
                  }
              }
              return Promise.reject(errorObj?.data || errorMsg);
          });
    },
  },
};

export default globalMixins;
